import { Col, Dropdown, Nav, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import DatePicker from "react-datepicker";

import saleImg from "../../assests/img/nft.png";
import fixedicon from "../../assests/img/fixed.svg";
import fixedIconDark from "../../assests/svg/fixedicondark.svg";
import timedicon from "../../assests/img/timed.svg";
import timedIconDark from "../../assests/svg/timedauctiondark.svg";
import dateImg from "../../assests/svg/datechooselight.svg";
import datewhite from "../../assests/svg/datechoose.svg";
import { Link } from "react-router-dom";
import ImgAudVideo from "../common-components/ImagAudVideo";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import { useHistory, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty, NumANdDotOnly, NumberChange } from "../actions/common";
import config from "../config/config";
import { CreateOrder } from "../actions/axios/nft.axios";
import Calender from "./Calendar";
import moment from "moment";

const optionsOne = [
  { value: "avax", label: "Avax" },
  { value: "cake", label: "Cake" },
];
const optionsTwo = [
  { value: "selectDate", label: "Select Date" },
  { value: "rightAfterListing", label: "Right After Listing" },
  { value: "pickSpecificDate", label: "Pick Specific Date" },
];

function PutOnSale({ show, handleClose, item, owner, text, RefreshData }) {
  // redux
  const currTheme = useSelector((state) => state.themeReducer.theme);

  // state
  const [showTabs, setShowTabs] = useState(true);
  const [BtnData, SetBtnData] = useState("start");
  const [TokenBtn, SetTokenBtn] = useState("start");
  const [Mintbtn, SetMintbtn] = useState("start");

  const [selectedOption, setSelectedOption] = useState(null);
  const { currency } = useSelector((state) => state.LoginReducer);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const { web3, currentNetwork } = useSelector((state) => state.LoginReducer.AccountDetails);
  const { sellerFees } = useSelector((state) => state.LoginReducer.ServiceFees);

  const [FormValue, SetFormValue] = useState(item);
  const [ValidateError, SetValidateError] = useState({});
  const [OpenPopup, SetOpenPopup] = useState("");

  useEffect(() => {
    if (isEmpty(FormValue?.CoinName)) {
      SetFormValue({
        ...FormValue,
        ...{
          ["CoinName"]: currency[0]?.label ?? currentNetwork?.COIN_NAME,
          ["EmailId"]: payload?.EmailId
        },
      });
    }

  }, [currency, FormValue?.PutOnSaleType]);
  const handleClosecalender = () => setCalenderShow(false);

  var validEnd = function (current) {
    return current.isAfter(
      FormValue.ClockTime ? new Date(FormValue.ClockTime) : undefined
    );
  };
  var validStart = function (current) {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const DateSelection = (e, data) => {
    SetValidateError({});
    SetBtnData("start");
    if (data == "start") {
      if (e.value == "List Immediately")
        SetFormValue({
          ...FormValue,
          ...{
            // ["ClockTime"]: moment(new Date()).format("YYYY-MM-DD HH:mm:ss a"),
            ["ClockTime"]: new Date(),
          },
        });
      else if (e.value == "Scheduled Listing") {
        setCalenderShow(true);
        SetOpenPopup("ClockTime");
      }
    } else {
      if (e.value == "1 day") {
        // console.log('dateeeee',FormValue.ClockTime,moment(FormValue.ClockTime).toDate())
        if (FormValue.ClockTime === "") {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date().setDate(new Date().getDate() + 1)
              ),
            },
          });
        } else {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date(FormValue.ClockTime).setDate(
                  new Date(FormValue.ClockTime).getDate() + 1
                )
              ),
            },
          });
        }
      } else if (e.value == "2 days") {
        if (FormValue.ClockTime === "") {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date().setDate(new Date().getDate() + 2)
              ),
            },
          });
        } else {
          SetFormValue({
            ...FormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date(FormValue.ClockTime).setDate(
                  new Date(FormValue.ClockTime).getDate() + 2
                )
              ),
            },
          });
        }
      } else if (e.value == "Scheduled Listing") {
        setCalenderShow(true);
        SetOpenPopup("EndClockTime");
      }
    }
  };
  // action
  const handleCheckboxActive = (e) => {
    setShowTabs(!showTabs);
  };
  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      FormValue.ContractType == 721 || FormValue.ContractType == "721"
        ? "Sinle"
        : "Multiple",
      FormValue.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtnData("start");
    } else SetTokenBtn("try");
  };
  const onSelectChange = (e, data) => {
    SetBtnData("start");

    const id = "CoinName";
    const { label, value } = e;
    SetFormValue({ ...FormValue, ...{ [id]: value } });
  };
  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(
      FormValue?.NFTPrice,
      FormValue.NFTRoyalty
    );
  }, [FormValue?.NFTPrice, FormValue.NFTRoyalty]);

  const onChange = (e) => {
    const { files, value, id } = e.target;
    SetBtnData("start");

    SetFormValue({ ...FormValue, ...{ [id]: NumANdDotOnly(value) } });
  };

  const FormSubmit = async () => {
    SetBtnData("start");
    const id = toast.loading("Validating Form");
    var Error = Validation(FormValue);
    console.log('erororoorr', Error)
    SetBtnData("process");
    if (isEmpty(Error)) {
      let Respc = await ContractCall.Contract_Base_Validation();
      if (!Respc) {
        let Statu = await ContractCall.GetApproveStatus(
          FormValue.ContractType == 721 || FormValue.ContractType == "721"
            ? "Single"
            : "Multiple",
          FormValue.ContractAddress
          // FormValue.ContractType == 721?FormValue?.ContractAddress:config?.ERC721
        );
        if (Statu == true) {
          SetBtnData("process");
          toast.update(id, {
            render: "Ready To Place Order",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          ListCall();
        } else {
          SetBtnData("open");
          toast.update(id, {
            render: "Get Approve",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
        }
      } else {
        SetBtnData("error");
        SetValidateError(Respc);
      }
    } else {
      setTimeout(() => {
        toast.update(id, {
          render: "Check Fields",
          isLoading: false,
          type: "error",

          autoClose: 1000,
        });
      }, 700);

      SetBtnData("error");
      SetValidateError(Error);
    }
  };
  const closemodal = () => SetBtnData("start");

  const Validation = (data) => {
    let ValidateError = {};
    const {
      NFTPrice,
      CoinName,
      PutOnSaleType,
      ClockTime,
      EndClockTime,


    } = data;
    console.log("dfsgsdfgdfh", data);
    if (
      // (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      isEmpty(NFTPrice)
    )
      ValidateError.NFTPrice = "Token Price Required";
    if (
      // (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName
    )
      ValidateError.CoinName = "CoinName Required";

    if (
      !ClockTime
      // PutOnSaleType == "TimedAuction" ||
      // (PutOnSaleType == "FixedPrice" && !ClockTime)
    ) {
      ValidateError.ClockTime = "ClockTime Required";
    }
    if (
      !EndClockTime
    ) {
      ValidateError.EndClockTime = "EndClockTime Required";
    }
    return ValidateError;
  };
  async function ListCall() {
    const id = toast.loading("Listing Processing");
    SetMintbtn("process");
    console.log('erororoorr', FormValue)
    if (FormValue.PutOnSaleType == "FixedPrice") {
      var error = await ContractCall.Contract_Base_Validation();
      if (error)
        toast.update(id, {
          render: error,
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
      else {

        const cont = await ContractCall._signcall(
          FormValue?.NFTPrice,
          owner.NFTOwner
        );
        console.log('contcontlistttt', cont)
        if (cont) {
          let _data = FormValue;
          _data.NFTOwner = payload.WalletAddress;
          // _data.HashValue = cont.HashValue;
          _data.ClockTime = new Date(FormValue.ClockTime);
          _data.EndClockTime = new Date(FormValue.EndClockTime);
          _data.Password = cont.password;
          _data.Signhash = cont?.signhash;
          _data.nonce = cont?.tot;
          _data.NFTId = owner.NFTId;
          _data.activity = "PutOnSale";
          // _data.NFTQuantity = owner?.CurrentNFTBalance ? owner.CurrentNFTBalance : (NumberChange(owner?.NFTBalance) + NumberChange(owner?.StakedNFTQuantity));
          _data.click = `${config.FRONT_URL}/product/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;
          // debugger

          BackCall(id, _data);
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtnData("try");
        }
      }
    }
    else {
      let _data = FormValue;
      _data.NFTOwner = payload.WalletAddress;
      _data.HashValue = "";
      _data.NFTId = owner.NFTId;
      _data.activity = "Change Price";
      _data.click = `${config.FRONT_URL}/product/${FormValue.CollectionNetwork}/${FormValue.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;

      BackCall(id, _data);
    }
  }
  const BackCall = async (id, _data) => {
    let Resp = await CreateOrder(_data);
    if (Resp.success == "success") {
      toast.update(id, {
        render: "Listed Successfully",
        type: Resp.success,
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtnData("done");
      RefreshData();
      push(`/author/${payload?.CustomUrl}`);
    } else {
      toast.update(id, {
        render: "Transaction Failed",
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtnData("try");
    }
  };
  // select style

  // theme from redux state

  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  let valueContainerBGTwo = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerTextTwo = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorderTwo =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  const stylesgraybgTwo = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 10px",
      // width: "180px",
      backgroundColor: valueContainerBGTwo,
      color: "red",
      border: valueContainerBorderTwo,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerTextTwo,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBGTwo,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerTextTwo,
      };
    },
  };
  const stylesgraybgThree = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00ef9b",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,

      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  const startingdate = [
    { value: "List Immediately", label: "List Immediately" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const enddate = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];

  const setClockValue = (data, date) => {
    SetFormValue({
      ...FormValue,
      ...{ [data]: new Date(date) },
    });
  };
  const handleCalenderOpen = () => {
    console.log("clicke");
  };

  const [showCalender, setCalenderShow] = useState(false);

  console.log('ownerownerputonsale', owner)
  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item)
      console.log('balanceeee', Nftbalance, owner?.NFTBalance, owner, item)
      if (NumberChange(Nftbalance) != NumberChange(owner.NFTBalance)) {
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          // push("/")
          handleClose()
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner])


  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcnter"
        centered
        className="custom_modal"
        onHide={handleClose}
        backdrop="static"
      >
        <Modal.Header closeButton className="px-sm-5 mx-auto ">
          <Modal.Title id="contained-modal-title-vcenter" className=" heading">
            {text == "Change Price" ? <>Change Price</> : <> Put on Sale</>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-sm-5 pb-4">
          <div className="d-flex flex-column align-items-center">
            <div className="put_on_sale_img pos_img_wrp">
              {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
              <ImgAudVideo
                file={`${config?.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT/${item?.CompressedFile}`}
                type={
                  item?.CompressedFile
                    ? item?.CompressedFile?.includes(".webp")
                      ? "image"
                      : item?.CompressedFile.includes(".webm")
                        ? "video"
                        : "audio"
                    : item?.CompressedFile
                }
                thumb={`${config.IMG_URL}/nft/${item?.NFTCreator}/Compressed/NFT_THUMB/${item?.CompressedThumbFile}`}
                from="info"
                origFile={`${config.IMG_URL}/nft/${item?.NFTCreator}/Original/NFT/${item?.OriginalFile}`}
                className="img-fluid img_wrp_cvr_fit"
              />
            </div>
            <p className="mt-4">Your About to Place {item?.NFTName} on sale</p>

            <Row className="w-100 mt-3">
              <Col xs={9} className="ps-0">
                <div>
                  <h5 className="heading mb-0">
                    {" "}
                    {text == "Change Price" ? (
                      <>Change Price</>
                    ) : (
                      <> Put on Sale</>
                    )}
                  </h5>
                  <p>You'll receive bids on this item</p>
                </div>
              </Col>
              {/* <Col xs={3} className="pe-0">
                {" "}
                <label class="switch tab_toggle">
                  <input
                    type="checkbox"
                    onChange={handleCheckboxActive}
                    checked={showTabs}
                    disabled={true}
                  />
                  <span class="slider round"></span>
                </label>
              </Col> */}
            </Row>
            {showTabs && (
              <div className="tab_cont md_tab_cont mt-3 w-100">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Row>
                    <Col sm={12} className="px-0">
                      <Nav variant="pills">
                        <div className="tab_row create_single d-flex align-items-center gap-3 pb-2 w-100">
                          <Row className="w-100 mx-auto">
                            <Col xs={6}>
                              {" "}
                              <Nav.Item>
                                <Nav.Link eventKey="first" className="px-0">
                                  <div
                                    className="d-flex flex-column align-items-center"
                                    onClick={() =>
                                      SetFormValue({
                                        ...FormValue,
                                        ...{
                                          ["PutOnSaleType"]: "FixedPrice",
                                        },
                                      })
                                    }
                                  >
                                    {currTheme == "light" ? (
                                      <img src={fixedicon} alt="fixed price" />
                                    ) : (
                                      <img
                                        src={fixedIconDark}
                                        alt="fixed price"
                                      />
                                    )}
                                    <p className="method_type mb-0 mt-2">
                                      Fixed Price
                                    </p>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </Col>
                            {text !== "Change Price" && (
                              <Col xs={6}>
                                <Nav.Item>
                                  <Nav.Link eventKey="second" className="px-0">
                                    {" "}
                                    <div
                                      className="d-flex flex-column align-items-center "
                                      onClick={() =>
                                        SetFormValue({
                                          ...FormValue,
                                          ...{
                                            ["PutOnSaleType"]: "TimedAuction",
                                          },
                                        })
                                      }
                                    >
                                      {currTheme == "light" ? (
                                        <img
                                          src={timedicon}
                                          alt="time auction"
                                        />
                                      ) : (
                                        <img
                                          src={timedIconDark}
                                          alt="time auction"
                                        />
                                      )}
                                      <p className="method_type mb-0 mt-2">
                                        Time auction
                                      </p>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Nav>
                    </Col>
                    <Col sm={12}>
                      <Tab.Content className="mt-2">
                        <Tab.Pane eventKey="first">
                          {/* <div className="w-100">
                            <input
                              type="text"
                              placeholder="Enter new Price"
                              className="custom_input modal_input w-100"
                              onChange={onChange}
                              id="NFTPrice"
                              value={FormValue.NFTPrice}
                            />
                            {(FormValue.PutOnSaleType == "FixedPrice" ||
                              FormValue.PutOnSaleType == "TimedAuction") &&
                              ValidateError.NFTPrice && (
                                <div className="text-danger img-file">
                                  {ValidateError.NFTPrice}
                                </div>
                              )}
                          </div> */}
                          <>
                            <p className="label">
                              Price
                              <span className="text-danger">*</span>
                            </p>
                            <Row className="cust_inpt_slt mx-auto">
                              <Col
                                xs={12}
                                className="ps-0 pe-2 d-flex align-items-center"
                              >
                                <div class="form-group w-100">
                                  <input
                                    type="text"
                                    class="form-control custom_input"
                                    id="NFTPrice"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter price"
                                    value={FormValue.NFTPrice}
                                    onChange={onChange}
                                  />
                                </div>
                              </Col>

                            </Row>
                            {ValidateError?.NFTPrice && (
                              <div className="text-danger img-file">
                                {ValidateError?.NFTPrice}
                              </div>
                            )}
                            {console.log("Coinnameee", FormValue.CoinName)
                            }                            <div className="mt-3">
                              <p className="text-left mb-3 label">
                                Select Coin
                              </p>
                              <Select
                                defaultValue={selectedOption}
                                isSearchable={false}
                                onChange={(e) => onSelectChange(e, "price")}
                                value={
                                  FormValue.PutOnSaleType
                                    ? FormValue.PutOnSaleType == "FixedPrice"
                                      ? {
                                        label: FormValue.CoinName,
                                        value: FormValue.CoinName,
                                      }
                                      : currency.filter(
                                        (item) =>
                                          item.value !== currentNetwork?.COIN_NAME
                                      )[0]
                                    : currency[0]
                                }
                                options={
                                  FormValue.PutOnSaleType == "FixedPrice"
                                    ? currency
                                    : currency.filter(
                                      (item) =>
                                        item.value != currentNetwork?.COIN_NAME
                                    )
                                }
                                id="CoinName"
                                styles={stylesgraybg}
                                className="react_slt"
                                placeholder="BNB"
                                classNamePrefix="react-select-one"
                              />
                            </div>
                            {(FormValue.PutOnSaleType == "FixedPrice" ||
                              FormValue.PutOnSaleType == "TimedAuction") &&
                              ValidateError.CoinName && (
                                <div className="text-danger img-file">
                                  {ValidateError.CoinName}
                                </div>
                              )}
                            <Row>
                              <Col xs={12} className="mt-3">
                                <div>
                                  <p className="label">
                                    Starting Date
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div className=" position-relative date_picker ">
                                    <Select
                                      // menuIsOpen={true}
                                      className="react_slt rem_drp_dwn_icn"
                                      value={{
                                        value: FormValue.ClockTime,
                                        label:
                                          FormValue.ClockTime == "" ||
                                            FormValue.ClockTime == null
                                            ? FormValue.ClockTime
                                            : moment(
                                              FormValue.ClockTime
                                            ).format("YYYY-MM-DD h:mm:ss a"),
                                      }}
                                      onChange={(e) =>
                                        DateSelection(e, "start")
                                      }
                                      options={startingdate}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      styles={stylesgraybg}
                                    />

                                    {ValidateError.ClockTime && (
                                      <span className="text-danger img-file">
                                        {ValidateError.ClockTime}
                                      </span>
                                    )}
                                    {/* {currTheme == "light" ? (
                                      <img
                                        src={dateImg}
                                        alt="date-choosen"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    ) : (
                                      <img
                                        src={datewhite}
                                        alt="date"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    )} */}
                                    {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} className="mt-3">
                                <div>
                                  <p className="label">
                                    Ending Date
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div className=" position-relative date_picker ">
                                    <Select
                                      styles={stylesgraybg}
                                      value={{
                                        value: FormValue.EndClockTime,
                                        label:
                                          FormValue.EndClockTime == "" ||
                                            FormValue.EndClockTime == undefined ||
                                            FormValue.EndClockTime == null
                                            ? FormValue.EndClockTime
                                            : moment(
                                              FormValue.EndClockTime
                                            ).format("YYYY-MM-DD h:mm:ss a"),
                                      }}
                                      onChange={(e) => DateSelection(e, "end")}
                                      options={enddate}
                                      isSearchable={false}
                                      className="react_slt rem_drp_dwn_icn"
                                      classNamePrefix="react-select-one"
                                    />
                                    {ValidateError.EndClockTime && (
                                      <span className="text-danger img-file">
                                        {ValidateError.EndClockTime}
                                      </span>
                                    )}
                                    {/* {currTheme == "light" ? (
                                      <img
                                        src={dateImg}
                                        alt="date-choosen"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    ) : (
                                      <img
                                        src={datewhite}
                                        alt="date"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    )} */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <>
                            <p className="label">
                              Minimum Bid
                              <span className="text-danger">*</span>
                            </p>
                            <Row className="cust_inpt_slt mx-auto">
                              <Col
                                xs={12}
                                className="ps-0 pe-2 d-flex align-items-center"
                              >
                                <div class="form-group w-100">
                                  <input
                                    type="text"
                                    class="form-control custom_input"
                                    id="NFTPrice"
                                    aria-describedby="emailHelp"
                                    placeholder="Enter price"
                                    value={FormValue.NFTPrice}
                                    onChange={onChange}
                                  />
                                </div>
                              </Col>

                            </Row>
                            {ValidateError?.NFTPrice && (
                              <div className="text-danger img-file">
                                {ValidateError?.NFTPrice}
                              </div>
                            )}
                            <div className="mt-3">
                              <p className="text-left mb-3 label">
                                Select Coin
                              </p>
                              <Select
                                defaultValue={selectedOption}
                                isSearchable={false}
                                onChange={(e) => onSelectChange(e, "price")}
                                value={
                                  FormValue.PutOnSaleType
                                    ? FormValue.PutOnSaleType == "FixedPrice"
                                      ? {
                                        label: FormValue.CoinName,
                                        value: FormValue.CoinName,
                                      }
                                      :
                                      currency.filter(
                                        (item) =>
                                          item.value !== currentNetwork?.COIN_NAME
                                      )[0]
                                    : currency[0]
                                }
                                options={
                                  FormValue.PutOnSaleType == "FixedPrice"
                                    ? currency
                                    : currency.filter(
                                      (item) =>
                                        item.value != currentNetwork?.COIN_NAME
                                    )
                                }
                                id="CoinName"
                                styles={stylesgraybg}
                                className="react_slt"
                                classNamePrefix="react-select-one"
                                placeholder="BNB"
                              />
                            </div>
                            {(FormValue.PutOnSaleType == "FixedPrice" ||
                              FormValue.PutOnSaleType == "TimedAuction") &&
                              ValidateError.CoinName && (
                                <div className="text-danger img-file">
                                  {ValidateError.CoinName}
                                </div>
                              )}

                            <Row>
                              <Col xs={12} className="mt-3">
                                <div>
                                  <p className="label">
                                    Starting Date
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div className=" position-relative date_picker ">
                                    <Select
                                      // menuIsOpen={true}
                                      className="react_slt rem_drp_dwn_icn"
                                      value={{
                                        value: FormValue.ClockTime,
                                        label:
                                          FormValue.ClockTime == "" ||
                                            FormValue.ClockTime == null
                                            ? FormValue.ClockTime
                                            : moment(
                                              FormValue.ClockTime
                                            ).format("YYYY-MM-DD h:mm:ss a"),
                                      }}
                                      onChange={(e) =>
                                        DateSelection(e, "start")
                                      }
                                      options={startingdate}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      styles={stylesgraybg}
                                    />

                                    {ValidateError.ClockTime && (
                                      <span className="text-danger img-file">
                                        {ValidateError.ClockTime}
                                      </span>
                                    )}
                                    {/* {currTheme == "light" ? (
                                      <img
                                        src={dateImg}
                                        alt="date-choosen"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    ) : (
                                      <img
                                        src={datewhite}
                                        alt="date"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    )} */}
                                    {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                  </div>
                                </div>
                              </Col>
                              <Col xs={12} className="mt-3">
                                <div>
                                  <p className="label">
                                    Ending Date
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div className=" position-relative date_picker ">
                                    <Select
                                      styles={stylesgraybg}
                                      value={{
                                        value: FormValue.EndClockTime,
                                        label:
                                          FormValue.EndClockTime == "" ||
                                            FormValue.EndClockTime == undefined ||
                                            FormValue.EndClockTime == null
                                            ? FormValue.EndClockTime
                                            : moment(
                                              FormValue.EndClockTime
                                            ).format("YYYY-MM-DD h:mm:ss a"),
                                      }}
                                      onChange={(e) => DateSelection(e, "end")}
                                      options={enddate}
                                      isSearchable={false}
                                      className="react_slt rem_drp_dwn_icn"
                                      classNamePrefix="react-select-one"
                                    />
                                    {ValidateError.EndClockTime && (
                                      <span className="text-danger img-file">
                                        {ValidateError.EndClockTime}
                                      </span>
                                    )}
                                    {/* {currTheme == "light" ? (
                                      <img
                                        src={dateImg}
                                        alt="date-choosen"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    ) : (
                                      <img
                                        src={datewhite}
                                        alt="date"
                                        className="img-fluid position-absolute date_icon pos_dat_icn"
                                      />
                                    )} */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </>{" "}

                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            )}

            <Row className="w-100 mt-3">
              <Col xs={6} className="ps-0">
                <p className="mb-0 heading">Seller Service Fee</p>
              </Col>
              <Col xs={6} className="pe-0">
                <p className="mb-0 heading">
                  {web3?.utils?.fromWei(String(sellerFees))}%
                </p>
              </Col>
              <Col xs={6} className="ps-0">
                <p className="mb-0 heading">You will get</p>
              </Col>
              <Col xs={6} className="pe-0">
                <p className="mb-0 heading">
                  {Number(YouWillGet).toFixed(6) ?? 0}
                </p>
              </Col>
            </Row>
            {BtnData == "open" && (
              <Button
                className="green_btn hover_btn mt-4 w-100"
                disabled={
                  TokenBtn == "process" || TokenBtn == "done" ? true : false
                }
                onClick={
                  TokenBtn == "start" || TokenBtn == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {TokenBtn == "start" && "Approve"}
                {TokenBtn == "process" && "In-Progress"}
                {TokenBtn == "try" && "Try-Again"}
                {TokenBtn == "done" && "Done"}
              </Button>
            )}
            {
              (BtnData != "open") &&
              <Button
                className="green_btn hover_btn mt-4 w-100"
                disabled={
                  BtnData == "process" || BtnData == "done" || BtnData == "open"
                    ? true
                    : false
                }
                onClick={
                  BtnData == "start" || BtnData == "try" ? FormSubmit : null
                }
              >
                {BtnData == "start" &&
                  BtnData == "start" &&
                  (text == "Change Price" ? "Change Price" : "Put on Sale ")}
                {(BtnData == "start" || BtnData == "error") &&
                  text == "Put on Sale " &&
                  "Put on Sale "}

                {BtnData == "process" && "processing"}
                {BtnData == "done" && "Done"}
                {BtnData == "try" && "Try-Again"}
                {BtnData == "error" && "Error"}
              </Button>
            }

            <Button className="red_btn modal_btn mt-4 " onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {showCalender && (
        <Calender
          show={showCalender}
          handleClose={handleClosecalender}
          modal={OpenPopup}
          validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
          setClockValue={setClockValue}
          closeModal={() => setCalenderShow(false)}
        />
      )}
    </>
  );
}
export default PutOnSale;
