import React, { useEffect, useState } from "react";
import Innertitle from "../common-components/innertitle";
import InputField from "../common-components/InputField";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  Accordion,
  Button,
  Form,
} from "react-bootstrap";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import photograpy from "../../assests/svg/photograpy.svg";

import collectionCardOne from "../../assests/img/background.jpg";
import collectionCardTwo from "../../assests/img/bgTwo.jpg";
import collectionCardThree from "../../assests/img/bgThree.jpg";
import collectionCardFour from "../../assests/svg/hotcollectionfour.svg";
import CollectionCards from "../common-components/CollectionCards";
import { CollectionsList } from "../actions/axios/nft.axios";
import { useSelector } from "react-redux";
import NoData from "../common-components/nodata";
import Loader from "../common-components/Loader";
import { useParams, useNavigate } from "react-router-dom";


const Collections = () => {
  console.log('hiiiiiiiicollectonnnn')
  const { Categorys } = useSelector((state) => state.LoginReducer);
  const { category } = useParams();
  const push = useNavigate();

  var init = {
    All: undefined,
  };
  const [Collectionsdata, Setcollectionsdata] = useState(init);
  const [limit, Setlimit] = useState(12);
  const [TabName, SetTabName] = useState(category ? (category.toLowerCase() == "project" ? "PROJECT" : category) : "All");
  const [loader, setLoader] = useState(true);
  const [Collectiontype, Setcollectiontype] = useState(category.toLowerCase() == "project" ? "PROJECT" : "ART");

  useEffect(() => {
    if (category) {
      SetTabName(category.toLowerCase() == "project" ? "PROJECT" : category);
      Setcollectiontype(category.toLowerCase() == "project" ? "PROJECT" : "ART")
    }
  }, [category]);
  useEffect(() => {
    if (
      typeof Collectionsdata[TabName] == "undefined" ||
      Collectionsdata[TabName].project !== Collectiontype
    ) {
      Collectionsdata[TabName] = { page: 1, list: [], loader: false };
      Setcollectionsdata(Collectionsdata);
      CollectionByCreate(1, TabName);
    }
  }, [TabName, category, Collectiontype]);

  const CollectionByCreate = async (page, tab) => {
    let senddata = {
      from: "collections",
      TabName: tab ? tab : TabName,
      limit: limit,
      page: page ?? 1,
      filter: "recentcreated",
      project:
        Collectiontype == "ART"
          ? false
          : Collectiontype == "PROJECT"
            ? true
            : "All",
    };
    senddata.TabName = "All"
    // console.log("categorggycategory", senddata);
    let Resp = await CollectionsList(senddata);
    console.log('respppsppspsps', Resp?.data)
    if (Resp?.data?.data) {
      setLoader(false);
      Setcollectionsdata({
        ...Collectionsdata,
        ...{
          [TabName]: {
            list: Collectionsdata[TabName]?.list
              ? [...Collectionsdata[TabName]?.list, ...Resp?.data?.data]
              : Resp?.data?.data,
            page: Collectionsdata[TabName]?.page,
            loader: Resp.data.data.length == 0 ? false : true,
            project: Collectiontype,
          },
        },
      });
    }
  };
  const LoadMore = () => {
    Collectionsdata[TabName].page = Collectionsdata[TabName].page + 1;
    Setcollectionsdata(Collectionsdata);
    CollectionByCreate(Collectionsdata[TabName].page, TabName);
  };

  console.log('CollectionsdataCollectionsdata', Collectionsdata)

  const settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1.1,
    slidesToScroll: 1,
    centerMode: false,
    // arrows: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },

      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1070,
        settings: {
          slidesToShow: 4,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 4,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 3,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          initialSlide: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log('loaderloader', loader)

  return loader ? (
    <Loader show={loader} handleClose={() => setLoader(false)} />
  ) : (
    <section className="catalog_sec">
      <Innertitle title="Collections" />
      {console.log('CollectionsdataCollectionsdatasdf')}
      <div className="mt-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="custom_tabs position-relative">
            {/* <div
              className="position-absolute scroll_left_btn d-flex align-items-center justify-content-center"
              // onClick={() => scrollingNavsLeft()}
            >
              <i class="fa-solid fa-chevron-left"></i>
            </div> */}
            <Container
              fluid
              className="custom_slider_container mkt_cust_sld mb-3 mb-md-4 "
            >
              <Slider {...settings}>
                <Nav
                  variant="pills"
                  className="d-flex align-items-center justify-content-between tab_nav_itm gap-3"
                  id="outsider"
                  onClick={() => Setcollectiontype("ART")}
                >
                  <Nav.Item className="width-100">
                    <Nav.Link
                      eventKey={"one"}
                      className={
                        Collectiontype == "ART"
                          ? "d-flex align-items-center justify-content-center gap-3 tab_btn active"
                          : "d-flex align-items-center justify-content-center gap-3 tab_btn"
                      }
                    >
                      <imgs
                        src={photograpy}
                        alt="photograpy"
                        className="common_filter_img_logo"
                      />
                      <p className="mb-0 slder_p">ART</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Nav
                  variant="pills"
                  className="d-flex align-items-center justify-content-between tab_nav_itm gap-3"
                  id="outsider"
                  onClick={() => Setcollectiontype("PROJECT")}
                >
                  <Nav.Item className="width-100">
                    <Nav.Link
                      eventKey={"two"}
                      className={
                        Collectiontype == "PROJECT"
                          ? "d-flex align-items-center justify-content-center gap-3 tab_btn active"
                          : "d-flex align-items-center justify-content-center gap-3 tab_btn"
                      }
                    >
                      <imgs
                        src={photograpy}
                        alt="photograpy"
                        className="common_filter_img_logo"
                      />
                      <p className="mb-0 slder_p">PROJECT</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Slider>
            </Container>

            {/* <Container fluid className="custom_slider_container mkt_cust_sld">
              <Slider {...settings}>
                {Categorys?.length !== 0 &&
                  Categorys?.map((item, index) => (
                    <Nav
                      variant="pills"
                      className="d-flex align-items-center justify-content-between tab_nav_itm gap-3"
                      id="outsider"
                      onClick={() => (
                        SetTabName(item.label),
                        push(`/collections/${item.label}`)
                      )}
                    >
                      <Nav.Item className="width-100">
                        <Nav.Link
                          eventKey={index}
                          className={
                            TabName == item.label
                              ? "d-flex align-items-center justify-content-center gap-3 tab_btn active"
                              : "d-flex align-items-center justify-content-center gap-3 tab_btn"
                          }
                        >
                          <img
                            src={photograpy}
                            alt="photograpy"
                            className="common_filter_img_logo"
                          />
                          <p className="mb-0">{item?.label}</p>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  ))}
              </Slider>
            </Container> */}
            <Container className="custom_container">
              <Row>
                <Col xs={12} className="mt-5">
                  <Row>
                    <Col xs={12} sm={121} lg={12} xl={12} className="">
                      <Tab.Content className="cus_tab_cont">
                        <Tab.Pane eventKey="first">
                          <Row className="pe-0">
                            {Collectionsdata[TabName]?.list?.length !== 0 ? (
                              Collectionsdata[TabName]?.list?.map((val) => (
                                <Col
                                  xs={12}
                                  md={6}
                                  lg={4}
                                  sm={6}
                                  xl={4}
                                  xxl={3}
                                  className="mb-4"
                                >
                                  <CollectionCards props={val} />
                                </Col>
                              ))
                            ) : (
                              <NoData />
                            )}
                          </Row>
                          {Collectionsdata[TabName]?.loader && (
                            <div className="d-flex align-items-center justify-content-center">
                              <Button
                                className="green_btn hover_btn mt-3 mx-auto"
                                onClick={() => LoadMore()}
                              >
                                Load More
                              </Button>
                            </div>
                          )}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="second">
                          <Row className="pe-0">
{hotCollectionData?.map((val) => (                               
<Col
                               xs={12}
                               md={6}
                               lg={4}
                               sm={6}
                               xl={4}
                               xxl={3}
                               className="mb-4"
                             >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                          <Row className="pe-0">
{hotCollectionData.map((val) => (                               
<Col
                               xs={12}
                               md={6}
                               lg={4}
                               sm={6}
                               xl={4}
                               xxl={3}
                               className="mb-4"
                             >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                          <Row className="pe-0">
{hotCollectionData.map((val) => (                              
<Col
                              xs={12}
                              md={6}
                              lg={4}
                              sm={6}
                              xl={4}
                              xxl={3}
                              className="mb-4"
                            >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                          <Row className="pe-0">
{hotCollectionData.map((val) => (                              
<Col
                              xs={12}
                              md={6}
                              lg={4}
                              sm={6}
                              xl={4}
                              xxl={3}
                              className="mb-4"
                            >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth">
                          <Row className="pe-0">
{hotCollectionData.map((val) => (                               
<Col
                               xs={12}
                               md={6}
                               lg={4}
                               sm={6}
                               xl={4}
                               xxl={3}
                               className="mb-4"
                             >
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                          <div className="d-flex align-items-center justify-content-center">
                            <Button className="green_btn hover_btn mt-3 mx-auto">
                              Load More
                            </Button>
                          </div>
                      </Tab.Pane> */}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            {/* <div
              className="position-absolute scroll_right_btn d-flex align-items-center justify-content-center"
              onClick={() => scrollingNavsRight()}
            >
              <i class="fa-solid fa-chevron-right"></i>
            </div> */}
          </div>
        </Tab.Container>
      </div>
    </section>
  );
};

export default Collections;
