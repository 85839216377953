import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Row, Col } from "react-bootstrap";

// import blogCardImg from "../../assests/svg/blogcardimg.svg";
import blogCardImg from "../../assests/svg/blg.png";
import { Link } from "react-router-dom";
import config from "../config/config";
import { DateTimeForm, isEmpty } from "../actions/common";
import NoData from "./nodata";

function BlogCards({ data }) {
  // console.log("Dfsrgdfgdfg",data);
  return !isEmpty(data) ? (
    <>
      <div className="newsbox bgbox">
        <span className="newsdate">
          {DateTimeForm(data?.date, false, false, false, true)}
        </span>
        <Row>
          <Col xs={12} md={12} lg={5} xxl={6} className="pe-lg-0">
            <img src={data.img ? `${config.IMG_URL}/${data.img}` : blogCardImg} alt={data?.heading} className="img-fluid" />
          </Col>
          <Col xs={12} md={12} lg={7} xxl={6}>
            <p className="newshead">
              {data?.heading?.length > 50 ? (
                <>{data?.heading.slice(0, 50)}...</>
              ) : (
                data?.heading
              )}
            </p>
            <p className="newscontent">
              {/* <div
                dangerouslySetInnerHTML={{
                  __html:
                    data?.content?.length > 100
                      ? data?.content.slice(0, 300)
                      : data?.content,
                }}
              /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: data?.content,
                }}
              />

            </p>
            <div className="d-flex align-items-center justify-content-center">

              <Link
                to={`/blogdetails/${data?.url}`}
                state={{ item: data }}
                className="primary_button"
              >
                {" "}
                Read more
              </Link>
            </div>
          </Col>
        </Row>
      </div>

      {/* <Card className="blog_card newsbox bgbox">
        <Card.Img
          variant="top"
          src={data.img ? `${config.IMG_URL}/${data.img}` : blogCardImg}
          src={blogCardImg}
          className="bc_top_img"
        />
        <Card.Body className="px-0">
          <p className="card_date">
            {"Published at"}
            {"  "}
            {DateTimeForm(data?.date, false, false, false, true)}
          </p>
          <p className="card_date">OCTOBER 20 , 2024</p>
          <h5 className="card_heading">
            {data?.heading?.length > 50 ? (
              <>{data?.heading.slice(0, 50)}...</>
            ) : (
              data?.heading
            )}
          </h5>

          <div className="card_content">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  data?.content?.length > 100
                    ? data?.content.slice(0, 300)
                    : data?.content,
              }}
            />
            ...
          </div>

          <div className=" mt-4">
            <Link
              to={`/blogdetails/${data?.url}`}
              state={{ item: data }}
              className="green_btn hover_btn card_btn py-2"
            >
              {" "}
              Read more
            </Link>
          </div>
        </Card.Body>
      </Card> */}

    </>
  ) : (
    <NoData />
  );
}

export default BlogCards;
