import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Dropdown, Offcanvas } from "react-bootstrap";
import logo from "../assests/img/logo.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ValidChainCheck,
  connectWallet,
  getServiceFees,
} from "./hooks/useWallet";
import {
  GetUserCookieToken,
  TOKENPRICE,
  USDPRICE,
  userRegister,
} from "./actions/axios/user.axios";
import saleImg from "../assests/img/nft.png";
import { Category, Currency } from "./actions/axios/cms.axios";

import {
  BulkCertificateUpload,
  CartAction,
  CartListGet,
  GetNftCookieToken,
  Getcart,
  SearchAction,
} from "./actions/axios/nft.axios";
import { toast } from "react-toastify";
import { Name_showing, NumberChange, isEmpty } from "./actions/common";
import config from "./config/config";
import useContractProviderHook from "./actions/contractProviderHook";

import { FaShoppingCart, FaMinus, FaPlus } from "react-icons/fa";
import { PiWarningCircleLight } from "react-icons/pi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import searchImgOne from "../assests/img/searchone.jpg";
import searchImgTwo from "../assests/img/searchtwo.jpg";

import sunIcon from "../assests/img/sunTheme.svg";
import cartImg from "../assests/img/searchone.jpg";
import CompletePurchase from "./modals/CompletePurchase";
import ImgAudVideo from "./common-components/ImagAudVideo";
import Certificate from "./other-components/Certificate";
import html2canvas from "html2canvas";
import network from "./config/network";
import Select from "react-select";

console.log('networkkkkk', network)
// import { position } from "html2canvas/dist/types/css/property-descriptors/position";

const cartData = [
  {
    id: 1,
    img: cartImg,
    titleOne: "Coinage Subscriber",
    titleTwo: "Coinage Media",
    titleThree: "Creator earnings: 7%",
    ethValue: "0.002",
  },
  {
    id: 2,
    img: cartImg,
    titleOne: "Coinage Subscriber",
    titleTwo: "Coinage Media",
    titleThree: "Creator earnings: 7%",
    ethValue: "0.002",
  },
  {
    id: 3,
    img: cartImg,
    titleOne: "Coinage Subscriber",
    titleTwo: "Coinage Media",
    titleThree: "Creator earnings: 7%",
    ethValue: "0.002",
  },
];

const Header = () => {
  const selectedTheme = localStorage.getItem("selectedTheme");

  const [show, setShow] = useState(false);
  const [search, setSearch] = useState(false);
  const [active, setActive] = useState("home");
  const [initialclick, setInitialclick] = useState(0);

  const [cartShowcer, setCartShowcer] = useState(false);

  const [val, Setval] = useState("");
  const [Searchdata, SetSearch] = useState(null);
  const [defaulttheme, setDefaulttheme] = useState(
    selectedTheme ? selectedTheme : "light"
  );

  const [cartShow, setCartShow] = useState(false);
  const [completeShow, setCompleteShow] = useState(false);
  const [Total, SetTotal] = useState({});
  const [TotalUsd, SetTotalUsd] = useState(0);

  const handleCartClose = () => {
    setCartShow(false);
    SetTab({ label: "All", value: "all" });
  };
  const handleCartShow = () => {
    if (show) {
      setShow(false);
    }
    setCartShow(true);
  };
  const handleCompletePurchaseShow = async () => {
    if (Tab?.ChainId) {
      let chainid = NumberChange(Tab?.ChainId);
      let checkchain = await ContractCall.SwitchNetwork(chainid);
      if (checkchain) {
        setCompleteShow(true);
      } else {
        return false;
      }
    } else {
      return toast.error("Select Valid Network");
    }
  };

  const wallet = useSelector((state) => state.LoginReducer?.AccountDetails);
  const { payload, token, CartList } = useSelector(
    (state) => state.LoginReducer.User
  );
  const { accountAddress, cartRefresh } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  const [instcarlist, SetinstCartList] = useState({
    All: { data: [], ChainId: undefined },
  });
  const [options, SetOptions] = useState({});
  const [Tab, SetTab] = useState({});

  useEffect(() => {
    SetOptions({
      ...{ All: { label: "All", value: "all" } },
      ...network,
    });
    SetTab({ label: "All", value: "all" });
  }, [network]);

  const { allcurrency, currency } = useSelector((state) => state.LoginReducer);
  const testdata = useSelector((state) => state.LoginReducer.User);

  console.log("aaaaaaaaaaaaa", testdata, wallet);

  const CertificateRef = useRef();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    // setInitialclick(initialclick + 1);
    // if (initialclick == 1) {
    // setDefaulttheme("light");
    // }
    setShow(true);
  };

  const statetest = useSelector((state) => state);
  const dispatch = useDispatch();
  const push = useNavigate();
  const ContractCall = useContractProviderHook();

  // dark and light theme

  const setDarkTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
  };
  const setLightTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
  };

  const [themeImg, setThemeImg] = useState();
  if (selectedTheme === "dark") {
    setDarkTheme();
  }

  const toggleTheme = (e) => {
    console.log(e.target.checked, "targetxx");
    if (e.target.checked) {
      setThemeImg(false);
      setDefaulttheme("light");
      setLightTheme();
    } else {
      setDefaulttheme("dark");

      setDarkTheme();
      setThemeImg(true);
    }
    dispatch({
      type: "themechange",
      value: { theme: e.target.checked ? "light" : "dark" },
    });
  };

  useEffect(() => {
    // alert(1);
    setDefaulttheme(localStorage.getItem("selectedTheme"));
    if (defaulttheme == "dark") {
      setDarkTheme();
    } else {
      setLightTheme();
    }
    let currentTheme = localStorage.getItem("selectedTheme");
    if (currentTheme === "dark") {
      currentTheme = "dark";
      console.log("currentTheme", currentTheme);
    } else {
      currentTheme = "light";
      console.log("currentTheme", currentTheme);
    }
    dispatch({
      type: "themechange",
      value: { theme: currentTheme },
    });
  }, [show]);

  useEffect(() => {
    // alert(1);
    setDefaulttheme(localStorage.getItem("selectedTheme"));
    if (defaulttheme == "dark") {
      setDarkTheme();
    } else {
      setLightTheme();
    }
    let currentTheme = localStorage.getItem("selectedTheme");
    if (currentTheme === "dark") {
      currentTheme = "dark";
      console.log("currentTheme", currentTheme);
    } else {
      currentTheme = "light";
      console.log("currentTheme", currentTheme);
    }
    dispatch({
      type: "themechange",
      value: { theme: currentTheme },
    });
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("walletConnectType") &&
      wallet.accountAddress == ""
    ) {
      console.log(
        "wallleeeeeeetconnectingddd",
        localStorage.getItem("walletConnectType")
      );
      initialConnectWallet(localStorage.getItem("walletConnectType"));
    }
    Categorys1();
    CurrencyList();
    getInitialSeviceFee();
    // Stakeoptions()
  }, []);

  useEffect(() => {
    if (window.ethereum && localStorage.getItem("walletConnectType")) {
      // window.ethereum.on('chainChanged', () => {
      //   console.log('changeeeeeee1111')
      //   initialConnectWallet(localStorage.getItem("walletConnectType"));
      // })
      // window.ethereum.on("accountsChanged", () => {
      //   console.log("changeeeeeee");
      //   initialConnectWallet(localStorage.getItem("walletConnectType"));
      // });
    }
  }, []);

  const Categorys1 = async () => {
    let Resp = await Category();

    if (Resp?.data) {
      var sendda = [];
      var data = (Resp.data || []).map((item) => {
        if (item.hideShow !== "hidden") {
          sendda.push({
            label: item.name,
            value: item.name,
            description: item.description,
          });
        }
      });
      //   setCategory(sendda)
      dispatch({
        type: "Register_Section",
        Register_Section: {
          Categorys: sendda,
        },
      });
    }
  };
  const CurrencyList = async () => {
    let Resp = await Currency();
    console.log("RespResp", Resp);

    if (!isEmpty(Resp?.data)) {
      var sen = [];
      if (Resp?.data?.length > 0) {
        await Promise.all(
          Resp?.data?.map(async (val) => {
            if (val?.CurrencyDetails?.length > 0) {
              await Promise.all(
                val?.CurrencyDetails?.map(async (data) => {
                  console.log("datadatadata", data, val);
                  if (!data.deleted) {
                    var USD = await USDPRICE(data.label);
                    // else var USD = await USDPRICE(data.address);
                    sen.push({
                      value: data.value,
                      label: data.label,
                      address: data.address,
                      usd: USD ? USD : 0,
                      decimal: data.decimal,
                      ChainId: val?.ChainId,
                    });
                  }
                })
              );
            }
          })
        );
      }
      // console.log("sensen",sen);
      dispatch({
        type: "Register_Section",
        Register_Section: {
          allcurrency: sen,
        },
      });
    }
  };

  const initialConnectWallet = async (type) => {
    const id = toast.loading(type + " Wallet Connecting...", {
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });

    var accountDetails = await connectWallet(type);
    console.log("accounttt", accountDetails);
    if (!isEmpty(accountDetails?.web3)) {
      console.log("accounttt1111");
      var resp = await setWalletAddress(
        "InitialConnect",
        accountDetails.accountAddress,
        type
      );
      console.log("resprespresp", resp);
      if (resp?.success == "success") {
        toast.update(id, {
          render: resp.msg,
          type: resp.success,
          autoClose: 1000,
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
        });
        dispatch({
          type: "Account_Section",
          Account_Section: {
            AccountDetails: { ...accountDetails, cartRefresh: 1 },
          },
        });
        // navigate("/")
      } else {
        toast.update(id, {
          render: "Connect Wallet",
          type: "error",
          autoClose: 1000,
          isLoading: false,
        });
      }
    } else {
      toast.update(id, {
        render: accountDetails?.display?.msg
          ? accountDetails?.display?.msg
          : "Try Again",
        type: accountDetails?.display?.type
          ? accountDetails?.display?.type
          : "error",
        autoClose: 1000,
        isLoading: false,
        closeButton: true,
        closeOnClick: true,
      });
      dispatch({
        type: "Account_Section",
        Account_Section: {
          AccountDetails: {},
        },
      });
      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {},
        },
      });
    }
  };

  const setWalletAddress = async (type, walletAddress, walletType) => {
    if (walletAddress) {
      var NewMethod = {
        Type: type,
        WalletAddress: walletAddress,
        WalletType: walletType,
      };
      let Resp = await userRegister(NewMethod);
      // console.log("inittttt", Resp);
      if (Resp?.success == "success") {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              payload: Resp.data,
              token: Resp.token ? Resp?.token : token,
            },
          },
        });
        document.cookie = "token" + "=" + Resp?.token + ";" + ";path=/";
        GetNftCookieToken();
        GetUserCookieToken();
        return Resp;
      } else return Resp;
    } else
      return {
        success: "error",
        msg: "No Address Detected.. Check Your Wallet",
      };
  };
  const walletDisconnect = async () => {
    localStorage.removeItem("accountInfo");
    localStorage.removeItem("walletConnectType");
    dispatch({
      type: "Account_Section",
      Account_Section: {
        AccountDetails: {
          accountAddress: "",
          tokenBalance: 0,
          coinBalance: 0,
        },
      },
    });
    // window.location.reload();
    document.cookie = "token" + "=" + "" + ";" + ";path=/";
    GetNftCookieToken();
    GetUserCookieToken();
    push("/signin");
  };

  const getInitialSeviceFee = async () => {
    var fees = await getServiceFees();
    console.log("feesfeesfees", fees);
    if (fees) {
      dispatch({
        type: "ServiceFees",
        ServiceFees_Section: {
          ServiceFees: fees,
        },
      });
    }
  };
  const OnSearch = async (value) => {
    if (value) {
      Setval(value);
      var Resp = await SearchAction({
        keyword: value,
        limit: 3,
        page: 1,
        from: "search",
      });
      if (Resp?.data.success == "success") {
        SetSearch(Resp.data);
      } else {
        SetSearch(null);
      }
    } else {
      SetSearch(null);
      Setval("");
    }
  };

  useEffect(() => {
    if (accountAddress) {
      GetCartData();
    }
  }, [cartShow, accountAddress, wallet?.currentNetwork, Tab, cartRefresh]);

  useEffect(() => {
    console.log("cureeentcurrency", wallet?.web3, allcurrency);
    if (wallet?.web3) {
      GetCurrentCurrency();
      getInitialSeviceFee();
    }
  }, [wallet?.web3, accountAddress, allcurrency]);

  const GetCurrentCurrency = async () => {
    if (wallet?.web3) {
      try {
        if (allcurrency?.length > 0) {
          let getchain = await wallet?.web3?.eth?.getChainId();
          let validcurrency = [];
          allcurrency?.map((val) => {
            if (parseInt(val?.ChainId) == parseInt(getchain)) {
              validcurrency.push(val);
            }
          });
          console.log("validcurrencyvalidcurrency", validcurrency, allcurrency);
          dispatch({
            type: "Register_Section",
            Register_Section: {
              allcurrency: allcurrency,
              currency: validcurrency,
            },
          });
        }
      } catch (err) {
        console.log("GetCurrentCurrency_error", err);
      }
    }
  };

  // useEffect(()=>{
  //   if(cartRefresh){
  //     GetCartlist();
  //   }
  // },[cartRefresh,wallet?.currentNetwork])

  const GetCartlist = async () => {
    if (accountAddress) {
      var data = await CartListGet({
        UserAddress: accountAddress,
        ChainId: Tab?.ChainId,
      });

      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {
            CartList: data.msg,
            payload: payload,
            token: token,
          },
        },
      });
    }
  };

  const Convertusd = (price, coinname) => {
    let usdcueency = allcurrency.filter((item) => item.value == coinname)[0]
      ?.usd;
    let finalusdprice = usdcueency * price;
    return finalusdprice;
  };

  const GetCartData = async () => {
    try {
      let Resp = await Getcart({
        WalletAddress: accountAddress,
        ChainId: Tab?.ChainId,
      });
      console.log("carttttttttt", payload, token, Resp?.data);

      if (Resp?.data?.length > 0) {
        let totalprc = {};
        let totalusd = 0;

        Resp?.data?.map((val, index) => {
          if (val?.CoinName) {
            if (totalprc?.[val?.CoinName]) {
              totalprc[val.CoinName] = (
                Number(val.NFTQuantity) * Number(val.NFTPrice) +
                Number(totalprc[val.CoinName])
              ).toFixed(4);
              val.Price = Number(val.NFTQuantity) * Number(val.NFTPrice);
            } else {
              totalprc[val.CoinName] = (
                Number(val.NFTQuantity) * Number(val.NFTPrice)
              ).toFixed(4);
              val.Price = Number(val.NFTQuantity) * Number(val.NFTPrice);
            }
            totalusd = (
              Number(totalusd) +
              Number(val.NFTQuantity) *
              (Number(val.NFTPrice) /
                Convertusd(Number(val?.NFTPrice), val?.CoinName))
            ).toFixed(4);
          }
        });

        console.log("totalprctotalprc", totalprc);
        Object.keys(totalprc).map((val) => {
          const token_det = allcurrency
            ?.filter(
              (item) =>
                item.label == (val == "PancakeSwap Token" ? "CAKE" : val)
            )
            ?.pop();
          console.log("token_dettoken_det", token_det);
          totalprc[val] = ContractCall.buy_bid_price_calculation(
            Number(totalprc[val]),
            token_det.decimal
          );
        });
        console.log("totalprctotalprcbeforeee", totalprc);
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              CartList: [...(Array.isArray(Resp?.data) ? Resp?.data : [])],
              payload: payload,
              token: token,
            },
          },
        });
        // SetCartList(Array.isArray(Resp?.data) ? Resp?.data : [])
        SetTotal(totalprc);
        SetTotalUsd(totalusd);
        GetNftCookieToken();
      } else {
        dispatch({
          type: "Register_Section",
          Register_Section: {
            User: {
              CartList: [],
              payload: payload,
              token: token,
            },
          },
        });
        // SetCartList(Array.isArray(Resp?.data) ? Resp?.data : [])
        SetTotal({});
        SetTotalUsd(0);
      }
    } catch (err) {
      console.log("GetCartDataerrorrr", err);
    }
  };

  console.log("totalllllll", Total);

  const CartUpdate = async (action, item, remove) => {
    console.log("itemitem", item);
    var senddata = {
      NFTId: item?.NFTId,
      WalletAddress: item?.WalletAddress,
      ContractType: item?.ContractType,
      ContractAddress: item?.ContractAddress,
      NFTOwner: item?.NFTOwner,
      UserAddress: accountAddress,
      CollectionNetwork: item?.CollectionNetwork,
      CollectionUrl: item?.CollectionUrl,
      action: action,
      remove: remove,
      ChainId: item?.ChainId,
    };
    var update = await CartAction(senddata);
    console.log("updateeeeeee", update);
    // var ids = toast.loading('Updating');
    // setTimeout(()=>{
    if (update?.success == "error") {
      toast.error(update?.msg, { autoClose: 800 });
    }
    GetCartData();
    // },1000);
  };

  const DownloadCertificate = async () => {
    // try {
    let ownerdata = [];
    let newownerdata = [];
    let nftdetail = [];
    for (let i = 0; i < CartList?.length; i++) {
      CertificateRef.current = document.getElementById(`creator${i}`);
      let canvas = await html2canvas(CertificateRef.current);
      let imageind = {};
      if (canvas) {
        const dataURL = await canvas.toDataURL("image/png");
        var fileDataCon = await dataURLtoFile(dataURL, CartList?.[i]?.NFTName);
        // formData.nftimage.push(fileDataCon)
        ownerdata.push(fileDataCon);
        imageind.ownerindex = i;
      }
      CertificateRef.current = document.getElementById(`owner${i}`);
      canvas = await html2canvas(CertificateRef.current);
      if (canvas) {
        const dataURL = await canvas.toDataURL("image/png");
        var fileDataCon = await dataURLtoFile(dataURL, CartList?.[i]?.NFTName);
        // formData.nftimage.push(fileDataCon)
        newownerdata.push(fileDataCon);
        imageind.newownerindex = i;
        // return fileDataCon;
      }
      nftdetail.push({ ...CartList?.[i], ...imageind });
    }
    let Resp = await BulkCertificateUpload({
      ownerdata: ownerdata,
      newownerdata: newownerdata,
      nftdetail: JSON.stringify(nftdetail),
    });

    return Resp;
    // }
    // catch (err) {
    //   console.log('DownloadCertificate_errrr', err)
    // }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // const Removenft = async(type) =>{
  //   var senddata = {
  //     TokenId: item.TokenId,
  //     WalletAddress: item.WalletAddress,
  //     ContractType: item.ContractType,
  //     ContractAddress: item.ContractAddress,
  //     TokenOwner: item.TokenOwner,
  //     Userid: item.Userid,
  //     CollectionNetwork: item.CollectionNetwork,
  //     from: item.from,
  //     CollectionUrl: item.CollectionUrl,
  //     remove: type,
  //   }
  //   var removed = await AxiosFile.CartAction(senddata)
  //   var ids = toast.loading('Removing');
  //   setTimeout(()=>{
  //     toast.update(ids,{render:'Token Removed from Cart',type:'success',autoClose:800,isLoading:false})
  //     GetCartTokens();
  //   },1000)
  // }

  const customCurrencyRenderer = ({ label, data, value }) => (
    <div
      className="market_select_component marktet_select_wlet d-flex justify-content-start align-items-center"
      onClick={() => {
        if (data?.onClick) {
          data?.onClick();
          currencyRef.current.blur();
          setCloser(!closer);
        } else {
          ChangeNetwork(data);
          currencyRef.current.blur();
          setCloser(!closer);
        }
      }}
    >
      <div className="react_select_imag_wrap">
        <img
          src={data.img}
        // style={{ width: "15px", height: "15px", marginRight: "10px" }}
        />
      </div>

      {label}
    </div>
  );
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "eth",
    label: "ETH",
    img: require("../assests/img/stakecoin2.png"),
  });
  const [closer, setCloser] = useState();
  const currencyRef = useRef();

  const [currencyOptions, SetCurrencyOption] = useState();

  useEffect(() => {
    if (Object.values(network).length > 0) {
      SetCurrencyOption([
        ...Object.values(network),
        ...[
          {
            value: "disconnect",
            label: "Disconnect",
            img: require("../assests/img/disconnect2.png"),
            onClick: () => {
              walletDisconnect();
              handleClose();
            },
          },
        ],
      ]);
    }
  }, [wallet?.currentNetwork]);

  const ChangeNetwork = async (e) => {
    try {
      console.log("dataaaaa", e);
      if (e?.CHAIN_ID) {
        let data = await ContractCall.SwitchNetwork(e?.CHAIN_ID);
      } else {
        toast.error("Invalid Network");
      }
    } catch (err) {
      console.log("ChangeNetwork_error", e);
    }
  };

  const AddPool = async () => {
    let chckpool = await ContractCall.AddStackpool({ rewardtoken: "0x862a9238293ad08e5c750c236f3e41483f9cad11" });
    console.log('chckpool', chckpool);
  }

  const [headscroll, setHeadscroll] = useState(window.scrollY);

  useEffect(() => {
    const handlescroll = () => {
      setHeadscroll(window.scrollY);
    }

    window.addEventListener("scroll", handlescroll);

    return () => {
      window.removeEventListener("scroll", handlescroll);
    }
  }, [window.scrollY]);



  return (
    <>
      <Container fluid className={`whole_header px-0 ${headscroll > 100 && "whole_header_scroll"}`}>
        <Container className="custom_container hds_85px d-flex align-items-center">
          {/* <Row className="header_row"> */}
          <div className="header_row d-flex align-items-center justify-content-between w-100">
            <div className="px-0">
              <Link className="hader_logo" to="/">
                <img className="" src={logo} alt="logo" />
              </Link>
            </div>

            <div className="header_navi">
              <div className="header_navs">
                <ul>
                  <li className="navlink">
                    <NavLink to="/" className="custom_link">
                      Home
                    </NavLink>
                  </li>

                  {/* <li className="navlink">
                    <Dropdown>
                      <Dropdown.Toggle>Stats</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>stats 1</Dropdown.Item>
                        <Dropdown.Item>stats 2</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="navlink">
                    <Dropdown>
                      <Dropdown.Toggle>Pages</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item>pages 1</Dropdown.Item>
                        <Dropdown.Item>pages 2</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li> */}

                  {/* <li className="navlink">
                    <NavLink to="/collections/All" className="custom_link">
                      Collections
                    </NavLink>
                  </li> */}

                  {/* <li className="navlink">
                    <NavLink to="/create" className="custom_link">
                      Create
                    </NavLink>
                  </li> */}

                  <li className="navlink">
                    <NavLink
                      to={`/collections/${"All"}`}
                      className="custom_link"
                    >
                      Marketplace{" "}
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div>
              {search ? (
                <></>
              ) : (
                <div className="header_right hds_rgt_sec">
                  <Link
                    className="primary_button custom_link header_crt_btn d-flex align-items-center"
                    to="/create"
                  >
                    Create
                  </Link>
                  {wallet && wallet.accountAddress ? (
                    <button
                      className="text-decoration-none bg-transparent border-0 outline-0 p-0"
                    // onClick={() => {
                    //   walletDisconnect();
                    // }}
                    >
                      {/* start of wallet select */}
                      <div className="position-relative">
                        <div className=" market_coinname_img position-absolute wallt_slt_img">
                          <div className="react_select_imag_wrap">
                            <img
                              src={wallet?.currentNetwork.img}
                              className="img-fluid"
                            />
                          </div>
                        </div>

                        <Select
                          // menuIsOpen={true}
                          isSearchable={false}
                          defaultValue={wallet?.currentNetwork}
                          options={currencyOptions}
                          value={wallet?.currentNetwork}
                          components={{ Option: customCurrencyRenderer }}
                          ref={currencyRef}
                          classNamePrefix="wallet_select"
                          placeholder="All Payment Methods"
                        />
                      </div>
                      {/* end of wallet select */}
                    </button>
                  ) : (
                    <Link
                      to="/signin"
                      className="primary_button custom_link header_crt_btn d-flex align-items-center me-xl-3"
                    >
                      CONNECT WALLET
                    </Link>
                  )}

                  {/* <button
                    type="button"
                    className="primary_button custom_link header_crt_btn d-flex align-items-center me-xl-3"
                    onClick={()=>AddPool()}
                  >Add Pool</button> */}

                  <div className="dark_mode d-flex">
                    <input
                      className="dark_mode_input"
                      type="checkbox"
                      id="darkmode-toggle"
                      defaultChecked={defaulttheme == "light" ? true : false}
                      onChange={toggleTheme}
                    />
                    <label className="dark_mode_label" for="darkmode-toggle">
                      {(defaulttheme === "dark" ||
                        selectedTheme === "dark") && (
                          <img
                            className="theme_moon position-absolute"
                            src={require("../assests/svg/Moon.svg").default}
                          />
                        )}
                      {(defaulttheme === "light" ||
                        selectedTheme === "light") && (
                          <img
                            className="theme_sun position-absolute"
                            src={sunIcon}
                          />
                        )}
                    </label>
                  </div>

                  <img
                    className="header_svg"
                    src={require("../assests/svg/search.svg").default}
                    onClick={() => setSearch(true)}
                  />

                  {accountAddress && (
                    <div>
                      <img
                        className="header_svg"
                        src={require("../assests/svg/user.svg").default}
                        alt="user"
                        onClick={() => push("/author")}
                      />
                    </div>
                  )}
                  {/* <div>
                    <Dropdown className="header_dropdown cust_heads_drpdwn">
                      <Dropdown.Toggle variant="dark" id="dropdown-basic">
                        <img
                          className="header_svg"
                          src={require("../assests/svg/user.svg").default}
                          alt="user"
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="header_dropmenu">
                        <Dropdown.Item href="javascript:void(0)">
                          <Link to="/author" className="drp_dwn_a">
                            My Profile
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                          <Link
                            to="/activity"
                            className="custom_link drp_dwn_a"
                          >
                            Activity
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item href="javascript:void(0)">
                          {wallet && wallet.accountAddress ? (
                            <Link
                              to="/signin"
                              className="custom_link drp_dwn_a"
                              onClick={() => {
                                walletDisconnect();
                              }}
                            >
                              Disconnect
                            </Link>
                          ) : (
                            <Link
                              to="/signin"
                              className="custom_link drp_dwn_a"
                            >
                              Connect Wallet
                            </Link>
                          )}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}

                  {accountAddress && (
                    <button
                      className="position-relative heads_cart_btn px-0"
                      onClick={handleCartShow}
                    >
                      {/* <FaShoppingCart className="heads_crt_icn" /> */}
                      <img
                        src={require("../assests/svg/cartsvg.svg").default}
                        alt=""
                      />
                      {CartList?.length > 0 && (
                        <div className="heads_cart_count_wrap d-flex align-items-center justify-content-center position-absolute">
                          <p className="mb-0 mt-1">
                            {CartList?.length > 0 ? CartList?.length : ""}
                          </p>
                        </div>
                      )}
                    </button>
                  )}

                  {/* <img
                    className="header_svg"
                    src={require("../assests/svg/search.svg").default}
                    onClick={() => setSearch(true)}
                  /> */}
                </div>
              )}

              {search ? (
                <></>
              ) : (
                <div className="header_burger">
                  <img
                    className="me-3"
                    // src={require("../assests/svg/search.svg").default}
                    onClick={() => setSearch(true)}
                  />
                  <i class="fa-solid fa-bars" onClick={handleShow} />
                </div>
              )}

              {search ? (
                <div className="header_search">
                  <i class="fa-solid fa-magnifying-glass" />
                  {/* <input
                    type="text"
                    placeholder="Search here..."
                    className="header_sbar"
                  /> */}

                  <Dropdown className="hd_search_inpt">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <input
                        type="text"
                        placeholder="Search here..."
                        className="header_sbar"
                        onChange={(e) => OnSearch(e?.target?.value)}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="header_dropmenu">
                      {/* {  Searchdata?.token?.data?.length !== 0 ||
                    Searchdata?.user?.msg?.length !== 0 ||   Searchdata?.collection?.msg?.length !==  0 && */}
                      <>
                        {Searchdata?.user?.data?.length !== 0 &&
                          Searchdata?.user?.data?.length !== undefined && (
                            <>
                              <Dropdown.Header>Users</Dropdown.Header>
                              {Searchdata?.user?.data?.length != 0 &&
                                Searchdata?.user?.data?.map((value) => (
                                  <Dropdown.Item
                                    href="javascript:void(0)"
                                    className="py-2"
                                  >
                                    <Link
                                      to={`/author/${value?.CustomUrl}`}
                                      className="drp_dwn_a"
                                    >
                                      <Row>
                                        <Col xs={3} md={2}>
                                          <div className="hd_search_img_wrap">
                                            <img
                                              src={`${config.IMG_URL}/user/${value.WalletAddress}/profile/${value.Profile}`}
                                              alt="search img"
                                            />
                                          </div>
                                        </Col>

                                        <Col
                                          xs={9}
                                          md={10}
                                          className="d-flex align-items-center"
                                        >
                                          {Name_showing(value.DisplayName)}
                                        </Col>
                                      </Row>
                                    </Link>
                                  </Dropdown.Item>
                                ))}
                            </>
                          )}
                        {Searchdata?.token?.data.length !== 0 &&
                          Searchdata?.token?.data?.length !== undefined && (
                            <>
                              <Dropdown.Header>Tokens</Dropdown.Header>

                              {Searchdata?.token?.data?.length !== 0 &&
                                Searchdata?.token?.data.map((value) => (
                                  <Dropdown.Item
                                    href="javascript:void(0)"
                                    className="py-2"
                                  >
                                    <Link
                                      to={`/product/${value?.CollectionNetwork}/${value?.ContractAddress}/${value?.NFTOwner}/${value?.NFTId}`}
                                      className="drp_dwn_a"
                                    >
                                      <Row>
                                        <Col xs={3} md={2}>
                                          <div className="hd_search_img_wrap">
                                            <img
                                              src={
                                                value?.CompressedFile?.includes(
                                                  "webp"
                                                )
                                                  ? `${config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT/${value.CompressedFile}`
                                                  : `${config.IMG_URL}/nft/${value.NFTCreator}/Compressed/NFT_THUMB/${value.CompressedThumbFile}`
                                              }
                                              alt="search img"
                                            />
                                          </div>
                                        </Col>
                                        <Col
                                          xs={9}
                                          md={10}
                                          className="d-flex align-items-center"
                                        >
                                          {value.NFTName}{" "}
                                        </Col>
                                      </Row>
                                    </Link>
                                  </Dropdown.Item>
                                ))}
                            </>
                          )}

                        {Searchdata?.collection?.data.length !== 0 &&
                          Searchdata?.collection?.data?.length !==
                          undefined && (
                            <>
                              <Dropdown.Header>Collection</Dropdown.Header>

                              {Searchdata?.collection?.data?.length !== 0 &&
                                Searchdata?.collection?.data?.map((value) => (
                                  <Dropdown.Item
                                    href="javascript:void(0)"
                                    className="py-2"
                                  >
                                    <Link
                                      to={`/collectiondetail/${value.Collectionurl}`}
                                      className="drp_dwn_a"
                                    >
                                      <Row>
                                        <Col xs={3} md={2}>
                                          <div className="hd_search_img_wrap">
                                            <img
                                              src={`${config.IMG_URL}/collection/profile/${value.Collectionurl}/${value.CollectionProfileImage}`}
                                              alt="search img"
                                            />
                                          </div>
                                        </Col>
                                        <Col
                                          xs={9}
                                          md={10}
                                          className="d-flex align-items-center"
                                        >
                                          {value.CollectionName}{" "}
                                        </Col>
                                      </Row>
                                    </Link>
                                  </Dropdown.Item>
                                ))}
                            </>
                          )}
                        <div className="py-3 d-flex justify-content-center">
                          <Link
                            to={`/search/${val}`}
                            className="green_btn hover_btn btn_saerc"
                          >
                            Search
                          </Link>
                        </div>
                      </>
                      {/* } */}
                    </Dropdown.Menu>
                  </Dropdown>
                  <i
                    class="fa-solid fa-xmark"
                    onClick={() => setSearch(false)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Container>
      </Container>

      {/* offcanva content */}
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        className="header_offcanva d-lg-none"
        backdrop="static"
      >
        <Offcanvas.Header>
          <div className="header_offcanvahead">
            <img className="offcanva_header_logo" src={logo} />
            <i class="fa-solid fa-xmark" onClick={() => handleClose()}></i>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="header_offcanvaList">
            <ul>
              {/* <li onClick={() => handleClose()}>
                <Link to="/" className="custom_link">
                  Home
                </Link>
              </li> */}
              {/* <li>
                <Dropdown className="header_dropdown">
                  <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    Stats
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown className="header_dropdown">
                  <Dropdown.Toggle variant="dark" id="dropdown-basic">
                    Pages
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li> */}

              <li className="navlink" onClick={() => handleClose()}>
                <NavLink to="/" className="custom_link">
                  Home
                </NavLink>
              </li>

              <li className="navlink">
                {/* <NavLink to="/topnft" className="custom_link">
                      Stats
                    </NavLink> */}
                <Dropdown>
                  <Dropdown.Toggle>Stats</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>stats 1</Dropdown.Item>
                    <Dropdown.Item>stats 2</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="navlink">
                <Dropdown>
                  <Dropdown.Toggle>Pages</Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>pages 1</Dropdown.Item>
                    <Dropdown.Item>pages 2</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              {/* <li className="navlink" onClick={() => handleClose()}>
                <NavLink to="/topnft" className="custom_link">
                  Stats
                </NavLink>
              </li> */}
              {/* <li className="navlink" onClick={() => handleClose()}>
                <NavLink to="/collections/All" className="custom_link">
                  Collections
                </NavLink>
              </li> */}
              {/* <li className="navlink" onClick={() => handleClose()}>
                <NavLink to="/create" className="custom_link">
                  Create
                </NavLink>
              </li> */}
              {/* <li className="navlink" onClick={() => handleClose()}>
                <NavLink to={`/marketplace/${"All"}`} className="custom_link">
                  Marketplace
                </NavLink>
              </li> */}
              {/* <li onClick={() => handleClose()}>
                <Link to="/collections" className="custom_link">
                  Marketplace
                </Link>
              </li> */}
              <li onClick={() => handleClose()}>
                <Link
                  to={`/marketplace/${"All"}`} // state={}
                  className="custom_link"
                >
                  Marketplace
                </Link>
              </li>

              <li>
                <Link
                  className="primary_button custom_link header_crt_btn d-flex align-items-center mb-3"
                  to="/create"
                >
                  Create
                </Link>
                {wallet && wallet.accountAddress ? (
                  <button
                    className="text-decoration-none bg-transparent border-0 outline-0 my-1 px-0"
                    // onClick={() => {
                    //   walletDisconnect();
                    //   handleClose();
                    // }}
                    type="button"
                  >
                    {/* start of wallet select */}
                    <div className="position-relative">
                      {/* <img
                        src={wallet?.currentNetwork.img}
                        className="img-fluid market_coinname_img position-absolute wallt_slt_img"
                      /> */}

                      <div className=" market_coinname_img position-absolute wallt_slt_img">
                        <div className="react_select_imag_wrap">
                          <img
                            src={wallet?.currentNetwork.img}
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <Select
                        isSearchable={false}
                        // menuIsOpen={true}
                        defaultValue={wallet?.currentNetwork}
                        onChange={ChangeNetwork}
                        options={currencyOptions}
                        value={wallet?.currentNetwork}
                        components={{ Option: customCurrencyRenderer }}
                        ref={currencyRef}
                        classNamePrefix="wallet_select"
                        placeholder="All Payment Methods"
                      />
                    </div>
                    {/* end of wallet select */}
                  </button>
                ) : (
                  <button
                    className="primary_button header_crt_btn"
                    onClick={() => {
                      handleClose();
                      push("/signin");
                    }}
                    type="button"
                  >
                    CONNECT WALLET
                  </button>
                )}
              </li>

              <li>
                {/* <label class="switch">
                  <img
                    className={themeImg ? "switch_moonHide" : "switch_moon"}
                    src={require("../assests/svg/Moon.svg").default}
                  />
                  <img
                    className={themeImg ? "switch_sun" : "switch_sunHide"}
                    src={require("../assests/svg/Sun.svg").default}
                  />
                  <input
                    type="checkbox"
                    checked={themeImg}
                    onChange={toggleTheme}
                    defaultChecked={selectedTheme === "dark"}
                  />
                  {console.log(themeImg, "dfsfsdfs")}
                  <span class="slider round" />
                </label> */}
                {/* <div className="dark_mode d-flex" onChange={toggleTheme}> */}
                {/* <div className="dark_mode d-flex">
                  <input
                    className="dark_mode_input"
                    type="checkbox"
                    id="darkmode-toggle-new"
                    // checked={val}
                    // defaultChecked={selectedTheme === "dark"}
                    defaultChecked={defaulttheme == "light" ? true : false}
                    onChange={toggleTheme}
                  />
                  <label className="dark_mode_label" for="darkmode-toggle-new">
                    <img
                      className="theme_moon position-absolute"
                      src={require("../assests/svg/Sun.svg").default}
                    />
                    <img
                      className="theme_sun position-absolute"
                      src={require("../assests/svg/Moon.svg").default}
                    />
                  </label>
                </div> */}
                <div className="dark_mode d-flex">
                  <input
                    className="dark_mode_input"
                    type="checkbox"
                    id="darkmode-toggle-new"
                    defaultChecked={defaulttheme == "light" ? true : false}
                    onChange={toggleTheme}
                  />
                  <label className="dark_mode_label" for="darkmode-toggle-new">
                    {(defaulttheme === "dark" || selectedTheme === "dark") && (
                      <img
                        className="theme_moon position-absolute"
                        src={require("../assests/svg/Moon.svg").default}
                      />
                    )}
                    {(defaulttheme === "light" ||
                      selectedTheme === "light") && (
                        <img
                          className="theme_sun position-absolute"
                          src={sunIcon}
                        />
                      )}
                  </label>
                </div>
              </li>

              {/* <img
                className="header_svg mb-2"
                src={require("../assests/svg/search.svg").default}
                onClick={() => setSearch(true)}
              /> */}
              {accountAddress && (
                <li>
                  {/* <img src={require("../assests/svg/user.svg").default} /> */}
                  <div>
                    <img
                      className="header_svg"
                      src={require("../assests/svg/user.svg").default}
                      alt="user"
                      onClick={() => {
                        push("/author");
                        handleClose();
                      }}
                    />
                    {/* <Dropdown className="header_dropdown cust_heads_drpdwn">
                    <Dropdown.Toggle variant="dark" id="dropdown-basic">
                      
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="header_dropmenu">
                      <Dropdown.Item href="javascript:void(0)">
                        <Link to="/author" className="drp_dwn_a">
                          My Profile
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="javascript:void(0)">
                        <Link to="/activity" className="custom_link drp_dwn_a">
                          Activity
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  </div>
                </li>
              )}

              <li>
                {" "}
                {accountAddress && (
                  <button
                    className="position-relative heads_cart_btn px-0"
                    onClick={handleCartShow}
                  >
                    <img
                      src={require("../assests/svg/cartsvg.svg").default}
                      alt=""
                    />
                    {/* <FaShoppingCart className="heads_crt_icn" /> */}
                    {CartList?.length > 0 && (
                      <div className="heads_cart_count_wrap d-flex align-items-center justify-content-center position-absolute">
                        <p className="mb-0 mt-1">{CartList?.length}</p>
                      </div>
                    )}
                  </button>
                )}
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      {/* <button onClick={()=>setCartShowcer(true)} className="download_btn">Downloadddd</button> */}
      {console.log("cartttttttt", CartList)}
      <Offcanvas
        show={cartShow}
        placement="end"
        className="header_offcanva heads_cart_offcanva "
        onHide={() => {
          handleCartClose();
        }}
        backdrop="static"
      >
        <Offcanvas.Header
          closeButton
          className="d-flex flex-column align-items-start"
        >
          <div className="header_offcanvahead">
            {/* <img className="img-fluid" src={logo} /> */}

            <div className="d-flex align-items-center gap-4">
              <h5 className="mb-0 heads_cart_offcanva_h">Your Cart </h5>
              <Select
                options={Object.values(options)}
                value={Tab}
                onChange={(e) => SetTab(e)}
                classNamePrefix="your_cart_select"
              // menuIsOpen={true}
              />
            </div>

            <i class="fa-solid fa-xmark" onClick={() => handleCartClose()}></i>
          </div>
          <div className="d-flex align-items-center mt-3">
            <p className="text-light mb-0"> Choose Specific network to buy.</p>
            <span className="heads_cart_warn ms-2">
              <PiWarningCircleLight />
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <div className="cart_canva_body_top">
              {CartList?.length > 0 && (
                <Row className="mb-3">
                  <Col>
                    <p className="cart_offcanva_items mb-0">
                      {CartList?.length} items
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <button
                      className="cart_canva_clr_btn border-0 outline-0 bg-transparent text-light cart_offcanva_items"
                      onClick={() => CartUpdate("removeall", val, true)}
                    >
                      Clear all
                    </button>
                  </Col>
                </Row>
              )}
              {CartList?.length > 0 ? (
                CartList?.map((val) => (
                  <div className="p-2 mb-3 cart_canva_div">
                    <Row className="align-items-center mx-auto">
                      <Col xs={3} className="px-0">
                        <div className="cart_offcanva_img_wrp">
                          <ImgAudVideo
                            file={
                              // nftCardVideo
                              `${config.IMG_URL}/nft/${val?.NFTCreator}/Compressed/NFT/${val?.CompressedFile}`
                            }
                            origFile={`${config.IMG_URL}/nft/${val?.NFTCreator}/Original/NFT/${val?.OriginalFile}`}
                            thumb={`${config.IMG_URL}/nft/${val?.NFTCreator}/Compressed/NFT_THUMB/${val?.CompressedThumbFile}`}
                            type={
                              // "video"
                              val?.CompressedFile
                                ? val?.CompressedFile?.includes(".webp")
                                  ? "image"
                                  : val?.CompressedFile.includes(".webm")
                                    ? "video"
                                    : "audio"
                                : val?.CompressedFile
                            }
                            className="img-fluid img_cont_fit"
                            noimg={saleImg}
                          />

                          {/* <img
                          src={val.img}
                          alt="cart_image"
                          className="img-fluid img_cont_fit"
                        /> */}
                        </div>
                      </Col>
                      <Col xs={6} className="pe-0">
                        <div>
                          <h5 className="mb-0 cart_canva_tle_one">
                            {val.NFTName}
                          </h5>
                          <p className="mb-0 cart_canva_tle_two">
                            {val.ContractType}
                          </p>
                          <p className="mb-0 cart_canva_tle_three">
                            {val?.CollectionDetail?.CollectionName}
                          </p>
                          <div className="d-flex align-items-center cart_canva_quan gap-2 mt-1">
                            <p className="mb-0 cart_canva_tle_three">
                              Quantity
                            </p>
                            <div
                              onClick={() => {
                                CartUpdate("remove", val);
                              }}
                              aria-disabled={
                                val.TokenQuantity > 1 ? false : true
                              }
                            >
                              <FaMinus />
                            </div>

                            <input
                              type="text"
                              className="cart_quan_inpt"
                              placeholder="10"
                              value={val?.NFTQuantity}
                            />
                            <div
                              onClick={() => CartUpdate("add", val)}
                              aria-disabled={
                                Number(val.TokenQuantity) >=
                                  Number(val.TokenBalance)
                                  ? true
                                  : false
                              }
                            >
                              <FaPlus />
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={3} className="pe-0 d-flex justify-content-end">
                        <div>
                          <p className="cart_canva_eth_val mb-0">
                            {" "}
                            {val.CoinName} {val?.Price}
                          </p>

                          <button
                            className="border-0 outline-0 bg-transparent p-0 d-none cart_canva_del_icn"
                            onClick={() => CartUpdate("remove", val, true)}
                          >
                            <MdOutlineDeleteOutline />
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ))
              ) : (
                <div className="p-2 mb-3 cart_canva_div">
                  Add items to get started
                </div>
              )}{" "}
            </div>

            <div className="cart_canva_footer">
              {CartList?.length > 0 && (
                <Row className="mx-auto">
                  <Col>
                    <p className="cart_canva_ttl_prc mb-0">Total Price</p>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <div>
                      <h5 className="cart_canva_ttl_prc mb-1 text-end">
                        {Object.keys(Total)?.map((key, ind) => {
                          return (
                            <>
                              {Total?.[key]} {key}{" "}
                              {ind + 1 >= Object.keys(Total)?.length
                                ? "."
                                : " && "}{" "}
                            </>
                          );
                        })}
                      </h5>
                      <p className="text-secondary mb-0 text-end">
                        ${TotalUsd}
                      </p>
                    </div>
                  </Col>
                </Row>
              )}
              {Tab?.ChainId ? (
                <button
                  className="green_btn  comp_btn my-4 w-100 mx-auto"
                  onClick={handleCompletePurchaseShow}
                  disabled={CartList?.length > 0 ? false : true}
                >
                  Complete Purchase
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        show={completeShow}
        placement="end"
        className="header_offcanva heads_cart_offcanva certif_offcan"
        onHide={() => setCartShowcer(false)}
        backdrop="static"
      >
        {
          // completeShow &&

          <>
            {CartList?.length > 0 &&
              CartList?.map((certificateval, ind) => {
                return (
                  <>
                    <div className="cartificate_contiane">
                      <Certificate
                        divid={"creator" + ind}
                        nftdata={{
                          Profile: certificateval?.Profile
                            ? `${config.IMG_URL}/user/${certificateval?.NFTOwner}/profile/${certificateval?.Profile}`
                            : undefined,
                          NFTCreator: certificateval?.NFTCreator,
                          NFTOwner: certificateval?.NFTOwner,
                          NFTQuantity:
                            NumberChange(certificateval?.CurrentNFTBalance) -
                            NumberChange(certificateval?.NFTQuantity),
                          Receptionist: certificateval?.NFTOwner,
                          Certificateid: certificateval?.Certificateid,
                          NFTName: certificateval?.NFTName,
                          NFTDescription: certificateval?.NFTDescription,
                          createdAt: certificateval?.createdAt,
                          ContractAddress: certificateval?.ContractAddress,
                          NFTId: certificateval?.NFTId,
                          ContractType: certificateval?.ContractType,
                          HashValue: certificateval?.HashValue,
                          BlockChain: ValidChainCheck(certificateval?.ChainId)
                            ?.BlockChain,
                        }}
                      />
                      <Certificate
                        divid={"owner" + ind}
                        nftdata={{
                          Profile: certificateval?.Profile
                            ? `${config.IMG_URL}/user/${certificateval?.NFTOwner}/profile/${certificateval?.Profile}`
                            : undefined,
                          NFTCreator: certificateval?.NFTCreator,
                          NFTOwner: certificateval?.NFTOwner,
                          NFTQuantity:
                            NumberChange(certificateval?.NFTQuantity) +
                            NumberChange(
                              certificateval?.BuyerQuantity
                                ? certificateval?.BuyerQuantity
                                : 0
                            ),
                          Receptionist: accountAddress,
                          Certificateid: certificateval?.Certificateid,
                          NFTName: certificateval?.NFTName,
                          NFTDescription: certificateval?.NFTDescription,
                          createdAt: certificateval?.createdAt,
                          ContractAddress: certificateval?.ContractAddress,
                          NFTId: certificateval?.NFTId,
                          ContractType: certificateval?.ContractType,
                          HashValue: certificateval?.HashValue,
                          BlockChain: ValidChainCheck(certificateval?.ChainId)
                            ?.BlockChain,
                        }}
                      />
                    </div>
                  </>
                );
              })}
          </>
        }
      </Offcanvas>

      {/* end of offcanva content */}
      {completeShow && (
        <CompletePurchase
          show={completeShow}
          setCompleteShow={setCompleteShow}
          CartNFT={CartList}
          Total={Total}
          USD={TotalUsd}
          DownloadCertificate={DownloadCertificate}
          handleCartClose={() => handleCartClose()}
          Tabdata={Tab}
        />
      )}

      {/* <button onClick={()=>{cartShowcer()}} className="donwload_btn_style">downlad</button> */}
    </>
  );
};

export default Header;
