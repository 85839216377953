import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Button, Row, Col, Modal } from "react-bootstrap";

import "./App.css";
import "../src/components/Common.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Author from "./components/pages/Author";
import HelpCenter from "./components/pages/Help";
import Activity from "./components/pages/Activity";
import Createsingle from "./components/pages/createsingle";
import "./assests/styles.css";
import LiveAuction from "./components/pages/LiveAuction";
import Blog from "./components/pages/Blog";
import Catalog from "./components/pages/Catalog";
import Collections from "./components/pages/Collections";
import Collectiondetail from "./components/pages/Collectiondetail";
import Search from "./components/pages/Search";
import TopNFT from "./components/pages/TopNFT";
import NextPage from "./components/pages/BlogDetails";

import Home from "./components/pages/Home";
import EditProfile from "./components/pages/EditProfile";
import Login from "./components/pages/Signup";
import Signin from "./components/pages/Signin";
import Product from "./components/pages/Product";
import Signup from "./components/pages/Signup";
import HelpsCenter from "./components/pages/HelpsCenter";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { GetNftCookieToken } from "./components/actions/axios/nft.axios";
import "react-toastify/dist/ReactToastify.css";

import Help from "./components/pages/Help";
import BlogDetails from "./components/pages/BlogDetails";
import Nftcard from "./components/common-components/nftcard";
import EditBid from "./components/modals/EditBid";
import Calendar from "./components/modals/Calendar";
import CancelOrder from "./components/modals/CancelOrder";
import AcceptBid from "./components/modals/AcceptBid";
import CancelBid from "./components/modals/CancelBid";
import Loader from "./components/common-components/Loader";

function App() {
  const [putOnSale, setPutOnSale] = useState(false);
  const [deleteSale, setDeleteSale] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [placeBid, setPlaceBid] = useState(false);
  const [editBid, setEditBid] = useState(false);
  const [changePrice, setChangePrice] = useState(false);
  const [transferToken, setTransferToken] = useState(false);
  const [burnToken, setBurnToken] = useState(false);
  const [followSteps, setFollowSteps] = useState(false);
  const [purchase, setPurchase] = useState(false);
  const [calender, setCalender] = useState(false);
  const [cancelOrder, setCanelOrder] = useState(false);
  const [acceptBid, setAcceptBid] = useState(false);
  const [cancelBid, setCancelBid] = useState(false);

  // 👇️ scroll to top on page load

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const wallet = useSelector((state) => state.LoginReducer?.AccountDetails);

  // const Protect =(data,login)=>{

  //   if (localStorage.getItem("walletConnectType")) {
  //            return data
  //     }

  //     else{
  //       return login ? data :<Navigate to ="/" replace></Navigate>
  //    }
  //   }

  useEffect(() => {
    if (process.env.NODE_ENV == "production") {
      console.log = () => { };
      console.warning = () => { };
      console.info = () => { };
      console.error = () => { };
    }
  }, [])
  // console.log=()=>{}
  // console.warning = ()=>{}
  // console.info=()=>{}
  // console.error=()=>{}

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        rtl={true}
        closeOnClick={true}
        closeButton={true}
        style={{
          zIndex: "999999",
          width: "400px",
          wordBreak: "break-word",
          "text-align": "center",
          fontWeight: "500",
          marginTop: "70px",
        }}
      />

      <BrowserRouter basename="/">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />

          {/* <Route path="/" element={<LiveAuction />} /> */}
          <Route path="/author" element={<Author />} />
          <Route path="/help" element={<Help />} />
          <Route path="/helpcenter/:id" element={<HelpsCenter />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/create" element={<Createsingle />} />
          <Route path="/liveauction" element={<LiveAuction />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/marketplace/:category" element={<Catalog />} />
          <Route path="/topnft" element={<TopNFT />} />
          <Route path="/next-page" element={<NextPage />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/author/:customurl" element={<Author />} />
          {/* <Route path="/" element={<LiveAuction />} /> */}
          {/* <Route path="/" element={<Collections />} /> */}
          <Route path="/collections/:category" element={<Collections />} />
          <Route
            path="/collectiondetail/:collectiondetail"
            element={<Collectiondetail />}
          />
          <Route path="/search/:key" element={<Search />} />

          <Route path="/blogdetails/:url" element={<BlogDetails />} />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route
            path="/product/:network/:Contract/:Owner/:Id"
            element={
              <Product
                setPlaceBid={setPlaceBid}
                placeBid={placeBid}
                checkout={checkout}
                setCheckout={setCheckout}
              />
            }
          />
          <Route path="/loader" element={<Loader />} />
        </Routes>
        {/* <>
        <Button
          variant="primary"
          onClick={() => {
            setPutOnSale(true);
          }}
        >
          Put On sale
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setDeleteSale(true);
          }}
        >
          Delete Instant Sale
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setCheckout(true);
          }}
        >
          Checkout
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setPlaceBid(true);
          }}
        >
          Place a bid
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setEditBid(true);
          }}
        >
          Edit a bid
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setChangePrice(true);
          }}
        >
          Change Price
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setTransferToken(true);
          }}
        >
          Transfer Token
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setBurnToken(true);
          }}
        >
          Burn Token
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setFollowSteps(true);
          }}
        >
          Follow Steps
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setPurchase(true);
          }}
        >
          Purchase
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setCalender(true);
          }}
        >
          Calender
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setCanelOrder(true);
          }}
        >
          Cancel Order
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setAcceptBid(true);
          }}
        >
          Accept
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            setCancelBid(true);
          }}
        >
          Cancel
        </Button> */}

        {/* Modal  */}

        {/* <PutOnSale show={putOnSale} onHide={() => setPutOnSale(false)} /> */}
        {/* <DeleteInstantSale
          show={deleteSale}
          onHide={() => setDeleteSale(false)}
        /> */}
        {/* <Checkout show={checkout} onHide={() => setCheckout(false)} /> */}
        {/* <PlaceBid
          show={placeBid}
          onHide={() => {
            setPlaceBid(false);
          }}
        />
        <EditBid
          show={editBid}
          onHide={() => {
            setEditBid(false);
          }}
        />
        <ChangePrice show={changePrice} onHide={() => setChangePrice(false)} />
        <TransferToken
          show={transferToken}
          onHide={() => setTransferToken(false)}
        />
        <BurnToken show={burnToken} onHide={() => setBurnToken(false)} />
        <FollowSteps show={followSteps} onHide={() => setFollowSteps(false)} />
        <Purchase show={purchase} onHide={() => setPurchase(false)} />
        <Calendar show={calender} onHide={() => setCalender(false)} />
        <CancelOrder show={cancelOrder} onHide={() => setCanelOrder(false)} />
        <AcceptBid show={acceptBid} onHide={() => setAcceptBid(false)} />

        <CancelBid show={cancelBid} onHide={() => setCancelBid(false)} />
      </> */}

        <Footer />
        <div
          className="scrl_top_btn d-flex align-items-center justify-content-center position-fixed"
          onClick={() => {
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          }}
        >
          <i class="fa-solid fa-chevron-up"></i>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
