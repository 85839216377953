import React, { useState, useEffect, useRef } from "react";
import authorBg from "../../assests/img/authorBg.svg";
import Profile from "../../assests/img/monica.png";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Nftcard from "../common-components/nftcard";
import ProfileInfo from "./ProfileInfo";
import { Link, useParams } from "react-router-dom";
import {
  Rewardsfun,
  Token_MyList_Func,
  userRegister,
} from "../actions/axios/user.axios";
import { useSelector } from "react-redux";
import { isEmpty } from "../actions/common";
import config from "../config/config";
import { toast } from "react-toastify";
import CollectionCards from "../common-components/CollectionCards";
import { Stackfun } from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";
import RewardCards from "../common-components/RewardCards";
import lightGifBg from '../../assests/gif/light.gif'



const Author = () => {
  var { customurl } = useParams();
  const { payload, isAdmin } = useSelector((state) => state.LoginReducer.User);

  var LikeForwardRef = useRef();

  const [Tabname, SetTabname] = useState("owned");
  const [Follow, SetFollow] = useState("follow");
  const [Datas, SetDatas] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [Rewards, setRewards] = useState({ page: 0, list: [], loader: true });
  const [StakeRewards, setStakeRewards] = useState("");

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      if (Datas[Tabname] == undefined && Tabname !== "Rewards") {
        GetTabData();
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isEmpty(userProfile)) {
      if (Tabname == "Rewards") {
        if (Rewards?.list?.length == 0) {
          getrewards();

        }
      }
      // }
    }
  }, [Tabname]);

  const getrewards = async () => {
    if (payload) {
      var SendDATA = {
        Type: "getrewards",
        WalletAddress: payload?.WalletAddress,
      };
      var resp = await Rewardsfun(SendDATA);
      if (resp.success == "success") {
        setRewards({
          ...Rewards,
          ...{
            list: [...Rewards.list, ...(resp?.data ?? [])],
            loader:
              resp?.data?.length == 0 ||
                (Rewards?.list?.length ?? 0) + resp?.data?.length >= resp?.count
                ? false
                : true,
            page:
              resp?.data?.length > 0
                ? Rewards.page
                : Rewards.page > 0
                  ? Rewards.page - 1
                  : 0,
          },
        });
        // setRewards(resp?.data);
      }
    }
  };
  // const Getstakerewards = async () => {
  //   if (payload) {
  //     var SendDATA = {
  //       action: "getrewards",
  //       WalletAddress: payload?.WalletAddress,
  //       // NFTId:Tokens_Detail.NFTId,
  //     };
  //     var resp = await Stackfun(SendDATA);
  //     if (resp?.success == "success") {
  //       setStakeRewards(resp?.data?.[0]);
  //     }
  //   }
  // };

  useEffect(() => {
    getProfileDetails();
    // Getstakerewards();
  }, [customurl, payload?.WalletAddress]);

  const getProfileDetails = async () => {
    var SendDATA = {
      CustomUrl: customurl ? customurl : payload?.CustomUrl,
      Type: "getProfile",
      User: payload?.WalletAddress,
    };
    var profileInfo = await userRegister(SendDATA);
    if (profileInfo?.success == "success" && profileInfo?.data?.WalletAddress) {
      setUserProfile(profileInfo?.data);
      SetFollow(profileInfo?.follow);
    }
  };

  const GetTabData = async (tab) => {
    tab = tab ?? Tabname;
    SetTabname(tab);
    if (isEmpty(tab) && Datas?.[tab]?.page > 0) {
      return false;
    }
    if (Datas?.[tab]?.loader == false && tab != "liked") {
      return false;
    }
    let instdata = Datas?.[tab]
      ? Datas
      : { [tab]: { page: 0, list: [], loader: true } };
    var page = instdata?.[tab]?.page + 1;
    var SendDATA = {
      TabName: tab,
      limit: 12,
      CustomUrl: customurl ?? userProfile?.CustomUrl,
      NFTOwner: userProfile?.WalletAddress,
      page: page ? page : 1,
      filter: "LatestDrops",
      from: "myItem",
    };
    let Resp = await Token_MyList_Func(SendDATA);
    // console.log("RespResp", Resp);

    if (Resp?.success == "error") {
      // toast.error(Resp?.msg)
    } else {
      SetDatas({
        ...Datas,
        ...{
          [tab]: {
            ...Datas?.[tab],
            ...{
              list:
                tab == "liked"
                  ? [...(Resp?.data ?? [])]
                  : [...(Datas?.[tab]?.list ?? []), ...(Resp?.data ?? [])],
              loader:
                Resp?.data?.length == 0 ||
                  (Datas?.[tab]?.list?.length ?? 0) + (Resp?.data?.length ?? 0) >=
                  Resp?.count
                  ? false
                  : true,
              page: Resp?.data?.length > 0 ? page : page > 0 ? page - 1 : 0,
            },
          },
        },
      });
    }
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }



  return (
    <section className="author">
      <div className="author_section">
        <div className="author_bg author_bg_set position-relative">
          <img
            src={
              isEmpty(userProfile?.Cover)
                ? authorBg
                : `${config.IMG_URL}/user/${userProfile?.WalletAddress}/cover/${userProfile?.Cover}`
            }
            alt="author-bg"
            className="img-fluid"
          />
          {/* <div className="top_banner_animation">
            <img src={lightGifBg} className="lottie" />
          </div> */}
        </div>
        <div className="profile_section d-flex align-items-center justify-content-start gap-4 container py-5">
          <ProfileInfo
            userProfile={userProfile}
            rewards={Rewards}
            Stakerewards={StakeRewards}
          />
          <div className="follow_sec">
            {/* <p className="followers mb-0">
              {userProfile?.Follower?.length > 0
                ? userProfile?.Follower?.filter((val) => !isEmpty(val?.Address))
                    .length
                : 0}{" "}
              followers
            </p> */}

            {/* {payload?.WalletAddress != userProfile?.WalletAddress &&
              (userProfile?.Follower?.length > 0 &&
              userProfile?.Follower?.some(
                (val) => val?.Address == payload?.WalletAddress
              ) ? (
                <Button className="follow_btn primary_button">Un-Follow</Button>
              ) : (
                <Button className="follow_btn primary_button">Follow</Button>
              ))} */}
          </div>
        </div>
        <div className="tab_section container py-5">
          <Tab.Container id="left-tabs-example" defaultActiveKey="owned">
            <Row>
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="owned"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("owned")}
                    >
                      Owned
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="onsale"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("onsale")}
                    >
                      On sale
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="created"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("created")}
                    >
                      Created
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="liked"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("liked")}
                    >
                      Liked
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="approvedcollections"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("approvedcollections")}
                    >
                      Approved Collections
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="notapprovedcollections"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("notapprovedcollections")}
                    >
                      Collections Waiting for Approval
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="rewards"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => SetTabname("Rewards")}
                    >
                      Rewards
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="stakednft"
                      className="tab_nav_act nw_act_lnk"
                      onClick={() => GetTabData("stakednft")}
                    >
                      Stack Nfts
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="mt-4">
                  <Tab.Pane eventKey={Tabname}>
                    <div className="first_section row">
                      {Datas?.[Tabname]?.list?.length > 0 ? (
                        Datas?.[Tabname]?.list?.map((item, index) => {
                          return (
                            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                              {(Tabname !== "approvedcollections" && Tabname != "notapprovedcollections") ? (
                                <Nftcard
                                  from={
                                    Tabname == "stakednft"
                                      ? "stakednft"
                                      : "collectionsnft"
                                  }
                                  product={item}
                                  type="collectionsnft"
                                  index={index}
                                  value={item}
                                  LikeList={LikeList}
                                  LikedTokenList={LikedTokenList}
                                  setLikedTokenList={setLikedTokenList}
                                  LikeForwardRef={LikeForwardRef}
                                />
                              ) : (
                                <CollectionCards props={item} />
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </Tab.Pane>
                  {/* <Tab.Pane eventKey="second">
                    {" "}
                    <div className="first_section row">
                      {liveAuctionCardData.map((item, index) => {
                        return (
                          <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                            <Nftcard value={item} />
                          </div>
                        );
                      })}
                    </div>
                  </Tab.Pane> */}
                  {/* {console.log("sdasfsdfsdg",Rewards)} */}

                  {
                    <Tab.Pane eventKey="rewards">
                      {" "}
                      <div>
                        {Rewards?.list?.length !== 0 ? (
                          <Row>
                            {Rewards?.list?.map((data) => (
                              <Col xs={12} md={6} xl={4} className="mb-4">
                                <RewardCards data={data} profile={""} />
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          <NoData />
                        )}
                      </div>
                    </Tab.Pane>
                  }
                </Tab.Content>
              </Col>
              {Datas?.[Tabname]?.loader && (
                <div className="d-flex align-items-center justify-content-center">
                  <Button
                    className="green_btn hover_btn mt-3 mx-auto"
                    onClick={() => GetTabData()}
                  >
                    Load More
                  </Button>
                </div>
              )}
            </Row>
          </Tab.Container>
        </div>
      </div>
    </section>
  );
};

export default Author;
