import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import saleImg from "../../assests/img/nft.png";
import { Col, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import useContractProviderHook from "../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  address_showing,
  isEmpty,
  Name_showing,
  NumANdDotOnly,
  NumberChange,
  NumberOnly,
  toFixed,
} from "../actions/common";
import { BidApprove } from "../actions/axios/nft.axios";
import config from "../config/config";
import Select from "react-select";

function PlaceBid({
  show,
  handleClose,
  OpenPopup,
  bidder,
  bid,
  owner,
  item,
  owners,
  RefreshData,
}) {
  // console.log("dataofowners" ,bidder  )
  const currTheme = useSelector((state) => state.themeReducer.theme);

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      padding: "0 10px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "16px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "45px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        marginTop: "-10px",
        color: valueContainerText,
      };
    },
  };

  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, accountAddress, coinBalance, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { buyerFees } = useSelector((state) => state.LoginReducer.ServiceFees);
  const ContractCall = useContractProviderHook();
  const push = useNavigate();
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState({});
  const [TokenQuantity, SetTokenQuantity] = useState("1");
  const [TokenBal, SetTokenBal] = useState(0);
  // const [found, setfound] = useState({});
  console.log('currentNetworkcurrentNetwork', currentNetwork)

  const [selectedOption, setSelectedOption] = useState(null);
  const [FormValue, SetFormValue] = useState({
    TokenBidderAddress: accountAddress,
    // TokenOwner_Name   : owner.ProfileUrl,
    Category: item?.Category,
    NFTQuantity: isEmpty(bidder) ? 1 : bidder.NFTQuantity,
    TokenBidAmt: isEmpty(bidder) ? 0 : bidder.TokenBidAmt,
    NFTId: item?.NFTId,
    ContractAddress: item?.ContractAddress,
    ContractType: item?.ContractType,
    CollectionNetwork: item?.CollectionNetwork,
    CoinName:
      isEmpty(owner?.CoinName) || owner?.PutOnSaleType != "TimedAuction"
        ? isEmpty(bidder)
          ? currency?.filter((item) => item.label !== currentNetwork.COIN_NAME)
            ?.length > 0
            ? currency.filter((item) => item.label !== currentNetwork.COIN_NAME)[0]
              .label
            : "Cake"
          : bidder.CoinName
        : owner?.CoinName,
  });
  const [once, setOnce] = useState(true);

  useEffect(() => {
    // const fd = owners?.find(
    //   (element) =>
    //     element.NFTOwner.toLowerCase() == accountAddress.toLowerCase()
    // );
    // setfound(fd);
    BalCal(FormValue.CoinName);
  }, [FormValue.CoinName]);

  const BalCal = async (data) => {
    let TokenBal = await ContractCall.Token_Balance_Calculation(
      Token_details.token_address,
      accountAddress
    );
    SetTokenBal(TokenBal);
  };

  const Token_details = useMemo(() => {
    var data =
      currency?.filter((item) => item.label === FormValue.CoinName)?.pop() ??
      currency?.filter((item) => item.label !== currentNetwork?.COIN_NAME)?.pop();

    return {
      decimal: data?.decimal ?? 18,
      token_address: data?.address ?? config.DEADADDRESS,
    };
  }, [FormValue.CoinName]);

  const YouWillGet = useMemo(() => {
    if (FormValue?.TokenBidAmt) {
      if (TokenBal) {

        if (Number(FormValue?.TokenBidAmt) < Number(TokenBal)) {
          return ContractCall.buy_bid_price_calculation(
            (FormValue.TokenBidAmt * FormValue.NFTQuantity).toString(),
            Token_details.decimal.toString()
          );
        } else {
          var err = Error;
          Error.TokenBidAmt = "Insuffecient Balance";
          SetError(err);
          toast.error("Insuffecient Balance");
        }
      }
    }
  }, [FormValue.TokenBidAmt, FormValue.NFTQuantity, currentNetwork]);

  const Validation = async () => {
    var Error = {};
    if (isEmpty(FormValue.TokenBidAmt))
      Error.TokenBidAmt = "Must Enter Bid Amount";
    if (isEmpty(FormValue.NFTQuantity))
      Error.NFTQuantity = "Enter Valid Quantity";
    if (Number(FormValue.NFTQuantity) <= 0 || Number(FormValue.NFTQuantity) < 0)
      Error.NFTQuantity = "Enter Valid Quantity";
    if (!(Number(TokenBal) > 0)) Error.TokenBal = "insufficient balance";
    if (!(Number(TokenBal) > FormValue?.TokenBidAmt))
      Error.TokenBidAmt = "Insufficient balance";

    if (owner.NFTPrice && owner.PutOnSaleType !== "FixedPrice") {
      if (!(owner.NFTPrice <= FormValue.TokenBidAmt)) {
        Error.Minbid = `Enter a bid above the minimum ${owner.NFTPrice} `;
      }
    }
    if (Number(FormValue.TokenBidAmt) <= 0 || Number(FormValue.TokenBidAmt) < 0)
      Error.TokenBidAmt = "Bid Amount Must be above  Zero ";
    else if (FormValue.ContractType == "1155") {
      if (
        Number(owner.NFTBalance) <
        Number(FormValue.NFTQuantity)
      ) {
        Error.NFTQuantity = "Token Quantity Must be less than token Available";
      }
    }
    if (Number(FormValue.TokenBidAmt) <= 0 || Number(FormValue.TokenBidAmt) < 0)
      Error.TokenBidAmt = "Bid Amount Must be above  Zero ";
    else if (FormValue.ContractType == "1155") {
      if (
        Number(owner.NFTBalance) <
        Number(FormValue.NFTQuantity)
      ) {
        Error.NFTQuantity = "Token Quantity Must be less than token Available";
      }
    } else if (FormValue.ContractType == "721") {
      if (Number(owner.NFTBalance) < Number(FormValue.NFTQuantity)) {
        Error.NFTQuantity = "Token Quantity Must be less than token Available";
      }
    } else if (Number(FormValue.NFTQuantity) % 1 !== 0)
      Error.NFTQuantity = "Token Quantity Must be a Valid Count";
    if (ContractCall.Contract_Base_Validation())
      Error.Wal = await ContractCall.Contract_Base_Validation();
    if (FormValue?.CoinName == bid?.CoinName) {
      let bigdata =
        owner.NFTPrice > bid.TokenBidAmt ? owner.NFTPrice : bid.TokenBidAmt;
      if (!isEmpty(bid)) {
        if (FormValue.TokenBidAmt <= bid.TokenBidAmt)
          Error.TokenBidAmt = "Must Be greater Than " + bid.TokenBidAmt;
      } else if (owner?.PutOnSaleType === "TimedAuction") {
        if (Number(FormValue.TokenBidAmt) < bigdata)
          Error.TokenBidAmt = "Minimum Bid is " + owner.NFTPrice;
      }
    }

    if (TokenBal === 0)
      Error.TokenBal = `Not Enough Token in Your Wallet, your TokenBalance:${TokenBal}`;
    return Error;
  };

  const FormSubmit = async () => {
    const id = toast.loading("Approving Token on processing");
    SetError({});
    SetBtn("process");
    var error = await Validation();
    if (!isEmpty(error)) {
      setTimeout(() => {
        toast.update(id, {
          render: Object.values(error)[0],
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
      }, 1000);
      SetBtn("error");
      SetError(error);
    } else {
      let allow = web3?.utils?.fromWei(
        (await ContractCall.allowance_721_1155(
          Token_details.token_address,
          accountAddress
        ))
          ? String(
            await ContractCall.allowance_721_1155(
              Token_details.token_address,
              accountAddress
            )
          )
          : "0"
      );
      let chainid = NumberChange(item?.ChainId)
      let checkchain = await ContractCall.SwitchNetwork(chainid, id);
      if (checkchain) {
        // if(checkchain==true){

        // }
      }
      else {
        return false;
      }
      var all = toFixed(allow);
      let cont = await ContractCall.approve_721_1155(
        Token_details.token_address,
        currentNetwork.TradeContract,
        web3?.utils?.toWei(String(Number(YouWillGet) + Number(allow)))
      );
      if (cont) {
        var _data = FormValue;
        _data.HashValue = cont.HashValue;
        _data.from = isEmpty(bidder) ? "Bid" : "Edit";
        _data.activity = isEmpty(bidder) ? "Bid" : "Edit";
        _data.EmailId = payload.EmailId;
        _data.click = `${config?.FRONT_URL}/product/${item.CollectionNetwork}/${item.ContractAddress}/${owner.NFTOwner}/${owner.NFTId}`;

        let Resp = await BidApprove(_data);
        if (Resp.success == "success") {
          setTimeout(() => {
            toast.update(id, {
              render: "Make offer for token Successfully",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              closeButton: true,
              closeOnClick: true,
            });
            SetBtn("done");
            RefreshData();
            handleClose();
          }, 1000);

          setTimeout(() => {
            // window.location.reload();
          }, 1500);
          // push(`/author/${payload?.CustomUrl}`, { state: { Tab: "owned" } });
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
  };

  const onChange = async (e, data) => {
    let changedata = data === "price" ? e : e.target;
    SetBtn("start");
    const { value, id, name } = changedata;

    let val = data === "price" ? "CoinName" : id;

    SetFormValue({
      ...FormValue,
      ...{
        [val]:
          data === "inp"
            ? name == "NumDotOnly"
              ? NumANdDotOnly(value)
              : NumberOnly(value)
            : value,
      },
    });

    if (data === "price") {
      BalCal(value);
    }
  };

  useEffect(() => {
    async function BalanceCheck() {
      var Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("NftbalanceNftbalance", Nftbalance);

      if (Number(Nftbalance) !== Number(owner?.NFTBalance)) {
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          // push("/");
          handleClose()
        }, 1000);
      }
    }
    BalanceCheck();
  }, [item, owner]);

  // async function BalanceCheck() {
  //   // SetMintbtn("process");
  //   if (owner.HashValue !== "not yet minted") {
  //     if (once) {
  //       // let id = toast.loading("Checking balance....")
  //       let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);

  //       if ((Number(Nftbalance) !== Number(owner?.NFTBalance) && item?.ContractType === '1155') || (Nftbalance.toLowerCase() != owner?.NFTOwner.toLowerCase() && item?.ContractType === '721')) {

  //         toast.warning("You won't buy at this moment please refresh you data");
  //         setTimeout(() => {
  //           push("/");
  //         }, 1000);
  //       }

  //     }
  //   }
  //   // SetMintbtn("start");
  //   return () => { setOnce(false) }

  // }

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={handleClose}
      backdrop="static"

    >
      <Modal.Header closeButton className="px-sm-5 mx-a uto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          {isEmpty(bidder) ? "Place a Bid" : "Edit a Bid"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4">
        <div className="d-flex flex-column align-items-center">
          <Row className="w-100 ">
            <Col xs={10} className="mx-auto mb-3">
              <p className="gray_txt heading text-center">
                You are about to place a bid for{" "}
                <span className="modal_hightlights"> {item.NFTName} </span>
                from
                <span className="modal_hightlights">
                  {" "}
                  {owner?.DisplayName
                    ? Name_showing(owner.DisplayName)
                    : address_showing(owner.WalletAddress)}
                </span>
              </p>
            </Col>
            <Col xs={12} className="mb-3">
              <input
                type="text"
                placeholder="Enter new Price"
                className="custom_input modal_input w-100"
                id="TokenBidAmt"
                name="NumDotOnly"
                value={FormValue.TokenBidAmt}
                onChange={(e) => onChange(e, "inp")}
              />
            </Col>
            <Col xs={12}>
              <div>
                <p className="text-left">Select Coin</p>
                <Select
                  isSearchable={false}
                  styles={stylesgraybg}
                  value={{
                    label: FormValue.CoinName,
                    value: FormValue.CoinName,
                  }}
                  defaultValue={{
                    label: FormValue.CoinName,
                    value: FormValue.CoinName,
                  }}
                  className="select_custom w-100"
                  onChange={(e) => {
                    onChange(e, "price");
                  }}
                  options={currency?.filter(
                    (item) => item.label != currentNetwork?.COIN_NAME
                  )}
                  id="CoinName"
                  classNamePrefix="react-select-one"
                  isDisabled={owner?.PutOnSaleType == "TimedAuction"}
                />
              </div>
            </Col>
            <Col xs={12} className="my-3">
              <input
                type="text"
                id="NFTQuantity"
                value={FormValue.NFTQuantity}
                onChange={(e) => onChange(e, "inp")}
                name="NumOnly"
                className="custom_input modal_input w-100"
                disabled={
                  owner.NFTBalance == "1" || owner.NFTBalance == 1
                    ? true
                    : false
                }
                placeholder="Enter your bid quantity"
              />
              <p className="text-left">
                Enter Quantity{" "}
                <span>
                  {/* {console.log("asddddddddd",owner,)} */}
                  (
                  {item.ContractType == "1155"
                    ? owner.NFTBalance
                    : "1"}{" "}
                  available)
                </span>
              </p>
            </Col>
            <Col xs={6}>
              <p className=" heading">Your Balance</p>
            </Col>
            <Col xs={6}>
              <p className=" heading">
                {Number(web3?.utils?.fromWei(coinBalance))?.toFixed(4)} {"  "}
                {item?.CollectionNetwork}
              </p>
            </Col>

            <Col xs={6}>
              <p className=" heading">Your Biding Balance</p>
            </Col>
            <Col xs={6}>
              <p className=" heading">
                {Number(TokenBal).toFixed(4)} {FormValue?.CoinName}
              </p>
            </Col>
            <Col xs={6}>
              <p className=" heading">Service fee</p>
            </Col>
            <Col xs={6}>
              <p className=" heading">
                {Number(web3.utils.fromWei(String(buyerFees))).toFixed(4)}%{" "}
                {FormValue.CoinName}
              </p>
            </Col>

            <Col xs={6}>
              <p className=" heading">You will Pay</p>
            </Col>
            <Col xs={6}>
              <p className=" heading">
                {isEmpty(YouWillGet) ? 0 : Number(YouWillGet).toFixed(2)}{" "}
                {FormValue.CoinName}
              </p>
            </Col>
          </Row>
          <Button
            className="green_btn hover_btn modal_btn mt-4 "
            disabled={
              Btn == "error" || Btn === "process" || Btn === "done"
                ? true
                : false
            }
            onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
          >
            {(Btn == "start" &&
              (isEmpty(bidder) ? "Place a bid" : "Edit Bid")) ||
              (Btn == "try" && "Try-Again") ||
              (Btn == "error" && "Error") ||
              (Btn == "done" && "Done") ||
              (Btn == "process" && "In-Progress")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PlaceBid;
