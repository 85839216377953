import React from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import ActivityContent from "../other-components/ActivityContent";
import Innertitle from "../common-components/innertitle";

const Activity = () => {
  return (
    <section className="activity ">
      <Innertitle title="Activity" subtitle="" />
      <Container className="custom_container">
        <div className="activity_section py-4 py-lg-5">
          <ActivityContent />
        </div>
      </Container>
    </section>
  );
};

export default Activity;
