import React, { useEffect, useState } from "react";
import HomeSwiper from "../other-components/HomeSwiper";
import {
  Col,
  Container,
  Row,
  Tab,
  Nav,
  Accordion,
  Button,
  Dropdown,
} from "react-bootstrap";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import BlogCards from "../common-components/BlogCards";
import Nftcard from "../common-components/nftcard";
import CollectionCards from "../common-components/CollectionCards";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Keyboard } from "swiper/modules";
import TopSeller from "../common-components/TopSeller";

// import collectionCardOne from "../../assests/svg/hotcollectionone.svg";
import collectionCardOne from "../../assests/img/background.jpg";
import collectionCardTwo from "../../assests/img/bgTwo.jpg";
import collectionCardThree from "../../assests/img/bgThree.jpg";
import collectionCardFour from "../../assests/svg/hotcollectionfour.svg";
import profile from "../../assests/svg/profile.svg";
import location from "../../assests/svg/location.svg";
import location2 from "../../assests/svg/location2.svg";
import phone from "../../assests/svg/phone.svg";
import phone2 from "../../assests/svg/phone2.svg";
import mail from "../../assests/svg/mail.svg";
import download from "../../assests/svg/download.svg";
import FilterCards from "./FilterCards";
import { useSelector } from "react-redux";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import {
  CollectionsList,
  Token_List_Func,
  TopCreatorApi,
  homeSaledata,
} from "../actions/axios/nft.axios";
import NoData from "../common-components/nodata";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import { userQuestions } from "../actions/axios/user.axios";
import config from "../config/config";
import {
  GetAddress,
  GetArticle,
  GetCmsContent,
} from "../actions/axios/cms.axios";
import Loader from "../common-components/Loader";

import bannerarrow from "../../assests/img/bannerarrow.png";
import bannerImgOne from "../../assests/svg/homeBanner.svg";
import newsimg from "../../assests/img/blog.png";
import blog from "../../assests/img/blog.png";
import collectone from "../../assests/img/collectone.png"
import HotCollections from "../other-components/HotCollections";

import inclinedOne from '../../assests/img/inclinedone.png'
import inclinedTwo from '../../assests/img/inclinedtwo.png'
import inclinedThree from '../../assests/img/inclinedthree.png'
import inclinedFour from '../../assests/img/inclinedfour.png'
import blurCircleOne from '../../assests/img/blurcircle.png'
import blurCircleTwo from '../../assests/img/blurcircletwo.png'
import mailImg from '../../assests/img/mail.svg'
import mailImgDark from '../../assests/img/mailDark.svg'
import homebannerbg from '../../assests/img/homebannerbg.png'

import downloadImg from '../../assests/img/download.svg'
import downloadImgDark from '../../assests/img/downloadDark.svg'


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Component } from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const optionsOne = [
  { value: 7, label: "Last 7 Days" },
  { value: 10, label: "Last 10 Days" },
  { value: 30, label: "Last 1 Month" },
];

const Home = () => {
  const [Topcreatorstate, setTopcreatorstate] = useState(true);
  const [TopCreator, SetTopCreator] = useState([]);
  const [hotsalestate, SetHotsalestate] = useState(true);
  const [buttontxt, setbutton] = useState("Start");
  const [Error, setError] = useState({});




  const { web3p, accountAddress } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const getUrlPath = useLocation();
  console.log("geturlpath", getUrlPath.pathname);

  const [Collectionsdata, Setcollectionsdata] = useState([]);
  const [Promotedcollectionsdata, Setpromotedcollectionsdata] = useState([]);

  const [TabName, SetTabName] = useState("All");
  const [collectionfilter, Setcollectionfilter] = useState(7);
  const [loadingScreen, setLoadingScreen] = useState(true);

  const handleLoadingScreen = () => {
    setLoadingScreen(false);
  };

  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [filter, setFilter] = useState({
    auction: "Recent",
    collection: "new",
    sale: "LatestDrops",
    old: "OLD",
  });
  const initdata = {
    name: "",
    email: "",
    phoneno: "",
    message: "",
    action: "add",
  };
  const [form, setform] = useState(initdata);

  var [changeTheme, SetChangeTheme] = useState(false);

  const outsider = document.getElementById("outsider");
  const distance = 130;
  const scrollingNavsRight = () => {
    // outsider.scrollBy(100, 0);
  };

  // theme from redux state
  const currTheme = useSelector((state) => state.themeReducer.theme);

  console.log("currThemecurrTheme", currTheme);

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerTextInvert = currTheme === "light" ? "#ffffff" : "#18152D";

  useEffect(() => {
    valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
    valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
    valueContainerTextInvert = currTheme === "light" ? "#ffffff" : "#18152D";
    SetChangeTheme(!changeTheme);
  }, [currTheme]);

  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // width: "180px",
      width: "159px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  const stylesgraybg2 = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 0px",
      // width: "180px",
      width: "125px",
      backgroundColor: valueContainerTextInvert,
      color: "red",
      border: "none",
      borderRadius: 5,
      fontSize: "18px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  var init = {
    All: { loader: true, page: 1, list: [] },
  };
  const [Tokens, SetTokens] = useState(init);
  const [status, setStatus] = useState(true);
  const [blogcard, setblogcard] = useState([]);
  const [CMSdata, Setcmsdata] = useState([]);
  const [Address, SetAddress] = useState([]);

  const [loadlimit, setloadlimit] = useState(20);

  // useEffect(()=>{
  //         Getaddress()

  // },[])

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, [getUrlPath.pathname]);

  useEffect(() => {
    PromotedCollection();
    TopCreatorFunc();
    Getarticles();
    Getcms();
    Getaddressfun();
    setLoadingScreen(false);
  }, [collectionfilter]);

  const TopCreatorFunc = async () => {
    var resp = await TopCreatorApi();
    console.log("resttpresp", resp);
    SetTopCreator(resp?.data);
    setTopcreatorstate(false);
  };

  // console.log("TopCreatorTopCreator",TopCreator);

  const Getcms = async (data) => {
    var resp = await GetCmsContent();
    if (resp.success) {
      Setcmsdata(resp?.msg);
    }
  };
  const Getaddressfun = async (data) => {
    var resp = await GetAddress();
    if (resp.success) {
      SetAddress(resp?.msg);
    }
  };

  const PromotedCollection = async (page, tab) => {
    var senddata = {
      from: "collections",
      TabName: "All",
      limit: 2,
      page: 1,
      promoted: true,
    };
    let Resp = await CollectionsList(senddata);
    if (Resp?.data?.success) {
      Setpromotedcollectionsdata(Resp?.data?.data);
    }
  };
  const Validation = () => {
    let error = {};
    var data = Object.entries(form).map((it) => {
      if (isEmpty(it?.[1])) {
        return (error = {
          ...error,
          ...{ [it?.[0]]: `${it?.[0]} is required` },
        });
      } else if (it?.[0] == "email") {
        if (!config?.EMAIL?.test(form?.email)) {
          return (error = {
            ...error,
            ...{ [it?.[0]]: ` Enter Valid Email id` },
          });
        }
      } else if (it?.[0] == "phoneno") {
        if (!config?.MOBILE?.test(form?.phoneno)) {
          return (error = {
            ...error,
            ...{ [it?.[0]]: `Enter Valid Phone number` },
          });
        }
      }
    });
    if (!isEmpty(error)) {
      setbutton("Try Again");
      setError(error);
      return true;
    } else return false;
  };

  const inputonchang = async (e) => {
    if (e.target) {
      const { id, value } = e.target;
      setError({});

      setform({
        ...form,
        ...{ [id]: value },
      });
    }
  };
  const Getarticles = async () => {
    // var resp = await GetArticle({ limit: 3 });
    var resp = await GetArticle();

    if (resp?.success) {
      setLatestNews(resp?.data)
      setblogcard(resp?.data);
    }
  };

  const Formsubmit = async () => {
    const id = toast.loading("Validating Form");

    var Error = await Validation(form);
    if (!Error) {
      let Resp = await userQuestions(form);

      if (Resp.success == "success") {
        toast.update(id, {
          render:
            Resp.success == "success" ? "Updated Successfully" : "Try Again",
          type: Resp.success,
          isLoading: false,
          autoClose: 700,
        });
        setform(init);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.update(id, {
          render: "Try Again",
          type: "error",
          isLoading: false,
          autoClose: 700,
        });
      }
    } else {
      toast.update(id, {
        render: "Check fields",
        type: "error",
        isLoading: false,
        autoClose: 700,
      });
    }
  };

  // useEffect(() => {
  //   if (typeof Collectionsdata[TabName] == "undefined") {
  //     Collectionsdata[TabName] = { page: 1, list: [], loader: false };
  //     Setcollectionsdata(Collectionsdata);
  //     CollectionByCreate(1, TabName);
  //   }
  // }, [TabName]);

  const CollectionByCreate = async (page, tab, Filter, type) => {
    let senddata = {
      from: "collections",
      TabName: tab ? tab : TabName,
      limit: 6,
      page: page ?? 1,
      filter: Filter ? Filter : filter,
      project: type == "ART" ? false : type == "PROJECT" ? true : "All",
    };
    let Resp = await CollectionsList(senddata);
    // console.log("RespRespResp", Resp?.data?.data);
    if (Resp?.data?.data) {
      // setLoader(false);
      Setcollectionsdata({
        ...Collectionsdata,
        ...{
          [senddata.TabName]: {
            list: Collectionsdata[senddata.TabName]?.list
              ? [
                ...(Collectionsdata[senddata.TabName]?.list
                  ? Collectionsdata[senddata.TabName]?.list
                  : []),
                ...Resp?.data?.data,
              ]
              : Resp?.data?.data,
            page: Collectionsdata[senddata.TabName]?.page,
            loader: Resp.data.data.length == 0 ? false : true,
          },
        },
      });
    }
  };
  // console.log("CollectionsdataCollectionsdata", Collectionsdata);


  const [news] = useState([
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
    {
      date: "October 28, 2020",
      nimg: newsimg,
      nhead: "How to create music NFT",
      ndesc: "Dolore officia sint incididunt non excepteur ea mollit commodo ut enim reprehenderit cupidatat labore ad laborum consectetur consequat..."
    },
  ]);

  const [hotdrop, setHotdrop] = useState("30 days");

  const [hotcollect] = useState([
    {
      cimg: collectone,
      cname: "Abstraction",
      ctwo: "ERC-192"
    },
    {
      cimg: blog,
      cname: "Patternlicious",
      ctwo: "ERC-61"
    },
    {
      cimg: collectone,
      cname: "Skecthify",
      ctwo: "ERC-126"
    },
    {
      cimg: blog,
      cname: "Cartoonism",
      ctwo: "ERC-73"
    },
    {
      cimg: collectone,
      cname: "Abstraction",
      ctwo: "ERC-192"
    },
    {
      cimg: blog,
      cname: "Patternlicious",
      ctwo: "ERC-61"
    },
    {
      cimg: collectone,
      cname: "Skecthify",
      ctwo: "ERC-126"
    },
    {
      cimg: blog,
      cname: "Cartoonism",
      ctwo: "ERC-73"
    },
  ]);


  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500
  };

  const [latestNews, setLatestNews] = useState([])

  const [latestClick, setLatestClick] = useState('right')

  console.log('latestnewslatestnews', latestNews)
  const handleSlideLeft = () => {
    const splitedLastNews = latestNews.splice(latestNews.length - 1, 1)
    setLatestNews([splitedLastNews[0], ...latestNews])
    setLatestClick((prev) => prev == 'right' ? "left" : "right")
  }

  const handleSlideRight = () => {
    const splitedFirstNews = latestNews.splice(0, 1)
    setLatestNews([...latestNews, splitedFirstNews[0]])
    setLatestClick((prev) => prev == 'right' ? "left" : "right")
  }

  return (
    <>
      {loadingScreen ? (
        <Loader show={loadingScreen} handleClose={handleLoadingScreen} />
      ) : (
        <section className="blurbox position-relative">
          <img src={homebannerbg} className="banner_cover_image" />
          <div className="banner_wrapper position-relative">

            <img src={blurCircleOne} className="blur_circle_one" />
            <img src={blurCircleTwo} className="blur_circle_two" />
            <Container className="custom_container">
              {" "}
              {/* start of banner section */}
              <div className="home_banner_sec position-relative ">


                {/* <HomeSwiper value={Promotedcollectionsdata} content={CMSdata} /> */}
                <div className="bannercenter">
                  <p className="bannerheader_p">Free No</p>
                  <h1 className="bannerhead">
                    Code Marketplace for <br /> your Collection
                  </h1>
                  <p className="bannercontent">
                    A Customizable, on-brand marketplace for your community to trade all of your collections.
                  </p>
                  <Link to={"/create"} className="primary_button">Create marketplace</Link>
                  <div className="banner_inclined_images">
                    <div className="banner_inclined_images_wrap one">
                      <img src={inclinedOne} />
                    </div>
                    <div className="banner_inclined_images_wrap two">
                      <img src={inclinedTwo} />
                    </div>
                    <div className="banner_inclined_images_wrap one">
                      <img src={inclinedThree} />
                    </div>
                    <div className="banner_inclined_images_wrap two">
                      <img src={inclinedFour} />
                    </div>

                  </div>
                </div>
              </div>
              {/* end of banner section */}
              {/*  start of Latest News */}
              {/* <>
              <div className="latest_news pt-5">
                <h5 className="heading_bold">Latest News</h5>
                <Row className="mt-4 mt-xl-5">
                  {blogcard?.slice(loadlimit - 20, loadlimit)?.length !== 0 ? (
                    blogcard?.slice(loadlimit - 20, loadlimit).map((data) => (
                      <Col xs={12} sm={6} lg={4}>
                        <BlogCards data={data} />
                      </Col>
                    ))
                  ) : (
                    <>
                      {" "}
                      <NoData />
                    </>
                  )}
                </Row>
              </div>
            </> */}
            </Container>
          </div>
          {/* end of latest news */}

          {/* start of marketplace */}

          {/* <Container className="custom_container">
            <div className="pt-3 pt-xl-5">
              <h5 className="heading_bold">Lastest News</h5>
            </div>
          </Container> */}



          <FilterCards
            Collectiondata={Collectionsdata}
            from={"home"}
            Tabname={TabName}
            CollectionByCreate={CollectionByCreate}
            Setcollectionsdata={Setcollectionsdata}
          />
          {/* <div className="d-flex align-items-center justify-content-center">
            <Link
              className="green_btn hover_btn mt-3 mx-auto"
              to="/collections/All"
            >
              Explore
            </Link>
          </div> */}
          {/* end of marketplace */}


          {/* <Container className="custom_container">
            <div className="d-flex align-items-center justify-content-center gap-3">
              <h5 className="heading_bold mb-0">Hot Collections</h5>
              <Dropdown>
                <Dropdown.Toggle>{hotdrop}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setHotdrop("10 days")}>10 days</Dropdown.Item>
                  <Dropdown.Item onClick={() => setHotdrop("7 days")}>7 days</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="hotcollections">
              <HotCollections hotcollect={hotcollect} />
            </div>
          </Container> */}


          {/* start of top sellers */}

          <div className="top_seller_container position-relative">
            <img src={blurCircleTwo} className='ball_gradient_right' />

            <Container className="custom_container">
              <h5 className="heading_bold">Top sellers</h5>
              <p className="desc_p">A non-fungible Token is a non-interchangeable unit of data stored on a blockchain, a
                form of digital ledger that  can be sold & traded.</p>
              {Topcreatorstate?.length == 0 ? (
                <>
                  <div className="text-center">
                    <p className="no_text_value">No Creators found</p>

                    <div className="load-more"></div>
                  </div>
                </>
              ) : (
                // <Row className="mt-5">
                //   {TopCreator?.map((value, index) => (
                //     <Col md={4} lg={2} xxl={2} className="mb-4">
                //       <TopSeller props={value} index={index + 1} />
                //     </Col>
                //   ))}
                // </Row>
                <div className="topsellerstop">
                  {TopCreator?.map((value, index) => (
                    <div className="topsellersbox">
                      <TopSeller props={value} index={index + 1} />
                    </div>
                  ))}
                </div>
              )}

            </Container>
          </div>
          {/* end of top sellers */}

          {/* start of latest news */}

          <div className="latest_news_container position-relative ">
            <img src={blurCircleOne} className='ball_gradient_left' />
            <Container className="custom_container position-relative">
              <button
                className="custom_slider_button left d-none d-sm-block"
                onClick={handleSlideLeft}
              >
                <FaArrowLeft />
              </button>
              <button
                className="custom_slider_button right d-none d-sm-block"
                onClick={handleSlideRight}
              >
                <FaArrowRight />
              </button>
              <h5 className="heading_bold">Latest News</h5>
              <p className="desc_p">A non-fungible Token is a non-interchangeable unit of data stored on a blockchain, a
                form of digital ledger that  can be sold & traded.</p>
              <div className="news">
                {/* <Row>

                  {blogcard?.slice(loadlimit - 20, loadlimit)?.length !== 0 ? (
                    blogcard?.slice(loadlimit - 20, loadlimit).map((data) => (
                      <Col xs={12} sm={6} xl={4} className="mb-4 mb-lg-0">
                        <BlogCards data={data} />
                      </Col>
                    ))
                  ) : (
                    <div className="nodata_text_p">
                      {" "}
                      <NoData />
                    </div>
                  )}
                </Row> */}
                <div className="row position-relative">
                  <button
                    className="custom_slider_button left d-sm-none"
                    onClick={handleSlideLeft}
                  >
                    <FaArrowLeft />
                  </button>
                  <button
                    className="custom_slider_button right d-sm-none"
                    onClick={handleSlideRight}
                  >
                    <FaArrowRight />
                  </button>
                  <div className="col-12 col-xl-7 mt-5 mt-sm-0">
                    <div
                      className={`custom_slider ${latestClick}`}
                    >
                      <div className={`custom_slider--left  `}>
                        <img src={`${config.IMG_URL}/${latestNews[2]?.img}`} alt={latestNews[2]?.heading} />
                      </div>
                      <div className={`custom_slider--center `}>
                        <img src={`${config.IMG_URL}/${latestNews[1]?.img}`} alt={latestNews[1]?.heading} />
                      </div>
                      <div className={`custom_slider--right`}>
                        <img src={`${config.IMG_URL}/${latestNews[0]?.img}`} alt={latestNews[0]?.heading} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-5 ps-xl-5 mt-5 mt-xl-0 d-flex flex-xl-column justify-content-center justify-content-xl-start">
                    <div className="latest_news_right">
                      <h5 className="latest_news_h5 ">{latestNews[0]?.heading}</h5>
                      <p className="latest_news_p mt-3">

                        <div
                          dangerouslySetInnerHTML={{
                            __html: latestNews[0]?.content,
                          }}
                        />

                      </p>
                      <div className="latest_news_button">
                        <Link
                          to={`/blogdetails/${latestNews[0]?.url}`}
                          state={{ item: latestNews[0] }}
                          className="primary_button"
                        >
                          Read more
                        </Link>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>


          {/* end of latest news */}


          {/* start of newsletter */}

          <Container className="custom_container">
            <div className="newsletter_card">

              <h5>Newsletter</h5>
              <p>Signup for our newsletter to get the latest news in your inbox</p>
              <div className="row w-100 justify-content-center">
                <div className="col-12 col-sm-11 col-lg-8 col-xl-7 col-xxl-5 mt-2 mt-xxl-3 px-0">
                  <div className="newsletter_action">
                    <input placeholder="Enter your email here" />
                    <button>Subscribe Now</button>
                  </div>
                </div>
              </div>

            </div>
          </Container>

          {/* end of newsletter */}



          {/* start of forms */}

          <div className="form_container position-relative">
            <img src={blurCircleOne} className='ball_gradient_left' />
            <img src={blurCircleTwo} className='ball_gradient_right' />


            <Container className="custom_container ">
              <div className="submit_form ">
                <Row className=" submit_form_one_top">
                  <Col xs={12} md={12} lg={5}
                    className="submit_form_one cust-col-xxxl-4" >
                    {Address?.map((data, index) => (
                      <div
                        className={"card_dtls mb-4"}
                      >
                        <div className="card">
                          <h5
                            className={`heading ${index == 0 ? "" : "card_txt"
                              }`}
                          >
                            {data.country} Office
                          </h5>
                          <div className="row mt-4">
                            <div className="col-12 col-sm-6">
                              <div className="address_imgvalue">
                                {
                                  currTheme === "light" ? (
                                    <img src={location2} alt="location" />
                                  ) : (
                                    <img src={location} alt="location" />
                                  )
                                }

                                <p
                                  className={`content mb-0 ${index == 0 ? "" : "card_txt"
                                    }`}
                                >
                                  {data.address}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                              <div className="address_imgvalue">
                                {
                                  currTheme === "light" ? (
                                    <img src={mailImg} alt="location" />
                                  ) : (
                                    <img src={mailImgDark} alt="location" />
                                  )
                                }



                                <p className="content text_green">
                                  {data?.link}{" "}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="mt-4 row">
                            <div className="col-12 col-sm-6">
                              <div className="address_imgvalue">
                                {
                                  currTheme === "light" ? (
                                    <img src={phone} alt="phone" />
                                  ) : (
                                    <img src={phone2} alt="phone" />
                                  )
                                }
                                { }

                                <p
                                  className={`content mb-0 ${index == 0 ? "" : "card_txt"
                                    }`}
                                >
                                  {data?.phonenumber}
                                </p>
                              </div>
                            </div>
                            <div className="col-12 col-sm-6 mt-3 mt-sm-0">
                              <div className="address_imgvalue">
                                {
                                  currTheme === "light" ? (
                                    <img src={downloadImg} alt="download" className="maildownimg" />
                                  ) : (
                                    <img src={downloadImgDark} alt="download" className="maildownimg" />
                                  )
                                }

                                <p className="content text_green">
                                  Download Brochure
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Col>
                  <Col xs={12} md={12} lg={7}
                    className="submit_form_one cust-col-xxxl-8 ">
                    <div className="bgbox">
                      <h5 className="heading_futura mb-4">Do you have any question?</h5>
                      <Form className="mt-3">
                        <Form.Control
                          id="name"
                          type="text"
                          placeholder="Name"
                          className="custom_input "
                          onChange={(e) => {
                            inputonchang(e);
                          }}
                        />
                        {Error?.name && (
                          <span className="text-danger img-file">
                            {Error?.name}
                          </span>
                        )}
                        <Form.Control
                          id="email"
                          type="name"
                          placeholder="Email"
                          className="custom_input"
                          onChange={(e) => {
                            inputonchang(e);
                          }}
                        />
                        {Error?.email && (
                          <span className="text-danger img-file">
                            {Error?.email}
                          </span>
                        )}
                        <Form.Control
                          id="phoneno"
                          type="number"
                          placeholder="Phone"
                          className="custom_input"
                          onChange={(e) => {
                            inputonchang(e);
                          }}
                        />
                        {Error?.phoneno && (
                          <span className="text-danger img-file">
                            {Error?.phoneno}
                          </span>
                        )}
                        <Form.Control
                          id="message"
                          as="textarea"
                          placeholder="Message"
                          className="custom_input"
                          onChange={(e) => {
                            inputonchang(e);
                          }}
                        />
                        {Error?.message && (
                          <span className="text-danger img-file">
                            {Error?.message}
                          </span>
                        )}
                        <Button
                          className="green_btn hover_btn card_btn mt-4"
                          onClick={() => {
                            Formsubmit();
                          }}
                        >
                          Submit Form
                        </Button>
                      </Form>
                    </div>
                  </Col>


                </Row>
              </div >
            </Container >
          </div>


          {/* end of forms */}
        </section >
      )}
    </>
  );
};

export default Home;
