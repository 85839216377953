import React from "react";
import lightGifBg from '../../assests/gif/light.gif'

export default function Innertitle({ title, subtitle }) {
  return (
    <div className="innertitlesec position-relative">
      {/* <div className="top_banner_animation">
        <img src={lightGifBg} className="lottie" />
      </div> */}
      <div className="container custom_container">
        <div className="row secrow">
          <div className="col-md-8">
            <p className="mainhead new_mn_head">{title}</p>
            {subtitle ? (
              <p className="sub_head new_sb_hd">{subtitle}</p>
            ) : (
              <></>
            )}
            {/* <p className='mini_head'>Single Collection</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
