import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tab, Nav, Card, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Datetime from "react-datetime";
// import {REACT_APP_ADMINADDRESS} from "@env"

import Nftcard from "../common-components/nftcard";
import Thumbcard from "../common-components/Thumbcard";

import Innertitle from "../common-components/innertitle";
import fixedicon from "../../assests/img/fixed.svg";
import fixedIconDark from "../../assests/svg/fixedicondark.svg";
import timedicon from "../../assests/img/timed.svg";
import timedIconDark from "../../assests/svg/timedauctiondark.svg";
import openbidicon from "../../assests/img/openbid.svg";
import openBidDark from "../../assests/svg/openforbidsdark.svg";
import lock from "../../assests/svg/lock.svg";
import { useSelector } from "react-redux";
import {
  DateTimeForm,
  getDaysOfDesiredMonth,
  isEmpty,
  NumberChange,
} from "../actions/common";
import { toast } from "react-toastify";
import config from "../config/config";
import dateImg from "../../assests/svg/datechooselight.svg";
import datewhite from "../../assests/svg/datechoose.svg";
import FollowSteps from "../modals/FollowSteps";
import saleImg from "../../assests/img/nft.png";
import moment from "moment";
// import { createCanvas, loadImage } from 'canvas'

import Web3 from "web3";
import {
  nftNameValidation,
  NFTImageUpload,
  CreateNFT,
  CollectionsList,
  Stackfun,
  Cerificateupload,
} from "../actions/axios/nft.axios";
import useContractProviderHook from "../actions/contractProviderHook";
import { ImgValidation } from "../actions/validations";
import ImgAudVideo from "../common-components/ImagAudVideo";
import AddCollection from "../modals/AddCollection";
import Calender from "../modals/Calendar";
import CreateSingleModal from "../modals/CreateSingleModal";

import Certificate from "../other-components/Certificate";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
import network from "../config/network";
import { ValidChainCheck } from "../hooks/useWallet";
import { GetAdmin } from "../actions/axios/user.axios";

export default function Createsingle() {
  const { web3p, accountAddress, web3, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );

  // const Adminaddress = process.env.REACT_APP_ADMINADDRESS.toLocaleLowerCase();
  var [Adminaddress, SetAdminAddress] = useState('');

  const { currency, Categorys } = useSelector((state) => state.LoginReducer);
  const push = useNavigate();

  console.log('currencycurrency', currency)

  const initialTokenValue = {
    NFTName: "",
    NFTQuantity: 1,
    NFTOrginalImage: "",
    NFTOrginalImagePreview: "",
    NFTThumpImage: "",
    NFTLogoImage: "",
    NFTThumpImagePreview: "",
    NFTOrginalImageIpfs: "",
    NFTThumpImageIpfs: "",
    CompressedFile: "",
    CompressedThumbFile: "",
    NFTDescription: "",
    PutOnSaleType: "UnlimitedAuction",
    PutOnSale: false,
    NFTPrice: "",
    NFTMinimumBid: "",
    ClockTime: "",
    EndClockTime: "",
    NFTRoyalty: "",
    NFTProperties: [],
    NFTCreator: accountAddress,
    NFTOwner: accountAddress,
    HashValue: "",
    MetFile: "",
    MetaData: "",
    ContractAddress: (currentNetwork?.ERC721 ? currentNetwork?.ERC721 : config.ERC721),
    ContractType: 721,
    Category: Categorys.length > 0 && Categorys[0].label,
    CoinName: "",
    UnlockContent: "",
    CollectionName: "",
    CollectionNetwork: currentNetwork?.COIN_NAME,
    CollectionSymbol: "",
    Collectionid: "",
    isMessageapprove: "",
    isPricenotification: "",
    isPromotion: "",
    islegalalert: "",
    Burnable: true,
    NFTPictureRoyalty: "",
    Project: false,
    IsStaked: false,
    StakedNFTQuantity: "",
    CollectionDocuments: "",
    Stakingpool: {},
    // SoulBoundIpfs:"",
    CeritificateMetaData: "",
    Certificateid: "",
    CertificateQuantity: "",
    mintingdate: DateTimeForm(new Date(), true),
    ChainId: (currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
  };

  const certificateRef = useRef(null);

  const [NFTFormValue, setNFTFormValue] = useState(initialTokenValue);
  const [ValidateError, SetValidateError] = useState({});
  const [closebar, setclosebar] = useState("show");

  const [FormButton, SetFormButton] = useState("start");
  const [ApproveButton, SetApproveButton] = useState("start");
  const [UploadButton, SetUploadButton] = useState("stop");
  const [MintButton, setMintButton] = useState("stop");
  const [show8, setShow8] = useState(false);
  const [Unlock, setUnlock] = useState(false);

  const [location, SetLocation] = useState("");
  const [OpenPopup, SetOpenPopup] = useState("");
  const [CreateCollectionState, SetCreateCollectionState] = useState([]);

  const [number, setNumber] = useState([0]);
  const [key, setKey] = useState({});
  const [Value, setValue] = useState({});
  const [showCreateItem, setShowCreateItem] = useState(false);
  const [collection, setCollection] = React.useState(1);
  const [showCalender, setCalenderShow] = useState(false);
  const [season, Setseason] = useState();

  const [showCreateSingle, setShowCreateSingle] = useState(true);

  const handleClose = () => {
    setShowCreateSingle(false);
  };

  const currTheme = useSelector((state) => state.themeReducer.theme);

  const { payload } = useSelector((state) => state.LoginReducer.User);

  const ContractCall = useContractProviderHook();

  var navigate = useNavigate();
  const startingdate = [
    { value: "List Immediately", label: "List Immediately" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const enddate = [
    { value: "1 day", label: "1 day" },
    { value: "2 days", label: "2 days" },
    { value: "Scheduled Listing", label: "Scheduled Listing" },
  ];
  const [options, setOptions] = useState([]);
  // useEffect(() => {
  //   setNFTFormValue({
  //     ...NFTFormValue,
  //     ...{ NFTCreator: accountAddress, NFTOwner: accountAddress },
  //   });
  // }, [accountAddress]);

  console.log('locationlocation', location, NFTFormValue?.Project)

  useEffect(() => {
    GetAdminAddress()
  }, [])

  const GetAdminAddress = async () => {
    let resp = await GetAdmin();
    console.log('GetAdminresppp', resp);
    if (!isEmpty(resp?.data?.AdminAddress)) {
      SetAdminAddress(String(resp?.data?.AdminAddress)?.toLowerCase())
    }
  }

  useEffect(() => {
    console.log('useffecteeefindddd')
    console.log('checkbiggggwronggg')
    let path = collection == 1 ? "Single" : "Multiple";
    SetLocation(path);
    let type = collection == 1 ? 721 : 1155;
    let Addr = collection == 1 ? (currentNetwork?.ERC721 ? currentNetwork?.ERC721 : config.ERC721) : (currentNetwork?.ERC1155 ? currentNetwork?.ERC1155 : config.ERC1155);
    setNFTFormValue({
      ...NFTFormValue,
      ...{
        ["ContractAddress"]: Addr,
        ["ContractType"]: type,
        ["CollectionSymbol"]: "",
        ["CollectionName"]: "",
        ["CollectionDocuments"]: "",
        ["Collectionid"]: "",
      },
    });
    if (accountAddress) {
      CollectionByCreate({
        Type: collection == 1 ? 721 : 1155,
        Creator: accountAddress,
        Project: NFTFormValue?.Project,
        ChainId: String(currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
      });
    }
  }, [collection, location, NFTFormValue?.Project]);

  useEffect(() => {
    console.log('useffecteeefindddd11111')
    SetData();
  }, [accountAddress, currentNetwork]);

  console.log("useffecteeefinddddNFTFormValueNFTFormValue", NFTFormValue, currentNetwork?.CHAIN_ID);

  const SetData = async () => {
    let insetval = NFTFormValue
    let type = collection == 1 ? 721 : 1155;
    let Addr = collection == 1 ? (currentNetwork?.ERC721 ? currentNetwork?.ERC721 : config.ERC721) : (currentNetwork?.ERC1155 ? currentNetwork?.ERC1155 : config.ERC1155);
    let currchain = await web3?.eth?.getChainId();
    console.log('checkbigggg', NFTFormValue?.ChainId, currchain, accountAddress, NFTFormValue?.NFTCreator, ValidChainCheck(NFTFormValue?.ChainId, currchain), accountAddress == NFTFormValue?.NFTCreator, !isEmpty(currchain), !isEmpty(accountAddress), accountAddress == NFTFormValue?.NFTCreator && !isEmpty(accountAddress && String(NFTFormValue?.ChainId) == String(currchain) && !isEmpty(currchain)),)
    if (accountAddress == NFTFormValue?.NFTCreator && !isEmpty(accountAddress) && String(NFTFormValue?.ChainId) == String(currchain) && !isEmpty(currchain)) {
      insetval = {
        ...insetval,
        ...{
          "ChainId": (currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
          ["NFTCreator"]: accountAddress,
          ["NFTOwner"]: accountAddress,
          CollectionNetwork: currentNetwork?.COIN_NAME ? currentNetwork?.COIN_NAME : config.COIN_NAME,
        },
      };
    }
    else {
      insetval = {
        ...insetval,
        ...{
          ["ContractAddress"]: Addr,
          ["ContractType"]: type,
          "ChainId": (currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
          ["NFTCreator"]: accountAddress,
          ["NFTOwner"]: accountAddress,
          CollectionNetwork: currentNetwork?.COIN_NAME ? currentNetwork?.COIN_NAME : config.COIN_NAME,

          ["CollectionSymbol"]: "",
          ["CollectionName"]: "",
          ["CollectionDocuments"]: "",
          ["Collectionid"]: "",
          CoinName: "",
        },
      };
    }
    console.log('useffectinsetval', insetval)
    setNFTFormValue(insetval);
    SetValidateError({});
    SetFormButton("start");
    if (accountAddress) {
      CollectionByCreate({
        Type: collection == 1 ? 721 : 1155,
        Creator: accountAddress,
        Project: NFTFormValue?.Project,
        ChainId: String(currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
      });
    }
  }

  useEffect(() => {
    console.log('useffectrt222222')
    if (NFTFormValue.PutOnSaleType === "FixedPrice")
      setNFTFormValue({
        ...NFTFormValue,
        ...{ ["CoinName"]: currency[0]?.label },
      });
    else if (NFTFormValue.PutOnSaleType === "TimedAuction")
      setNFTFormValue({
        ...NFTFormValue,
        ...{
          ["CoinName"]: currency.filter(
            (item) => !item.address.includes("0x0000000")
          )[0]?.label,
        },
      });
    else setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: "" } });
    SetValidateError({});
    SetFormButton("start");
  }, [NFTFormValue.PutOnSaleType])


  // useEffect(() => {

  //   CurrecyChange();
  // }, [currency, NFTFormValue.PutOnSaleType]);

  // const CurrecyChange = () => {
  //   SetValidateError({});
  //   SetFormButton("start");

  //   if (NFTFormValue.PutOnSaleType === "FixedPrice")
  //     setNFTFormValue({
  //       ...NFTFormValue,
  //       ...{ ["CoinName"]: currency[0]?.label },
  //     });
  //   else if (NFTFormValue.PutOnSaleType === "TimedAuction")
  //     setNFTFormValue({
  //       ...NFTFormValue,
  //       ...{
  //         ["CoinName"]: currency.filter(
  //           (item) => !item.address.includes("0x0000000")
  //         )[0]?.label,
  //       },
  //     });
  //   else setNFTFormValue({ ...NFTFormValue, ...{ ["CoinName"]: "" } });
  // };


  useEffect(() => {
    if (web3) {
      Stakeoptions();
    }
  }, [web3]);

  // useEffect(() => {

  //   if (accountAddress) {
  //     if (isEmpty(NFTFormValue.NFTCreator) || isEmpty(NFTFormValue.NFTOwner)) {
  //       setNFTFormValue({
  //         ...NFTFormValue,
  //         ...{ ["NFTCreator"]: accountAddress, ["NFTOwner"]: accountAddress },
  //       });
  //     }
  //   }
  // }, [accountAddress]);

  useEffect(() => {
    setCollection(NFTFormValue?.Project == true ? 2 : 1);
  }, [accountAddress, NFTFormValue.Project]);

  const setClockValue = (data, date) => {
    setNFTFormValue({
      ...NFTFormValue,
      ...{ [data]: new Date(date) },
    });
  };
  var Properties = (e) => {
    var { value, id } = e.target;
    SetFormButton("start");
    SetValidateError({});
    if (id.includes("key")) {
      setKey({ ...key, ...{ [number.length - 1]: value } });
    } else if (id.includes("value")) {
      setValue({ ...Value, ...{ [number.length - 1]: value } });
    }
  };

  var AddProperties = () => {
    var error = {};
    if (isEmpty(key[number.length - 1])) {
      error.key = "Enter Key";
    }
    if (isEmpty(Value[number.length - 1])) {
      error.value = "Enter Value";
    }
    if (number.length <= 10) {
      if (isEmpty(error)) {
        document.getElementById("key").value = "";
        document.getElementById("value").value = "";
        setNumber([...number, number.length]);
      } else {
        SetValidateError(error);
      }
    }
  };


  var RemoveProperties = (ind) => {
    var check = delete Value[ind];
    delete key[ind];
    delete Value[ind];

    var obj = {};
    var obj2 = {};
    for (var i = 0; i < number.length - 1; i++) {
      if (i >= ind) {
        if (Value[i + 1]) obj[i] = Value[i + 1];
        if (key[i + 1]) obj2[i] = key[i + 1];
      } else {
        obj2[i] = key[i];
        obj[i] = Value[i];
      }
    }
    setValue(obj);
    setKey(obj2);
    document.getElementById("key").value = "";
    document.getElementById("value").value = "";
    setNumber(
      number.filter((val, ind) => {
        return ind !== number.length - 1;
      })
    );
  };

  var validEnd = function (current) {
    return current.isAfter(
      NFTFormValue.ClockTime ? new Date(NFTFormValue.ClockTime) : undefined
    );
  };
  var validStart = function (current) {
    var yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  const CollectionByCreate = async (data) => {
    data.from = "addcollection";
    data.limit = 100;
    data.page = 1;
    if (!data?.ChainId) {
      data.ChainId = String(currentNetwork?.CHAIN_ID)
    }
    let Resp = await CollectionsList(data);
    console.log("ResapResp", data, Resp);
    var arr = [];
    Resp?.data?.data.map((data, index) => {
      arr.push({
        ...data,
        name: "userCollection",
        value: data.value,
        label: data.label,
      });
    });
    arr.push({
      name: "userCollection",
      value: "button",
      label: (
        <button
          className="custom_add_collec_btn green_btn hover_btn green_btn_sm_mb custom_add_collection_btn"
          onClick={() => {
            handleShowAddCollection();
          }}
        >
          Add Collection
        </button>
      ),
    });

    SetCreateCollectionState(arr);
  };
  const Stakeoptions = async () => {
    var Poolstatus = await ContractCall.getStackPools();
    console.log('PoolstatusPoolstatus', Poolstatus)
    var Data = []
    Poolstatus?.data?.map((value, index) => {
      console.log('valuevalue', value)
      if (value?._enablestack) {
        value["value"] = `${((NumberChange(value.lockPeriod) / 365)).toFixed(((NumberChange(value.lockPeriod) / 365) % 1) == 0 ? 0 : 1)} years`;
        value["label"] = `${((NumberChange(value.lockPeriod) / 365)).toFixed(((NumberChange(value.lockPeriod) / 365) % 1) == 0 ? 0 : 1)} years`;
        value["startDate"] = getDaysOfDesiredMonth(index + 1).startDate;
        value["endDateFormat"] = new Date(
          new Date().setDate(new Date().getDate() + Number(value?.lockPeriod))
        );
        Data.push(value)
      }
    });
    console.log("Data_Data", Data);
    setOptions(Data);
  };


  // input field onchange function
  const inputonchange = (e, acceptedfile, type) => {
    if (accountAddress) {
      SetFormButton("start");
      SetValidateError({});
      if (e && e.target) {
        const { files, value, id, name } = e.target;
        if (id == "NFTRoyalty" || id == "NFTPrice" || id == "NFTMinimumBid" || id == 'NFTPictureRoyalty') {
          const checkprice = /^\d*\.?\d*$/;
          if (checkprice.test(value))
            setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
        } else {
          setNFTFormValue({ ...NFTFormValue, ...{ [id]: value } });
        }
      }
      if (acceptedfile) {
        var file = acceptedfile[0];
        if (type == "Orginal") {
          var validExtensions = [
            "png",
            "gif",
            "webp",
            "mp4",
            "jpg",
            "jpeg",
            "mp3",
            "aac",
            "flac",
            "webm",
            "ogv",
          ];
        }
        if (type == "Thump" || type == "Logo") {
          var validExtensions = ["png", "webp", "jpg", "jpeg"];
        }
        var fileName = file?.name;
        var fileNameExt = fileName?.substr(fileName.lastIndexOf(".") + 1);

        if (!validExtensions.some((val) => fileNameExt === val)) {
          toast.error(
            "Only these file types are accepted : " + validExtensions.join(", ")
          );
          return false;
        } else {
          if (type == "Orginal") {
            if (ImgValidation(acceptedfile[0], "pro")) {
              toast.error(ImgValidation(acceptedfile[0], "pro"));
            } else {
              setNFTFormValue({
                ...NFTFormValue,
                ...{ ["NFTOrginalImage"]: acceptedfile[0] },
              });
            }
          }

          if (type == "Thump") {
            if (ImgValidation(acceptedfile[0], "thumb")) {
              toast.error(ImgValidation(acceptedfile[0], "thumb"));
            } else {
              setNFTFormValue({
                ...NFTFormValue,
                ...{ ["NFTThumpImage"]: acceptedfile[0] },
              });
            }
          }

          if (type == "Logo") {
            if (ImgValidation(acceptedfile[0], "logo")) {
              toast.error(ImgValidation(acceptedfile[0], "logo"));
            } else {
              setNFTFormValue({
                ...NFTFormValue,
                ...{ ["NFTLogoImage"]: acceptedfile[0] },
              });
            }
          }
        }
      }
    } else {
      toast.error("Connect Wallet To create NFT");
    }
  };

  //NFT Initial Approve
  console.log('NFTFormValueNFTFormValue111innnnnnnnn', NFTFormValue, UploadButton)
  const TokenApproveCall = async () => {
    SetApproveButton("process");
    const id = toast.loading("Approve in process");
    let checkchain = await ContractCall.SwitchNetwork(NFTFormValue?.ChainId, id);
    console.log('checkkkkkk', checkchain)
    if (checkchain) {
    }
    else {
      SetApproveButton("try");
      return false;
    }

    const cont = await ContractCall.SetApproveStatus(
      location,
      NFTFormValue.ContractAddress
    );
    console.log('TokenApproveCallcontcont', cont, location, NFTFormValue.ContractAddress)
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    if (cont.status) {
      SetApproveButton("done");
      SetUploadButton("start");
    } else SetApproveButton("try");
  };
  //Upload image in IPFS
  async function UploadIPFScall() {
    setclosebar("hide");
    const {
      NFTCreator,
      NFTThumpImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
      NFTProperties,
      NFTLogoImage,
    } = NFTFormValue;
    SetUploadButton("process");
    const id = toast.loading("Uploading  File");
    let Resp = await NFTImageUpload({
      NFTCreator: NFTCreator ? NFTCreator : accountAddress,
      NFTThumpImage,
      NFTLogoImage,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
      NFTProperties: JSON.stringify(NFTProperties),
    });
    if (Resp?.success == "success") {
      var certificate = await CertificateUploadIPFScall();
      console.log("certificatecertificate", certificate);

      setNFTFormValue({
        ...NFTFormValue,
        ...Resp.data,
        ...(certificate ? certificate : {}),
      });
      SetUploadButton("done");
      setMintButton("start");
    } else {
      SetUploadButton("try");
    }
    toast.update(id, {
      render: Resp?.msg,
      type: Resp?.success,
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
  }

  //Certificate upload

  async function CertificateUploadIPFScall() {
    // setclosebar("hide");
    const {
      NFTCreator,
      NFTOrginalImage,
      NFTName,
      NFTDescription,
      NFTProperties,
      NFTLogoImage,
      NFTQuantity,
    } = NFTFormValue;

    let download = await DownloadCertificate();

    let Resp = await Cerificateupload({
      NFTCreator: NFTCreator ? NFTCreator : accountAddress,
      NFTLogoImage,
      NFTOrginalImage: download,
      NFTName,
      NFTDescription,
      NFTQuantity,
      NFTProperties: JSON.stringify(NFTProperties),
    });
    if (Resp.success == "success") {
      return Resp?.data;
    } else {
      SetUploadButton("try");
    }
  }

  const DownloadCertificate = async () => {
    try {
      if (certificateRef.current) {
        var canvas = await html2canvas(certificateRef.current);
        console.log("canvascanvascanvas", canvas);
        if (canvas) {
          const dataURL = await canvas.toDataURL("image/png");
          var fileDataCon = await dataURLtoFile(dataURL, "name.png");
          console.log("fileDataCon", fileDataCon);
          return fileDataCon;
        }
      }
    } catch (err) {
      console.log("sdfssd", err);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  //NFT Mint Function
  async function MintCall() {
    const id = toast.loading("Minting Processing");
    setMintButton("process");
    var _data = NFTFormValue;
    _data.activity = "Mint";
    _data.EmailId = payload?.EmailId;
    _data.NFTPrice == 0
      ? (_data.NFTPrice = "")
      : (_data.NFTPrice = _data.NFTPrice);
    let ENc = window.btoa(JSON.stringify(_data));

    if (NFTFormValue.PutOnSaleType === "FixedPrice") {
      let checkchain = await ContractCall.SwitchNetwork(NFTFormValue?.ChainId, id);
      if (checkchain) {
        if (checkchain == true) {
          let curaddr = await checkchain?.web3?.eth?.getAccounts()
          let checkaddr = await web3?.eth?.getAccounts()
          console.log('curaddrcuraddr', curaddr, checkaddr)
        }
      }
      else {
        setMintButton("try");
        return false;
      }
      var sign = await ContractCall._signcall(
        NFTFormValue?.NFTPrice,
        NFTFormValue?.NFTCreator
      );
      if (!sign) {
        toast.update(id, { render: "Try-Again", type: "error", isLoading: false, autoClose: 1000 })
        setMintButton("try");
        return false;
      }
    }
    if (NFTFormValue?.CeritificateMetaData) {
      let checkchain = await ContractCall.SwitchNetwork(NFTFormValue?.ChainId, id);
      console.log('checkchaincheckchain', checkchain)
      if (checkchain) {
        if (checkchain != true) {
          handleCloseCreateItem();
          toast.update(id, { render: "New Account Changed Fill the Form and Try Again." })
        }
      }
      else {
        // toast.update(id, { render: "Switch Network", type: "error", autoClose: 1000, isLoading: false, })
        setMintButton("try");
        return false;
      }
      console.log('innnnnnnnn', NFTFormValue)
      const cont = await ContractCall.minting_721_1155(
        NFTFormValue.ContractType,
        [
          config.IPFS + NFTFormValue.MetaData,
          config.IPFS + NFTFormValue.CeritificateMetaData,
        ],
        [
          NFTFormValue.NFTQuantity,
          NFTFormValue.ContractType,
          Web3?.utils?.toWei(NFTFormValue.NFTRoyalty),
          Web3?.utils?.toWei(
            (NFTFormValue.PutOnSaleType == "FixedPrice"
              ? NFTFormValue.NFTPrice
              : "0"
            ).toString()
          ),
          // 0,
        ],

        ENc,
        NFTFormValue?.ContractAddress
      );

      if (cont) {
        if (NFTFormValue.PutOnSaleType === "FixedPrice") {
          _data.ClockTime = new Date(NFTFormValue.ClockTime);
          _data.EndClockTime = new Date(NFTFormValue.EndClockTime);
          _data.Password = sign.password ?? "";
          _data.Signhash = sign?.signhash ?? "";
          _data.nonce = sign?.tot ?? "";
        }

        _data.HashValue = cont.HashValue;
        _data.NFTId = cont?.tokenCounts?.toString();
        _data.Certificateid = cont?.Certificateid?.toString();
        _data.click = `${config.FRONT_URL}/Product/${NFTFormValue.CollectionNetwork
          }/${NFTFormValue.ContractAddress
          }/${accountAddress}/${cont?.tokenCounts?.toString()}`;

        _data.Stakingpool = NFTFormValue?.Stakingpool;
        _data.CertificateQuantity = NFTFormValue.NFTQuantity;
        // _data.StakedNFTQuantity = NFTFormValue.NFTQuantity;

        let Resp = await CreateNFT(_data);

        toast.update(id, {
          render: Resp?.msg,
          type: Resp.success,
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        if (Resp.success == "success") {
          setMintButton("done");
          toast.update(id, {
            render: "Token Minted successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          // navigate('/createsingle')
          // navigate(`/product/${payload?.CustomUrl}`);
          navigate(
            `/product/${NFTFormValue?.CollectionNetwork}/${NFTFormValue?.ContractAddress}/${NFTFormValue.NFTOwner}/${NFTFormValue.NFTId} `
          );
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          setMintButton("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        setMintButton("try");
      }
    }
  }
  //NFT mint validation function
  const Validation = async (data) => {
    let ValidateError = {};
    const {
      NFTName,
      NFTOrginalImage,
      NFTThumpImage,
      NFTPrice,
      EndClockTime,
      ClockTime,
      NFTRoyalty,
      Category,
      PutOnSaleType,
      PutOnSale,
      CoinName,
      NFTQuantity,
      ContractType,
      NFTMinimumBid,
      CollectionSymbol,
      UnlockContent,
      Categorys,
      NFTPictureRoyalty,
      NFTLogoImage,
      Project,
    } = data;
    // console.log(
    //   "sfgh",
    //   NFTOrginalImage,
    //   NFTOrginalImage.type?.includes("video")
    // );
    if (!NFTRoyalty) ValidateError.NFTRoyalty = "Royalty Required";
    else if (isEmpty(NFTRoyalty))
      ValidateError.NFTRoyalty = "Royalty Must Be Greater Than 0";
    else if (isNaN(NFTRoyalty) === true)
      ValidateError.NFTRoyalty = "Royalty must be a number";
    else if (Number(NFTRoyalty) < 0)
      ValidateError.NFTRoyalty = "Royalty must be Greater than 0";
    else if (Number(NFTRoyalty) > 20)
      ValidateError.NFTRoyalty = "Royalty Must be less than 20";
    else if (Number(NFTRoyalty) % 1 !== 0)
      ValidateError.NFTRoyalty = "Royalty must be a Whole Number";

    if (accountAddress == Adminaddress && NFTFormValue.Project) {
      if (!NFTPictureRoyalty)
        ValidateError.NFTPictureRoyalty = "PictueRoyalty Required";
      else if (isEmpty(NFTPictureRoyalty))
        ValidateError.NFTPictureRoyalty =
          "PictueRoyalty Must Be Greater Than 0";
      else if (isNaN(NFTPictureRoyalty) === true)
        ValidateError.NFTPictureRoyalty = "PictueRoyalty must be a number";
      else if (Number(NFTPictureRoyalty) < 0)
        ValidateError.NFTPictureRoyalty =
          "PictueRoyalty must be Greater than 0";
      else if (Number(NFTPictureRoyalty) > 20)
        ValidateError.NFTPictureRoyalty = "PictueRoyalty Must be less than 20";
      else if (Number(NFTPictureRoyalty) % 1 !== 0)
        ValidateError.NFTPictureRoyalty =
          "PictueRoyalty must be a Whole Number";
    }

    if (!NFTName) ValidateError.NFTName = "Token Name Required";

    if (PutOnSaleType == "FixedPrice" && isEmpty(NFTPrice))
      ValidateError.NFTPrice = "NFTPrice Required";
    if (
      (PutOnSaleType == "FixedPrice" || PutOnSaleType == "TimedAuction") &&
      !CoinName &&
      PutOnSale == true
    )
      ValidateError.CoinName = "CoinName Required";

    if ((PutOnSaleType == "TimedAuction" || PutOnSaleType == "FixedPrice") && !ClockTime)
      ValidateError.ClockTime = "ClockTime Required";
    if ((PutOnSaleType == "TimedAuction" || PutOnSaleType == "FixedPrice") && !EndClockTime)
      ValidateError.EndClockTime = "EndClockTime Required";

    if (PutOnSaleType == "TimedAuction" || PutOnSaleType == "FixedPrice") {
      if (
        EndClockTime && (ClockTime > EndClockTime ||
          ClockTime.toString() == EndClockTime.toString())
      ) {
        ValidateError.EndClockTime =
          "Start date should be lesser than End date.";
      }
    }

    if (!Category) ValidateError.Category = "Category Required";
    if (
      !CollectionSymbol &&
      CreateCollectionState.length !== 0 &&
      PutOnSaleType !== "TimedAuction"
    )
      ValidateError.CollectionSymbol = "Please Choose a Collections";

    if (
      !CollectionSymbol &&
      CreateCollectionState.length == 0 &&
      PutOnSaleType !== "TimedAuction"
    )
      ValidateError.CollectionSymbol = "Please Add a Collections";

    if (!NFTOrginalImage)
      ValidateError.NFTOrginalImage = "OriginalFile Required";
    else {
      if (ImgValidation(NFTOrginalImage, "pro"))
        ValidateError.NFTOrginalImage = ImgValidation(NFTOrginalImage, "pro");
      if (
        (NFTOrginalImage?.type?.includes("video") ||
          NFTOrginalImage?.type?.includes("audio")) &&
        !NFTThumpImage
      )
        ValidateError.NFTThumpImage = "ThumbFile Required";
      else if (NFTThumpImage) {
        if (ImgValidation(NFTThumpImage, "thumb"))
          ValidateError.NFTThumpImage = ImgValidation(NFTThumpImage, "thumb");
      } else if (NFTThumpImage) {
        if (ImgValidation(NFTThumpImage, "thumb"))
          ValidateError.NFTThumpImage = ImgValidation(NFTThumpImage, "thumb");
      }
    }
    if (!NFTLogoImage) {
      ValidateError.NFTLogoImage = "Logoimage Required";
    } else {
      if (ImgValidation(NFTLogoImage, "pro"))
        ValidateError.NFTLogoImage = ImgValidation(NFTLogoImage, "pro");
    }

    // if(isEmpty(Categorys))ValidateError.Categorys = "Categorys Field is Required"

    if (PutOnSaleType == "FixedPrice") {
      if (isEmpty(NFTPrice))
        ValidateError.NFTPrice = "NFT Price Field is Required";
      if (isNaN(NFTPrice) === true)
        ValidateError.NFTPrice = "NFT Price Should Be a Number";
      else if (Number(NFTPrice) <= 0 && PutOnSale == true)
        ValidateError.NFTPrice = "NFTPrice should be above Zero";

      if (ContractType === 1155 || ContractType === "1155") {
        if (Number(NFTQuantity) % 1 !== 0) {
          ValidateError.NFTQuantity = '"Quantity" must be a Valid number';
        }
      }
    }
    if (Object.values(key)?.length > 0 || Object.values(Value)?.length > 0) {
      for (var i = 0; i < number.length; i++) {
        // console.log('isEmptyyyy',isEmpty(Value[i]))
        if (isEmpty(key[i])) {
          if (isEmpty(Value[i])) {
          } else {
            ValidateError["key"] = "Enter Key";
          }
        }
        if (isEmpty(Value[i])) {
          if (isEmpty(key[i])) {
          } else {
            ValidateError["value"] = "Enter Value";
          }
        }
      }
    }
    if (PutOnSaleType == "TimedAuction") {
      if (!NFTMinimumBid)
        ValidateError.NFTMinimumBid = "Minimum Bid Field Is Required";
    }

    if (!UnlockContent && Unlock)
      ValidateError.UnlockContent = "UnlockContent Required";

    if (!season && Project) ValidateError.season = "Staking Pool Required";

    return ValidateError;
  };
  const FormSubmit = async () => {
    SetValidateError({});
    const id = toast.loading("Validating Form");
    var Error = await Validation(NFTFormValue);
    if (isEmpty(Error)) {
      //for model

      var checkarr = [];
      if (Object.values(key)?.length > 0) {
        for (var i = 0; i < number.length; i++) {
          if (!isEmpty(key[i]) && !isEmpty(Value[i])) {
            NFTFormValue.NFTProperties.push({ [key[i]]: Value[i] });
            checkarr.push({ [key[i]]: Value[i] });
          }
        }
      }
      SetFormButton("process");
      let Resp = await nftNameValidation({
        NFTName: NFTFormValue.NFTName,
      });
      if (Resp?.success == "success") {
        toast.update(id, {
          render: Resp.success == "success" ? "Ready To Mint" : "Check Fields",
          type: Resp.success,
          isLoading: false,
          autoClose: 700,
        });
        let checkchain = await ContractCall.SwitchNetwork(NFTFormValue?.ChainId);
        console.log('checkchaincheckchain', checkchain)
        if (checkchain) {
          // if(checkchain==true){

          // }
        }
        else {
          // toast.update(id,{render:"Switch Network",type:"error",autoClose:1000,isLoading:false,})
          SetFormButton("start")
          return false;
        }
        let Respc = await ContractCall.Contract_Base_Validation();
        if (!Respc) {
          let Statu = await ContractCall.GetApproveStatus(
            location,
            NFTFormValue.ContractAddress
          );

          console.log("StatuStatuStatu", Statu);
          if (Statu == true) {
            SetApproveButton("stop");
            SetUploadButton("start");
            toast.update(id, {
              render: "Start Minting",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              closeButton: true,
              closeOnClick: true,
            });
          } else {
            SetApproveButton("start");
            toast.update(id, {
              render: "Get Approve",
              type: "success",
              isLoading: false,
              autoClose: 1000,
              closeButton: true,
              closeOnClick: true,
            });
          }
          setMintButton("stop");
          Modelfuctions();
          setShow8(true);
        } else {
          SetFormButton("error");
          SetValidateError(Respc);
        }
      } else {
        toast.update(id, {
          render: "NFT Name Exist",
          type: "error",
          isLoading: false,
          autoClose: 500,
          closeButton: true,
          closeOnClick: true,
        });
        SetFormButton("error");
        SetValidateError({ NFTName: Resp.msg });
      }
    } else {
      SetFormButton("error");
      SetValidateError(Error);
      toast.update(id, {
        render: "Form Validation failed Check Fields",
        type: "error",
        isLoading: false,
        autoClose: 500,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  const DateSelection = (e, data) => {
    SetValidateError({});
    SetFormButton("start");

    if (data == "start") {
      if (e.value == "List Immediately")
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["ClockTime"]: new Date(),
          },
        });
      else if (e.value == "Scheduled Listing") {
        setCalenderShow(true);
        SetOpenPopup("ClockTime");
      }
    } else {
      if (e.value == "1 day") {
        if (NFTFormValue.ClockTime === "") {
          setNFTFormValue({
            ...NFTFormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date().setDate(new Date().getDate() + 1)
              ),
            },
          });
        } else {
          setNFTFormValue({
            ...NFTFormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date(NFTFormValue.ClockTime).setDate(
                  new Date(NFTFormValue.ClockTime).getDate() + 1
                )
              ),
            },
          });
        }
      } else if (e.value == "2 days") {
        if (NFTFormValue.ClockTime === "") {
          setNFTFormValue({
            ...NFTFormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date().setDate(new Date().getDate() + 2)
              ),
            },
          });
        } else {
          setNFTFormValue({
            ...NFTFormValue,
            ...{
              ["EndClockTime"]: new Date(
                new Date(NFTFormValue.ClockTime).setDate(
                  new Date(NFTFormValue.ClockTime).getDate() + 2
                )
              ),
            },
          });
        }
      } else if (e.value == "Scheduled Listing") {
        setCalenderShow(true);
        SetOpenPopup("EndClockTime");
      }
    }
  };
  const ChooseCollection = (e) => {
    if (isEmpty(payload?.EmailId) && NFTFormValue?.Project && accountAddress !== Adminaddress) {
      toast.warning("Fill your emailId to get your project update")
      return push(`/editprofile`)
    }
    SetValidateError({});
    SetFormButton("start");
    if (e.value !== "button") {
      if (NFTFormValue?.CollectionSymbol !== e.Collectionurl) {
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["CollectionSymbol"]: e.Collectionurl,
            ["Category"]: e.Category,
            ["ContractAddress"]: e.CollectionContractAddress
              ? e.CollectionContractAddress
              : NFTFormValue?.ContractAddress,
            ["CollectionName"]: e.CollectionName,
            ["Project"]: e.Project,
            ["CollectionDocuments"]: e.CollectionDocuments,
            ["Collectionid"]: e._id,
          },
        });
      } else {
        setNFTFormValue({
          ...NFTFormValue,
          ...{
            ["CollectionSymbol"]: "",
            ["Category"]: Categorys.length > 0 && Categorys[0].label,
            ["CollectionName"]: e.CollectionName,
            ["Project"]: e.Project,
            ["CollectionDocuments"]: e.CollectionDocuments,
          },
        });
      }
    } else {
      setNFTFormValue(NFTFormValue);
    }

  };
  const Sessonselect = (e) => {
    SetValidateError({});
    SetFormButton("start");
    setNFTFormValue({
      ...NFTFormValue,
      ...{
        ["Stakingpool"]: e,
        // ["StakedNFTQuantity"]: NFTFormValue?.NFTQuantity,
      },
    });
    // SetError("")
    Setseason(e);
  };
  const handleCloseCreateItem = () => setShowCreateItem(false);
  const handleShowCreateItem = () => setShowCreateItem(true);
  const handleClosecalender = () => setCalenderShow(false);

  const Modelfuctions = async () => {
    handleShowCreateItem();
  };

  const [showAddCollection, setShowAddCollection] = useState(false);

  const handleCloseAddCollection = () => setShowAddCollection(false);
  const handleShowAddCollection = () => setShowAddCollection(true);

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00B800"
          : isSelected
            ? "#00B800"
            : isFocused
              ? "#00B800"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      padding: "0 10px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "16px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "45px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        marginTop: "-10px",
        color: valueContainerText,
      };
    },
  };

  const stylesgraybgTwo = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00B800"
          : isSelected
            ? "#00B800"
            : isFocused
              ? "#00B800"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "43px",
      padding: "0px",

      backgroundColor: "transparent",
      color: "red",
      border: "none",
      borderRadius: 0,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
      padding: "0px",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#00B800",
    }),
    menuList: (base) => ({
      ...base,

      padding: 0,

      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };
  const handleCheckboxActive = (e) => {
    SetValidateError({});

    setNFTFormValue({
      ...NFTFormValue,
      ...{ Project: !NFTFormValue?.Project },
    });
    setCollection(NFTFormValue?.Project == true ? 2 : 1);
  };

  const ChangeNetwork = async (e) => {
    try {
      // console.log('dataaaaa', e)
      if (e?.CHAIN_ID) {
        let data = await ContractCall.SwitchNetwork(e?.CHAIN_ID)
        // if (data) {
        //   SetValidateError({});
        //   SetFormButton("start");
        //   if (data == true) {
        //   }
        //   else {
        //     let type = collection == 1 ? 721 : 1155;
        //     let Addr = collection == 1 ? (e?.ERC721 ? e?.ERC721 : config.ERC721) : (e?.ERC1155 ? e?.ERC1155 : config.ERC1155);

        //     setNFTFormValue({
        //       ...NFTFormValue,
        //       ...{
        //         ["ContractAddress"]: Addr,
        //         ["ContractType"]: type,
        //         "ChainId": (e?.CHAIN_ID),

        //         ["CollectionSymbol"]: "",
        //         ["CollectionName"]: "",
        //         ["CollectionDocuments"]: "",
        //         ["Collectionid"]: "",
        //         "CoinName": "",
        //       },
        //     })
        //     SetValidateError({});
        //     SetFormButton("start");
        //     if (accountAddress) {
        //       CollectionByCreate({
        //         Type: collection == 1 ? 721 : 1155,
        //         Creator: accountAddress,
        //         Project: NFTFormValue?.Project,
        //         ChainId: String(e?.CHAIN_ID)
        //       });
        //     }
        //   }
        // }
      }
      else {
        toast.error("Invalid Network");
      }
    }
    catch (err) {
      console.log('ChangeNetwork_error', e)
    }
  }

  return (
    <>
      {console.log("showCreateSingleshowCreateSingle", showCreateSingle)}
      {showCreateSingle ? (
        <>
          <CreateSingleModal
            show={showCreateSingle}
            handleClose={handleClose}
            Project={setNFTFormValue}
            formvalue={NFTFormValue}
          />
          <section>
            <div className="innerpages createpage">
              <Innertitle title="Create" subtitle="" />
              <div className="container custom_container">
                <div className="row mt-5 justify-content-between">
                  <div className="col-lg-8 col-xl-7 leftside">
                    <form>
                      <div className="inner_row mb-3">
                        <p className="label">Upload file</p>
                        <div className="uploadsec">
                          <p className="support">
                            png, jpg, gif, webp or mp4. Max 50mb.
                          </p>
                          <p>
                            upload file in 280px * 300px for better experience
                          </p>

                          <div>
                            <button className="green_btn hover_btn">
                              Browse
                              <input
                                className=" cs_upld_inpt"
                                type="file"
                                id="NFTOrginalImage"
                                autoComplete="off"
                                onChange={(e) =>
                                  inputonchange(null, e.target.files, "Orginal")
                                }
                              />
                            </button>
                          </div>
                          {ValidateError.NFTOrginalImage && (
                            <span className="text-danger img-file">
                              {ValidateError.NFTOrginalImage}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="inner_row mb-3">
                        <p className="label">Upload logo</p>
                        <div className="uploadsec">
                          <p className="support">
                            png, jpg, gif, webp. Max 50mb.
                          </p>

                          <div>
                            <button className="green_btn hover_btn">
                              Browse
                              <input
                                className=" cs_upld_inpt"
                                type="file"
                                id="NFTOrginalImage"
                                autoComplete="off"
                                onChange={(e) =>
                                  inputonchange(null, e.target.files, "Logo")
                                }
                              />
                            </button>
                          </div>
                          {ValidateError.NFTLogoImage && (
                            <span className="text-danger img-file">
                              {ValidateError.NFTLogoImage}
                            </span>
                          )}
                        </div>
                      </div>
                      {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                        !NFTFormValue?.NFTOrginalImage?.type.includes(
                          "image"
                        ) && (
                          <div className="inner_row mb-3">
                            <p className="label">Upload Thump Image</p>
                            <div className="uploadsec">
                              <p className="support">png, jpg. Max 50mb.</p>
                              <p>
                                upload file in 280px * 300px for better
                                experience
                              </p>

                              {/* <button className="btn primary_btn">Browse</button> */}

                              <div>
                                <button className="green_btn hover_btn">
                                  Browse
                                  <input
                                    className=" cs_upld_inpt"
                                    type="file"
                                    id="NFTThumpImage"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      inputonchange(
                                        null,
                                        e.target.files,
                                        "Thump"
                                      )
                                    }
                                  />
                                </button>
                              </div>

                              {ValidateError?.NFTThumpImage && (
                                <span className="text-danger img-file">
                                  {ValidateError?.NFTThumpImage}
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                    </form>
                    <div>
                      <div className=" col-lg-4 col-xl-4 rightside mt-4 mt-lg-0  d-lg-none mb-4 d-flex flex-column align-items-center ">
                        <p className="label">Preview item</p>

                        <div className="">
                          <Nftcard
                            from={"Preview"}
                            product={NFTFormValue}
                            preview={NFTFormValue}
                            type="Preview"
                            index={1}
                            value={NFTFormValue}
                          />{" "}
                        </div>

                        {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                          !NFTFormValue?.NFTOrginalImage?.type.includes(
                            "image"
                          ) && (
                            <>
                              <p className="label mt-4">Preview Thumb Image</p>
                              <Row className="">
                                {/* {thumbCardData.map((value) => ( */}
                                {(!NFTFormValue?.NFTOrginalImage?.type?.includes(
                                  "image"
                                ) ||
                                  (typeof NFTFormValue?.NFTOrginalImage ==
                                    "string" &&
                                    (NFTFormValue?.NFTOrginalImage?.includes(
                                      ".mp3"
                                    ) ||
                                      NFTFormValue?.NFTOrginalImage?.includes(
                                        ".webm"
                                      )))) && (
                                    <Col className="">
                                      <Thumbcard
                                        key={1}
                                        value={
                                          typeof NFTFormValue?.NFTThumpImage ==
                                            "string"
                                            ? isEmpty(NFTFormValue?.NFTThumpImage)
                                              ? saleImg
                                              : `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT_THUMB/${NFTFormValue?.NFTThumpImage}`
                                            : URL.createObjectURL(
                                              NFTFormValue?.NFTThumpImage
                                            )
                                        }
                                      />
                                    </Col>
                                  )}
                                {/* ))} */}
                              </Row>
                            </>
                          )}
                      </div>
                    </div>

                    <p className="label">Select method</p>
                    <div className="tab_cont">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="third"
                      >
                        <Row>
                          <Col sm={12}>
                            <Nav variant="pills">
                              <div className="tab_row create_single d-flex align-items-center gap-3 pb-2">
                                <Nav.Item>
                                  <Nav.Link eventKey="third">
                                    {" "}
                                    <div
                                      className="d-flex flex-column align-items-center py-3"
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]:
                                              "UnlimitedAuction",
                                          },
                                        })
                                      }
                                    >
                                      {currTheme == "light" ? (
                                        <img src={openbidicon} alt="bids" />
                                      ) : (
                                        <img src={openBidDark} alt="bids" />
                                      )}

                                      <p className="method_type mb-0 mt-2">
                                        Open for bids
                                      </p>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                                {
                                  <Nav.Item>
                                    <Nav.Link eventKey="first">
                                      <div
                                        className="d-flex flex-column align-items-center py-3"
                                        onClick={() =>
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{
                                              ["PutOnSaleType"]: "FixedPrice",
                                            },
                                          })
                                        }
                                      >
                                        {currTheme == "light" ? (
                                          <img
                                            src={fixedicon}
                                            alt="fixed price"
                                          />
                                        ) : (
                                          <img
                                            src={fixedIconDark}
                                            alt="fixed price"
                                          />
                                        )}
                                        <p className="method_type mb-0 mt-2">
                                          Fixed Price
                                        </p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                }
                                {NFTFormValue?.ContractType == 721 && (
                                  <Nav.Item>
                                    <Nav.Link eventKey="second">
                                      {" "}
                                      <div
                                        className="d-flex flex-column align-items-center py-3"
                                        onClick={() =>
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{
                                              ["PutOnSaleType"]: "TimedAuction",
                                            },
                                          })
                                        }
                                      >
                                        {currTheme == "light" ? (
                                          <img
                                            src={timedicon}
                                            alt="time auction"
                                          />
                                        ) : (
                                          <img
                                            src={timedIconDark}
                                            alt="time auction"
                                          />
                                        )}
                                        <p className="method_type mb-0 mt-2">
                                          Time auction
                                        </p>
                                      </div>
                                    </Nav.Link>
                                  </Nav.Item>
                                )}
                              </div>
                            </Nav>
                          </Col>
                          <Col sm={12}>
                            <Tab.Content className="mt-4">
                              <Tab.Pane eventKey="first">
                                <div>
                                  {/* <div className="inner_row mb-3">
                            <p className="label">Select method</p>
                            <div className="meth_row">
                              <div className="method_card">
                                <img src={fixedicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                              <div className="method_card">
                                <img src={timedicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                              <div className="method_card">
                                <img src={openbidicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                            </div>
                          </div> */}
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Price
                                      <span className="text-danger">*</span>
                                    </p>
                                    <Row className="cust_inpt_slt mx-auto">
                                      <Col
                                        xs={7}
                                        sm={8}
                                        md={9}
                                        lg={8}
                                        xl={9}
                                        className="ps-0 pe-2 d-flex align-items-center"
                                      >
                                        <div class="form-group w-100">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="NFTPrice"
                                            onChange={inputonchange}
                                            value={NFTFormValue.NFTPrice}
                                            aria-describedby="emailHelp"
                                            placeholder="enter price for one items"
                                            autoComplete="off"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        xs={5}
                                        sm={4}
                                        md={3}
                                        lg={4}
                                        xl={3}
                                        className="px-0"
                                      >
                                        {" "}
                                        <Select
                                          styles={stylesgraybgTwo}
                                          onChange={(e) => {
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{ ["CoinName"]: e.label },
                                            });
                                          }}
                                          value={

                                            {
                                              label: NFTFormValue.CoinName,
                                              value: NFTFormValue.CoinName,
                                            }
                                          }
                                          defaultValue={
                                            NFTFormValue.PutOnSaleType
                                              ? NFTFormValue.PutOnSaleType ==
                                                "FixedPrice"
                                                ? currency.filter(
                                                  (item) =>
                                                    item.value !== currentNetwork?.COIN_NAME
                                                )[0]
                                                : ""
                                              : ""
                                          }
                                          options={
                                            // currency
                                            NFTFormValue.PutOnSaleType ==
                                              "FixedPrice"
                                              ? currency?.filter(
                                                (item) => item.deleted != true
                                              )
                                              : currency?.filter(
                                                (item) => item.label != currentNetwork?.COIN_NAME
                                              )
                                          }
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />
                                      </Col>
                                    </Row>
                                    {/* <div class="form-group">

                                <input
                                  type="text"
                                  class="form-control custom_input"
                                  id="NFTPrice"
                                  onChange={inputonchange}
                                  value={NFTFormValue.NFTPrice}
                                  aria-describedby="emailHelp"
                                  placeholder="enter price for one item (BNB)"
                                />
                              </div> */}
                                    {/* <Select
                                styles={stylesgraybg}
                                onChange={(e) => {
                                  console.log("sfjgfjshfhsjf", e.label);
                                  setNFTFormValue({
                                    ...NFTFormValue,
                                    ...{ ["CoinName"]: e.label },
                                  });
                                }}
                                value={{
                                  label: NFTFormValue.CoinName,
                                  value: NFTFormValue.CoinName,
                                }}
                                defaultValue={
                                  NFTFormValue.PutOnSaleType
                                    ? NFTFormValue.PutOnSaleType == "FixedPrice"
                                      ? currency.filter(
                                        (item) => item.value !== "ETH"
                                      )[0]
                                      : ""
                                    : ""
                                }
                                options={
                                  NFTFormValue.PutOnSaleType == "FixedPrice"
                                    ? currency?.filter(
                                      (item) => item.deleted != true
                                    )
                                    : currency?.filter(
                                      (item) => item.label != "ETH"
                                    )
                                }
                                classNamePrefix="react-select"
                                isSearchable={false}
                              /> */}
                                    {ValidateError.NFTPrice && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPrice}
                                      </span>
                                    )}
                                  </div>
                                  {/* unlock once purchased */}
                                  {/* <Card className="tab_card mb-3 mt-4">
                                    <Card.Body className="p-4 ">
                                      <Row>
                                        <Col xs={8}>
                                          <div>
                                            <div className="d-flex align-items-start  lock_cont  gap-1">
                                              <img
                                                src={lock}
                                                alt="lock"
                                                className="img-fluid "
                                              />
                                              <h5 className="mb-0 heading tab_card_heads">
                                                Unlock once purchased
                                              </h5>
                                            </div>
                                            <p className="mb-0 mt-4 text_p">
                                              Unlock content after successful
                                              transaction.
                                            </p>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={4}
                                          className="d-flex justify-content-end"
                                        >
                                          <label class="switch tab_toggle">
                                            <input
                                              type="checkbox"
                                              onChange={(e) => {
                                                setUnlock(!Unlock);
                                              }}
                                            />
                                            <span class="slider round"></span>
                                          </label>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                  {Unlock && (
                                    <div className="inner_row mb-3">
                                      <p className="label">Unlcok Content</p>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="UnlockContent"
                                          onChange={inputonchange}
                                          value={NFTFormValue.UnlockContent}
                                          placeholder="e.g. `Cripto Funk"
                                        />
                                      </div>
                                      {ValidateError.UnlockContent && (
                                        <span className="text-danger img-file">
                                          {ValidateError.UnlockContent}
                                        </span>
                                      )}
                                    </div>
                                  )} */}
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Type<span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                          value:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                        }}
                                        onChange={(e) => setCollection(e.value)}
                                        options={[
                                          { value: 1, label: "Single" },
                                          { value: 2, label: "Multiple" },
                                        ]}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      // isDisabled={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Choose collection
                                      <span className="text-danger">*</span>
                                    </p>
                                    <Row className="mx-auto">
                                      <Col xs={8} className="ps-0">
                                        <div>
                                          <h5 className="heading mb-0">
                                            {" "}
                                            Project
                                          </h5>
                                          <p>
                                            If you Want Choose Project for
                                            Staking Your NFT
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={4}
                                        className="pe-4 d-flex justify-content-end"
                                      >
                                        {" "}
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={handleCheckboxActive}
                                            checked={NFTFormValue?.Project}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row>

                                    <div class="form-group custom_choose_collec">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        onChange={(e) => {
                                          ChooseCollection(e);
                                        }}
                                        options={CreateCollectionState}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        value={{
                                          value: NFTFormValue?.CollectionName,
                                          label: NFTFormValue?.CollectionName,
                                        }}
                                      />
                                    </div>
                                    {ValidateError.CollectionSymbol && (
                                      <span className="text-danger img-file">
                                        {ValidateError.CollectionSymbol}
                                      </span>
                                    )}
                                  </div>
                                  <Row>
                                    <Col>
                                      <div className="inner_row mb-3">
                                        <p className="label">
                                          Starting Date
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div className=" position-relative date_picker ">
                                          <Select
                                            className="react_slt rem_drp_dwn_icn"
                                            styles={stylesgraybg}
                                            value={{
                                              value: NFTFormValue.ClockTime,
                                              label:
                                                NFTFormValue.ClockTime == ""
                                                  ? NFTFormValue.ClockTime
                                                  : moment(
                                                    NFTFormValue.ClockTime
                                                  ).format(
                                                    "YYYY-MM-DD h:mm:ss a"
                                                  ),
                                            }}
                                            onChange={(e) =>
                                              DateSelection(e, "start")
                                            }
                                            options={startingdate}
                                            classNamePrefix="react-select-one"
                                            isSearchable={false}
                                          />

                                          {ValidateError.ClockTime && (
                                            <span className="text-danger img-file">
                                              {ValidateError.ClockTime}
                                            </span>
                                          )}
                                          {currTheme == "light" ? (
                                            <img
                                              src={dateImg}
                                              alt="date-choosen"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          ) : (
                                            <img
                                              src={datewhite}
                                              alt="date"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          )}
                                          {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div>
                                        <p className="label">
                                          Ending Date
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div className=" position-relative date_picker ">
                                          <Select
                                            className="react_slt rem_drp_dwn_icn"
                                            styles={stylesgraybg}
                                            value={{
                                              value: NFTFormValue.EndClockTime,
                                              label:
                                                NFTFormValue.EndClockTime == ""
                                                  ? NFTFormValue.EndClockTime
                                                  : moment(
                                                    NFTFormValue.EndClockTime
                                                  ).format(
                                                    "YYYY-MM-DD h:mm:ss a"
                                                  ),
                                            }}
                                            onChange={(e) =>
                                              DateSelection(e, "end")
                                            }
                                            options={enddate}
                                            classNamePrefix="react-select-one"
                                            isSearchable={false}
                                          />
                                          {ValidateError.EndClockTime && (
                                            <span className="text-danger img-file">
                                              {ValidateError.EndClockTime}
                                            </span>
                                          )}
                                          {currTheme == "light" ? (
                                            <img
                                              src={dateImg}
                                              alt="date-choosen"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          ) : (
                                            <img
                                              src={datewhite}
                                              alt="date"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>


                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Title{" "}
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={inputonchange}
                                        id="NFTName"
                                        value={NFTFormValue.NFTName}
                                        aria-describedby="emailHelp"
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.NFTName && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTName}
                                      </span>
                                    )}
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">Description</p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={inputonchange}
                                        id="NFTDescription"
                                        value={NFTFormValue.NFTDescription}
                                        aria-describedby="emailHelp"
                                        placeholder="e.g. `This is very limited item`"
                                      />
                                    </div>
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Royalties
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTRoyalty"
                                        value={NFTFormValue.NFTRoyalty}
                                        onChange={inputonchange}
                                        aria-describedby="emailHelp"
                                        placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                      />
                                      {ValidateError.NFTRoyalty && (
                                        <span className="text-danger img-file">
                                          {ValidateError.NFTRoyalty}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <p className="label">
                                    Block Chain
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={currentNetwork}
                                      onChange={(e) => ChangeNetwork(e)}
                                      options={Object.values(network)}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                    />
                                  </div>{" "}
                                  {!NFTFormValue?.Project && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Token Burn Status
                                        <span className="text-danger">*</span>
                                      </p>

                                      <div class="form-group">
                                        <Select
                                          styles={stylesgraybg}
                                          className="react_slt select_dropdown_modal"
                                          value={{
                                            label:
                                              NFTFormValue?.Burnable == true
                                                ? "Burnable"
                                                : "Unburnable",
                                            value:
                                              NFTFormValue?.Burnable == true
                                                ? "Burnable"
                                                : "Unburnable",
                                          }}
                                          onChange={(e) => (
                                            SetValidateError({}),
                                            SetFormButton("start"),
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{ ["Burnable"]: e.value },
                                            })
                                          )}
                                          options={[
                                            { value: true, label: "Burnable" },
                                            {
                                              value: false,
                                              label: "Unburnable",
                                            },
                                          ]}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {NFTFormValue?.ContractType !== 721 && (
                                    <div>
                                      <p className="label">
                                        No Of Copies
                                        <span className="text-danger">*</span>
                                      </p>
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={(e) => {
                                          if (
                                            e.target.value == "" ||
                                            config.NumOnly.test(e.target.value)
                                          ) {
                                            inputonchange(e);
                                          }
                                        }}
                                        id="NFTQuantity"
                                        value={NFTFormValue?.NFTQuantity}
                                        placeholder="No of Copy"
                                        autoComplete="off"
                                      />
                                    </div>
                                  )}
                                  {ValidateError.NFTQuantity && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTQuantity}
                                    </span>
                                  )}{" "}
                                  {NFTFormValue?.Project &&
                                    NFTFormValue?.CollectionDocuments && (
                                      <div className="inner_row mb-3">
                                        <p className="label">
                                          Documents
                                          <span className="text-danger">*</span>
                                        </p>
                                        <p className="mb-3 text_p">
                                          If You want to know more about this
                                          project please Click here...!
                                        </p>
                                        <button className="green_btn hover_btn a_btn mt-4">
                                          <a
                                            href={`${config.IMG_URL}/collection/documents/${NFTFormValue?.CollectionSymbol}/${NFTFormValue?.CollectionDocuments}`}
                                            download
                                            target="_blank"
                                            className="text-decoration-none"
                                          >
                                            view pdf
                                          </a>
                                        </button>
                                      </div>
                                    )}


                                  <Button
                                    className="green_btn hover_btn card_btn  mt-4 "
                                    disabled={
                                      FormButton == "error" ||
                                        FormButton == "done" ||
                                        FormButton == "process"
                                        ? true
                                        : false
                                    }
                                    onClick={
                                      FormButton == "start" ? FormSubmit : null
                                    }
                                  >
                                    {FormButton == "start" && "Publish"}
                                    {FormButton == "error" && "Fix-Error"}
                                    {FormButton == "process" && "processing"}
                                    {FormButton == "done" && "Done"}
                                  </Button>
                                  {/* <Button
                              onClick={() => {
                                handleShowAddCollection();
                              }}
                              className="green_btn hover_btn  mt-4 ms-3 green_btn_sm_mb"
                            >
                              Add Collection
                            </Button> */}
                                </div>
                              </Tab.Pane>

                              {/* ---------------Timed Auction------------------------- */}
                              <Tab.Pane eventKey="second">
                                {" "}
                                <div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Minimum Bid
                                      <span className="text-danger">*</span>
                                    </p>
                                    <Row className="cust_inpt_slt mx-auto">
                                      <Col
                                        xs={7}
                                        sm={8}
                                        md={9}
                                        lg={8}
                                        xl={9}
                                        className="ps-0 pe-2 d-flex align-items-center"
                                      >
                                        <div class="form-group w-100">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="NFTMinimumBid"
                                            value={NFTFormValue.NFTMinimumBid}
                                            onChange={inputonchange}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter minimum bid"
                                          />
                                        </div>
                                      </Col>
                                      <Col
                                        xs={5}
                                        sm={4}
                                        md={3}
                                        lg={4}
                                        xl={3}
                                        className="px-0"
                                      >
                                        <Select
                                          styles={stylesgraybgTwo}
                                          onChange={(e) => {
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{ ["CoinName"]: e.label },
                                            });
                                          }}
                                          value={{
                                            label: NFTFormValue.CoinName,
                                            value: NFTFormValue.CoinName,
                                          }}
                                          defaultValue={
                                            NFTFormValue.PutOnSaleType
                                              ? NFTFormValue.PutOnSaleType ==
                                                "FixedPrice"
                                                ? currency.filter(
                                                  (item) =>
                                                    item.value !== currentNetwork?.COIN_NAME
                                                )[0]
                                                : ""
                                              : ""
                                          }
                                          options={
                                            NFTFormValue.PutOnSaleType ==
                                              "FixedPrice"
                                              ? currency?.filter(
                                                (item) => item.deleted != true
                                              )
                                              : currency?.filter(
                                                (item) =>
                                                  !item.address.includes(
                                                    currentNetwork?.COIN_NAME
                                                  )
                                              )
                                          }
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />
                                      </Col>
                                    </Row>
                                  </div>
                                  {console.log(
                                    "ValidateErrorValidateError",
                                    ValidateError
                                  )}{" "}
                                  {ValidateError.NFTMinimumBid && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTMinimumBid}
                                    </span>
                                  )}
                                  {/* </div> */}
                                  {/* <Card className="tab_card mb-3 mt-4">
                                    <Card.Body className="p-4 ">
                                      <Row>
                                        <Col xs={8}>
                                          <div>
                                            <div className="d-flex  lock_cont  gap-1">
                                              <img
                                                src={lock}
                                                alt="lock"
                                                className="img-fluid "
                                              />
                                              <h5 className="mb-0 heading tab_card_heads">
                                                Unlock once purchased
                                              </h5>
                                            </div>
                                            <p className="mb-0 mt-4 text_p">
                                              Unlock content after successful
                                              transaction.
                                            </p>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={4}
                                          className="d-flex justify-content-end"
                                        >
                                          Rounded switch
                                          <label class="switch tab_toggle">
                                            <input
                                              type="checkbox"
                                              onChange={(e) => {
                                                setUnlock(!Unlock);
                                              }}
                                            />
                                            <span class="slider round"></span>
                                          </label>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card> */}
                                  {/* unlock once purchased */}
                                  {/* {Unlock && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Unlcok Content
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="UnlockContent"
                                          onChange={inputonchange}
                                          value={NFTFormValue.UnlockContent}
                                          placeholder="e.g. `Cripto Funk"
                                        />
                                      </div>
                                      {ValidateError.UnlockContent && (
                                        <span className="text-danger img-file">
                                          {ValidateError.UnlockContent}
                                        </span>
                                      )}
                                    </div>
                                  )} */}
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Type<span className="text-danger">*</span>
                                    </p>

                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                          value:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                        }}
                                        onChange={(e) => setCollection(e.value)}
                                        options={[
                                          { value: 1, label: "Single" },
                                          { value: 2, label: "Multiple" },
                                        ]}
                                        // isDisabled={
                                        //   NFTFormValue?.PutOnSaleType ==
                                        //   "TimedAuction"
                                        // }
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Choose collection
                                      <span className="text-danger">*</span>
                                    </p>
                                    <Row className="mx-auto">
                                      <Col xs={9} className="ps-0">
                                        <div>
                                          <h5 className="heading mb-0">
                                            {" "}
                                            Project
                                          </h5>
                                          <p>
                                            If you Want Choose Project for
                                            Staking Your NFT
                                          </p>
                                        </div>
                                      </Col>
                                      <Col xs={3} className="pe-0">
                                        {" "}
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={handleCheckboxActive}
                                            checked={NFTFormValue?.Project}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row>

                                    <div class="form-group custom_choose_collec">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        onChange={(e) => {
                                          ChooseCollection(e);
                                        }}
                                        options={CreateCollectionState}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        value={{
                                          value: NFTFormValue?.CollectionName,
                                          label: NFTFormValue?.CollectionName,
                                        }}
                                      />
                                    </div>
                                    {ValidateError.CollectionSymbol && (
                                      <span className="text-danger img-file">
                                        {ValidateError.CollectionSymbol}
                                      </span>
                                    )}
                                  </div>
                                  <Row>
                                    <Col>
                                      <div>
                                        <p className="label">
                                          Starting Date
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div className=" position-relative date_picker  ">
                                          <Select
                                            value={{
                                              value: NFTFormValue.ClockTime,
                                              label:
                                                NFTFormValue.ClockTime == ""
                                                  ? NFTFormValue.ClockTime
                                                  : moment(
                                                    NFTFormValue.ClockTime
                                                  ).format(
                                                    "YYYY-MM-DD h:mm:ss a"
                                                  ),
                                            }}
                                            onChange={(e) =>
                                              DateSelection(e, "start")
                                            }
                                            className="react_slt rem_drp_dwn_icn"
                                            styles={stylesgraybg}
                                            options={startingdate}
                                            // classNamePrefix="react-select"
                                            isSearchable={false}
                                          />

                                          {ValidateError.ClockTime && (
                                            <span className="text-danger img-file">
                                              {ValidateError.ClockTime}
                                            </span>
                                          )}
                                          {currTheme == "light" ? (
                                            <img
                                              src={dateImg}
                                              alt="date-choosen"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          ) : (
                                            <img
                                              src={datewhite}
                                              alt="date"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          )}
                                          {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div>
                                        <p className="label">
                                          Ending Date
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div className=" position-relative date_picker  ">
                                          <Select
                                            value={{
                                              value: NFTFormValue.EndClockTime,
                                              label:
                                                NFTFormValue.EndClockTime == ""
                                                  ? NFTFormValue.EndClockTime
                                                  : moment(
                                                    NFTFormValue.EndClockTime
                                                  ).format(
                                                    "YYYY-MM-DD h:mm:ss a"
                                                  ),
                                            }}
                                            onChange={(e) =>
                                              DateSelection(e, "end")
                                            }
                                            options={enddate}
                                            styles={stylesgraybg}
                                            classNamePrefix="react-select-one"
                                            className="react_slt rem_drp_dwn_icn"
                                            isSearchable={false}
                                          />
                                          {ValidateError.EndClockTime && (
                                            <span className="text-danger img-file">
                                              {ValidateError.EndClockTime}
                                            </span>
                                          )}
                                          {currTheme == "light" ? (
                                            <img
                                              src={dateImg}
                                              alt="date-choosen"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          ) : (
                                            <img
                                              src={datewhite}
                                              alt="date"
                                              className="img-fluid position-absolute date_icon"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Title
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTName"
                                        onChange={inputonchange}
                                        value={NFTFormValue.NFTName}
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.NFTName && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTName}
                                      </span>
                                    )}
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">Description</p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={inputonchange}
                                        id="NFTDescription"
                                        value={NFTFormValue.NFTDescription}
                                        placeholder="e.g. `This is very limited item`"
                                      />
                                    </div>
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Royalties
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTRoyalty"
                                        value={NFTFormValue.NFTRoyalty}
                                        onChange={inputonchange}
                                        aria-describedby="emailHelp"
                                        placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                      />
                                    </div>

                                    {ValidateError.NFTRoyalty && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTRoyalty}
                                      </span>
                                    )}
                                  </div>
                                  {NFTFormValue?.Project && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Pictures Royalties
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="NFTPictureRoyalty"
                                          value={NFTFormValue.NFTPictureRoyalty}
                                          onChange={inputonchange}
                                          aria-describedby="emailHelp"
                                          placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                        />
                                      </div>

                                      {ValidateError.NFTPictureRoyalty && (
                                        <span className="text-danger img-file">
                                          {ValidateError.NFTPictureRoyalty}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Block Chain
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={currentNetwork}
                                        onChange={(e) => ChangeNetwork(e)}
                                        options={Object.values(network)}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>{" "}
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Token Burn Status
                                      <span className="text-danger">*</span>
                                    </p>

                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                          value:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                        }}
                                        onChange={(e) => (
                                          SetValidateError({}),
                                          SetFormButton("start"),
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["Burnable"]: e.value },
                                          })
                                        )}
                                        options={[
                                          { value: true, label: "Burnable" },
                                          { value: false, label: "Unburnable" },
                                        ]}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                  {NFTFormValue?.ContractType !== 721 && (
                                    <div>
                                      <p className="label">
                                        No of Copies
                                        <span className="text-danger">*</span>
                                      </p>

                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={(e) => {
                                          if (
                                            e.target.value == "" ||
                                            config.NumOnly.test(e.target.value)
                                          ) {
                                            inputonchange(e);
                                          }
                                        }}
                                        id="NFTQuantity"
                                        value={NFTFormValue?.NFTQuantity}
                                        placeholder="No of Copy"
                                        autoComplete="off"
                                      />
                                    </div>
                                  )}
                                  {ValidateError.NFTQuantity && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTQuantity}
                                    </span>
                                  )}{" "}
                                  {/* <div className="inner_row mb-3">
                                    {" "}
                                    <div>
                                      <p className="label mb-0">
                                        Properties
                                        <span className="text-danger">*</span>
                                      </p>

                                      <div className="cs_prp_wrp d-flex align-items-center gap-3 py-3">
                                        {number.map((value, index) => (
                                          <>
                                            {number.length - 2 >= index && (
                                              <div className="d-flex align-items-center justify-content-between gap-3 cs_prp_crd p-2">
                                                <div className="d-flex align-items-center">
                                                  <p className="mb-0 pe-1">
                                                    {key[index]}
                                                  </p>{" "}
                                                  :
                                                  <p className="mb-0 ps-1">
                                                    {Value[index]}
                                                  </p>
                                                </div>

                                                <i
                                                  class="fa-solid fa-x cs_prp_cls"
                                                  onClick={() =>
                                                    RemoveProperties(index)
                                                  }
                                                ></i>
                                              </div>
                                            )}
                                          </>
                                          // )}
                                        ))}
                                      </div>
                                    </div>{" "}
                                  </div>{" "} */}

                                  <Button
                                    className="green_btn hover_btn card_btn  mt-4 "
                                    disabled={
                                      FormButton == "error" ||
                                        FormButton == "done" ||
                                        FormButton == "process"
                                        ? true
                                        : false
                                    }
                                    onClick={
                                      FormButton == "start" ? FormSubmit : null
                                    }
                                  >
                                    {FormButton == "start" && "Publish"}
                                    {FormButton == "error" && "Fix-Error"}
                                    {FormButton == "process" && "processing"}
                                    {FormButton == "done" && "Done"}
                                  </Button>
                                  {/* <Button
                              onClick={() => {
                                handleShowAddCollection();
                              }}
                              className="green_btn hover_btn  mt-4 ms-3 green_btn_sm_mb"
                            >
                              Add Collection
                            </Button> */}
                                </div>
                              </Tab.Pane>
                              <Tab.Pane eventKey="third">
                                {" "}
                                <div>
                                  {/* <div className="inner_row mb-3">
                              <p className="label">Price</p>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control custom_input"
                                  id="NFTPrice"
                                  onChange={inputonchange}
                                  value={NFTFormValue.NFTPrice}
                                  aria-describedby="emailHelp"
                                  placeholder="enter price for one item (ETH)"
                                />
                                
                                  {ValidateError.NFTPrice && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPrice}
                                      </span>
                                    )}
                              </div>
                            </div> */}
                                  {/* unlock once purchased */}
                                  {/* <Card className="tab_card mb-3 mt-4">
                                    <Card.Body className="p-4 ">
                                      <Row>
                                        <Col xs={8} sm={10}>
                                          <div>
                                            <div className="d-flex  lock_cont  gap-1">
                                              <img
                                                src={lock}
                                                alt="lock"
                                                className="img-fluid "
                                              />
                                              <h5 className="mb-0 heading tab_card_heads">
                                                Unlock once purchased
                                              </h5>
                                            </div>
                                            <p className="mb-0 mt-4 text_p">
                                              Unlock content after successful
                                              transaction.
                                            </p>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={4}
                                          sm={2}
                                          className="d-flex justify-content-end"
                                        >
                                          Rounded switch
                                          <label class="switch tab_toggle">
                                            <input
                                              type="checkbox"
                                              onChange={(e) => {
                                                setUnlock(!Unlock);
                                              }}
                                            />
                                            <span class="slider round"></span>
                                          </label>
                                        </Col>
                                      </Row>
                                    </Card.Body>
                                  </Card> */}
                                  {/* {Unlock && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Unlock Content
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div class="form-group">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="UnlockContent"
                                          onChange={inputonchange}
                                          value={NFTFormValue.UnlockContent}
                                          placeholder="e.g. `Cripto Funk"
                                        />
                                      </div>
                                      {ValidateError.UnlockContent && (
                                        <span className="text-danger img-file">
                                          {ValidateError.UnlockContent}
                                        </span>
                                      )}
                                    </div>
                                  )} */}

                                  {!NFTFormValue.Project &&
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Type{" "}
                                        <span className="text-danger">*</span>
                                      </p>

                                      <div class="form-group">
                                        <Select
                                          styles={stylesgraybg}
                                          className="react_slt select_dropdown_modal"
                                          value={{
                                            label:
                                              NFTFormValue?.ContractType == 721
                                                ? "Single"
                                                : "Multiple",
                                            value:
                                              NFTFormValue?.ContractType == 721
                                                ? "Single"
                                                : "Multiple",
                                          }}
                                          onChange={(e) => (
                                            setCollection(e.value),
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{
                                                ["CollectionName"]: "",
                                                ["CollectionSymbol"]: "",
                                              },
                                            })
                                          )}
                                          options={[
                                            { value: 1, label: "Single" },
                                            { value: 2, label: "Multiple" },
                                          ]}
                                          isDisabled={NFTFormValue.Project}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}

                                        />
                                      </div>
                                    </div>}

                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Choose collection
                                      <span className="text-danger">*</span>
                                    </p>
                                    {/* {  NFTFormValue?.Project&& 
                  <Row className="mx-auto">
                                      <Col xs={8} className="ps-0">
                                        <div>
                                          <h5 className="heading mb-0">
                                            {" "}
                                            Project
                                          </h5>
                                          <p>
                                            If you Want Choose Project for
                                            Staking Your NFT
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={4}
                                        className="pe-4 d-flex justify-content-end"
                                      >
                                        {" "}
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={handleCheckboxActive}
                                            checked={NFTFormValue?.Project}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row> 
                                    }*/}

                                    {/* <p className="text_p">
                                This is the collection where your item will
                                appear.
                              </p> */}
                                    <div class="form-group custom_choose_collec">
                                      <Select
                                        // menuIsOpen={true}
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        onChange={(e) => {
                                          ChooseCollection(e);
                                        }}
                                        options={CreateCollectionState}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        value={
                                          NFTFormValue?.CollectionName ?
                                            {
                                              value: NFTFormValue?.CollectionName,
                                              label: NFTFormValue?.CollectionName,
                                            } :
                                            {
                                              label: "Select Collection",
                                              value: "Select Collection",
                                            }
                                        }
                                      // defaultMenuIsOpen
                                      />
                                    </div>
                                    {ValidateError.CollectionSymbol && (
                                      <span className="text-danger img-file">
                                        {ValidateError.CollectionSymbol}
                                      </span>
                                    )}
                                  </div>

                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Title
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTName"
                                        onChange={inputonchange}
                                        value={NFTFormValue.NFTName}
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.NFTName && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTName}
                                      </span>
                                    )}
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">Description</p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="e.g. `This is very limited item`"
                                      />
                                    </div>
                                  </div>
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Royalties
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTRoyalty"
                                        value={NFTFormValue.NFTRoyalty}
                                        onChange={inputonchange}
                                        aria-describedby="emailHelp"
                                        placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                      />
                                    </div>

                                    {ValidateError.NFTRoyalty && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTRoyalty}
                                      </span>
                                    )}
                                  </div>{" "}

                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Block Chain
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={currentNetwork}
                                        onChange={(e) => ChangeNetwork(e)}
                                        options={Object.values(network)}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>{" "}
                                  </div>
                                  {!NFTFormValue?.Project && (
                                    <div className="inner_row mb-3 ">
                                      <p className="label">
                                        Token Burn Status
                                        <span className="text-danger">*</span>
                                      </p>

                                      <div class="form-group">
                                        <Select
                                          styles={stylesgraybg}
                                          className="react_slt select_dropdown_modal"
                                          value={{
                                            label:
                                              NFTFormValue?.Burnable == true
                                                ? "Burnable"
                                                : "Unburnable",
                                            value:
                                              NFTFormValue?.Burnable == true
                                                ? "Burnable"
                                                : "Unburnable",
                                          }}
                                          onChange={(e) => (
                                            SetValidateError({}),
                                            SetFormButton("start"),
                                            setNFTFormValue({
                                              ...NFTFormValue,
                                              ...{ ["Burnable"]: e.value },
                                            })
                                          )}
                                          options={[
                                            { value: true, label: "Burnable" },
                                            {
                                              value: false,
                                              label: "Unburnable",
                                            },
                                          ]}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {NFTFormValue?.ContractType !== 721 && (
                                    <div className="inner_row mb-3 ">
                                      <p className="label">
                                        No Of Copies
                                        <span className="text-danger">*</span>
                                      </p>
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        onChange={(e) => {
                                          if (
                                            e.target.value == "" ||
                                            config.NumOnly.test(e.target.value)
                                          ) {
                                            inputonchange(e);
                                          }
                                        }}
                                        id="NFTQuantity"
                                        value={NFTFormValue?.NFTQuantity}
                                        placeholder="No of Copy"
                                        autoComplete="off"
                                      />
                                    </div>
                                  )}
                                  {ValidateError.NFTQuantity && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTQuantity}
                                    </span>
                                  )}{" "}
                                  {NFTFormValue?.Project &&
                                    NFTFormValue?.CollectionDocuments && (
                                      <div className="inner_row mb-3">
                                        <p className="label">
                                          Documents
                                          <span className="text-danger">*</span>
                                        </p>
                                        <p className="mb-3 text_p">
                                          If You want to know more about this
                                          project please Click here...!
                                        </p>
                                        <button className="green_btn hover_btn a_btn mt-4">
                                          <a
                                            href={`${config.IMG_URL}/collection/documents/${NFTFormValue?.CollectionSymbol}/${NFTFormValue?.CollectionDocuments}`}
                                            download
                                            target="_blank"
                                            className="text-decoration-none"
                                          >
                                            view pdf
                                          </a>
                                        </button>
                                      </div>
                                    )}

                                  {/* <div className="inner_row mb-3">
                                    {" "}
                                    <div>
                                      <p className="label mb-0">
                                        Properties
                                        <span className="text-danger">*</span>
                                      </p>

                                      <div className="cs_prp_wrp d-flex align-items-center gap-3 py-3">
                                        {number.map((value, index) => (
                                          <>
                                            {number.length - 2 >= index && (
                                              <div className="d-flex align-items-center justify-content-between gap-3 cs_prp_crd p-2">
                                                <div className="d-flex align-items-center">
                                                  <p className="mb-0 pe-1">
                                                    {key[index]}
                                                  </p>{" "}
                                                  :
                                                  <p className="mb-0 ps-1">
                                                    {Value[index]}
                                                  </p>
                                                </div>

                                                <i
                                                  class="fa-solid fa-x cs_prp_cls"
                                                  onClick={() =>
                                                    RemoveProperties(index)
                                                  }
                                                ></i>
                                              </div>
                                            )}
                                          </>
                                          // )}
                                        ))}
                                      </div>
                                    </div>{" "}
                                  </div>{" "} */}

                                  <div className="d-flex flex-column flex-sm-row align-items-center">
                                    <Button
                                      className="green_btn hover_btn card_btn  mt-4 "
                                      disabled={
                                        FormButton == "error" ||
                                          FormButton == "done" ||
                                          FormButton == "process"
                                          ? true
                                          : false
                                      }
                                      onClick={
                                        FormButton == "start"
                                          ? FormSubmit
                                          : null
                                      }
                                    >
                                      {FormButton == "start" && "Publish"}
                                      {FormButton == "error" && "Fix-Error"}
                                      {FormButton == "process" && "processing"}
                                      {FormButton == "done" && "Done"}
                                    </Button>
                                    {/* <Button
                                onClick={() => {
                                  handleShowAddCollection();
                                }}
                                className="green_btn hover_btn  mt-4 ms-sm-4 green_btn_sm_mb"
                              >
                                Add Collection
                              </Button> */}
                                  </div>
                                </div>
                              </Tab.Pane>
                            </Tab.Content>
                          </Col>
                        </Row>
                      </Tab.Container>
                    </div>
                  </div>
                  <div className=" col-lg-4 col-xl-4 rightside mt-4 mt-lg-0 d-none d-lg-block ">
                    <p className="label">Preview item</p>

                    <div className="">
                      <Nftcard
                        from={"Preview"}
                        product={NFTFormValue}
                        preview={NFTFormValue}
                        type="Preview"
                        index={1}
                        value={NFTFormValue}
                      />{" "}
                    </div>
                    {/* ))} */}

                    {/* {typeof NFTFormValue?.NFTOrginalImage == "string" ? (
                  isEmpty(NFTFormValue?.NFTOrginalImage) ? (
                    <img src={saleImg} alt="images" />
                  ) : (
                    <ImgAudVideo
                      file={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`}
                      type={
                        NFTFormValue?.CompressedFile?.includes(".webp")
                          ? "image"
                          : NFTFormValue?.CompressedFile?.includes(".webm") ||
                            NFTFormValue?.CompressedFile?.includes(".mp4")
                          ? "video"
                          : "audio"
                      }
                      origFile={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`}
                      thumb={
                        NFTFormValue?.NFTThumpImage
                          ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`
                          : saleImg
                      }
                    />
                  //   <Nftcard 
                  //   from    = {'Preview'}
                  //   product={NFTFormValue}
                  //   type="Previed"
                  //   index={1}
                  //   value={NFTFormValue}
                  //   // LikeList={LikeList}
                  //   // LikedTokenList={LikedTokenList}
                  //   // setLikedTokenList={setLikedTokenList}
                  //   // LikeForwardRef={LikeForwardRef}
                  // />
                    // <Nftcard key={value.id} value={value} />
                  )
                ) 
                : 
                
                (     <Nftcard 
                  from    = {'Preview'}
                  product={NFTFormValue}
                  preview={NFTFormValue}
                  type="Previed"
                  index={1}
                  value={NFTFormValue}
                  // LikeList={LikeList}
                  // LikedTokenList={LikedTokenList}
                  // setLikedTokenList={setLikedTokenList}
                  // LikeForwardRef={LikeForwardRef}
                />)

                // NFTFormValue?.NFTOrginalImage?.type?.includes("image") ? (
                //   <>
                //     <img
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     />
                //   </>
                // ) : NFTFormValue?.NFTOrginalImage?.type?.includes("video") ? (
                //   <>
                //     <video
                //       loop={true}
                //       muted
                //       autoPlay={true}
                //       controls
                //       onContextMenu="return false;"
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     ></video>
                //   </>
                // ) : NFTFormValue?.NFTOrginalImage?.type?.includes("audio") ? (
                //   <>
                //     <audio
                //       controls
                //       onContextMenu="return false;"
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     ></audio>
                //   </>
                // )
                //  : (
                //   <img
                //     src={
                //       NFTFormValue?.NFTOrginalImage != ""
                //         ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`
                //         : saleImg
                //     }
                //     alt="images"
                //   />
                // )
                } */}
                    {/* </div> */}

                    {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                      !NFTFormValue?.NFTOrginalImage?.type.includes(
                        "image"
                      ) && (
                        <>
                          <p className="label mt-4">Preview Thumb Image</p>
                          <Row className="">
                            {/* {thumbCardData.map((value) => ( */}
                            {(!NFTFormValue?.NFTOrginalImage?.type?.includes(
                              "image"
                            ) ||
                              (typeof NFTFormValue?.NFTOrginalImage ==
                                "string" &&
                                (NFTFormValue?.NFTOrginalImage?.includes(
                                  ".mp3"
                                ) ||
                                  NFTFormValue?.NFTOrginalImage?.includes(
                                    ".webm"
                                  )))) && (
                                <Col className="">
                                  <Thumbcard
                                    key={1}
                                    value={
                                      typeof NFTFormValue?.NFTThumpImage ==
                                        "string"
                                        ? isEmpty(NFTFormValue?.NFTThumpImage)
                                          ? saleImg
                                          : `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT_THUMB/${NFTFormValue?.NFTThumpImage}`
                                        : URL.createObjectURL(
                                          NFTFormValue?.NFTThumpImage
                                        )
                                    }
                                  />
                                </Col>
                              )}
                            {/* ))} */}
                          </Row>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>{" "}
            {showCreateItem && isEmpty(ValidateError) && (
              <FollowSteps
                show={showCreateItem}
                functions={{
                  Nftimage: NFTFormValue.NFTOrginalImage,
                  CompressedFile: NFTFormValue?.CompressedFile,
                  approvecallshow: ApproveButton,
                  ipfscallshow: UploadButton,
                  mintcallshow: MintButton,
                  approvefunction: TokenApproveCall,
                  ipfscall: UploadIPFScall,
                  mintcallfunction: MintCall,
                  nfttype: location,
                }}
                handleClose={handleCloseCreateItem}
              />
            )}
            {showAddCollection && (
              <AddCollection
                show={showAddCollection}
                handleShow={handleShowAddCollection}
                handleClose={handleCloseAddCollection}
                project={NFTFormValue?.Project}
                Getcollection={() =>
                  CollectionByCreate({
                    Type: collection == 1 ? 721 : 1155,
                    Creator: accountAddress,
                    Project: NFTFormValue?.Project,
                    ChainId: String(currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
                  })
                }
              />
            )}
            {showCalender && (
              <Calender
                show={showCalender}
                handleClose={handleClosecalender}
                modal={OpenPopup}
                validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
                setClockValue={setClockValue}
                closeModal={() => setCalenderShow(false)}
              />
            )}
          </section>
        </>
      ) : (
        <section>
          <div className="innerpages createpage">
            <Innertitle title="Create" subtitle="" />
            <div className="container custom_container">
              <div className="row mt-5 justify-content-between">
                <div className="col-lg-8 col-xl-7 leftside">
                  <form>
                    <div className="inner_row mb-3">
                      <p className="label">Upload file</p>
                      <div className="uploadsec">
                        <p className="support">
                          png, jpg, gif, webp or mp4. Max 50mb.
                        </p>
                        <p className="support">
                          upload file in 280px * 300px for better experience
                        </p>

                        <div>
                          <button className="green_btn hover_btn">
                            Browse
                            <input
                              className=" cs_upld_inpt"
                              type="file"
                              id="NFTOrginalImage"
                              autoComplete="off"
                              onChange={(e) =>
                                inputonchange(null, e.target.files, "Orginal")
                              }
                            />
                          </button>
                        </div>
                        {ValidateError.NFTOrginalImage && (
                          <span className="text-danger img-file">
                            {ValidateError.NFTOrginalImage}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="inner_row mb-3">
                      <p className="label">Upload logo</p>
                      <div className="uploadsec">
                        <p className="support">
                          png, jpg, gif, webp. Max 50mb.
                        </p>

                        <div>
                          <button className="green_btn hover_btn">
                            Browse
                            <input
                              className=" cs_upld_inpt"
                              type="file"
                              id="NFTOrginalImage"
                              autoComplete="off"
                              onChange={(e) =>
                                inputonchange(null, e.target.files, "Logo")
                              }
                            />
                          </button>
                        </div>
                        {ValidateError.NFTLogoImage && (
                          <span className="text-danger img-file">
                            {ValidateError.NFTLogoImage}
                          </span>
                        )}
                      </div>
                    </div>
                    {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                      !NFTFormValue?.NFTOrginalImage?.type.includes(
                        "image"
                      ) && (
                        <div className="inner_row mb-3">
                          <p className="label">Upload Thump Image</p>
                          <div className="uploadsec">
                            <p className="support">png, jpg. Max 50mb.</p>
                            <p>
                              upload file in 280px * 300px for better experience
                            </p>

                            {/* <button className="btn primary_btn">Browse</button> */}

                            <div>
                              <button className="green_btn hover_btn">
                                Browse
                                <input
                                  className=" cs_upld_inpt"
                                  type="file"
                                  id="NFTThumpImage"
                                  autoComplete="off"
                                  onChange={(e) =>
                                    inputonchange(null, e.target.files, "Thump")
                                  }
                                />
                              </button>
                            </div>

                            {ValidateError?.NFTThumpImage && (
                              <span className="text-danger img-file">
                                {ValidateError?.NFTThumpImage}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                  </form>
                  <div>
                    <div className=" col-lg-4 col-xl-4 rightside mt-4 mt-lg-0  d-lg-none mb-4 d-flex flex-column align-items-center ">
                      <p className="label">Preview item</p>

                      <div className="">
                        <Nftcard
                          from={"Preview"}
                          product={NFTFormValue}
                          preview={NFTFormValue}
                          type="Preview"
                          index={1}
                          value={NFTFormValue}
                        />{" "}
                      </div>

                      {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                        !NFTFormValue?.NFTOrginalImage?.type.includes(
                          "image"
                        ) && (
                          <>
                            <p className="label mt-4">Preview Thumb Image</p>
                            <Row className="">
                              {/* {thumbCardData.map((value) => ( */}
                              {(!NFTFormValue?.NFTOrginalImage?.type?.includes(
                                "image"
                              ) ||
                                (typeof NFTFormValue?.NFTOrginalImage ==
                                  "string" &&
                                  (NFTFormValue?.NFTOrginalImage?.includes(
                                    ".mp3"
                                  ) ||
                                    NFTFormValue?.NFTOrginalImage?.includes(
                                      ".webm"
                                    )))) && (
                                  <Col className="">
                                    <Thumbcard
                                      key={1}
                                      value={
                                        typeof NFTFormValue?.NFTThumpImage ==
                                          "string"
                                          ? isEmpty(NFTFormValue?.NFTThumpImage)
                                            ? saleImg
                                            : `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT_THUMB/${NFTFormValue?.NFTThumpImage}`
                                          : URL.createObjectURL(
                                            NFTFormValue?.NFTThumpImage
                                          )
                                      }
                                    />
                                  </Col>
                                )}
                              {/* ))} */}
                            </Row>
                          </>
                        )}
                    </div>
                  </div>

                  <p className="label">Select method</p>
                  <div className="tab_cont">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="third"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills">
                            <div className="tab_row create_single d-flex align-items-center gap-3 pb-2">
                              <Nav.Item>
                                <Nav.Link eventKey="third">
                                  {" "}
                                  <div
                                    className="d-flex flex-column align-items-center py-3"
                                    onClick={() =>
                                      setNFTFormValue({
                                        ...NFTFormValue,
                                        ...{
                                          ["PutOnSaleType"]: "UnlimitedAuction",
                                        },
                                      })
                                    }
                                  >

                                    <img src={openbidicon} alt="bids" />

                                    <p className="method_type mb-0 mt-2">
                                      Open for bids
                                    </p>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                              {
                                <Nav.Item>
                                  <Nav.Link eventKey="first">
                                    <div
                                      className="d-flex flex-column align-items-center py-3"
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]: "FixedPrice",
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={fixedicon}
                                        alt="fixed price"
                                      />
                                      <p className="method_type mb-0 mt-2">
                                        Fixed Price
                                      </p>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              }
                              {NFTFormValue?.ContractType == 721 && (
                                <Nav.Item>
                                  <Nav.Link eventKey="second">
                                    {" "}
                                    <div
                                      className="d-flex flex-column align-items-center py-3"
                                      onClick={() =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["PutOnSaleType"]: "TimedAuction",
                                          },
                                        })
                                      }
                                    >
                                      <img
                                        src={timedicon}
                                        alt="time auction"
                                      />
                                      <p className="method_type mb-0 mt-2">
                                        Timed Auction
                                      </p>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              )}
                            </div>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content className="mt-4">
                            <Tab.Pane eventKey="first">
                              <div>
                                {/* <div className="inner_row mb-3">
                            <p className="label">Select method</p>
                            <div className="meth_row">
                              <div className="method_card">
                                <img src={fixedicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                              <div className="method_card">
                                <img src={timedicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                              <div className="method_card">
                                <img src={openbidicon} className="img-fluid" />
                                <p className="method_type">fixed price</p>
                              </div>
                            </div>
                          </div> */}
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Price<span className="text-danger">*</span>
                                  </p>
                                  <Row className="cust_inpt_slt mx-auto">
                                    <Col
                                      xs={7}
                                      sm={8}
                                      md={9}
                                      lg={8}
                                      xl={9}
                                      className="ps-0 pe-2 d-flex align-items-center"
                                    >
                                      <div class="form-group w-100">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="NFTPrice"
                                          onChange={inputonchange}
                                          value={NFTFormValue.NFTPrice}
                                          aria-describedby="emailHelp"
                                          placeholder="enter price for one items"
                                          autoComplete="off"
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xs={5}
                                      sm={4}
                                      md={3}
                                      lg={4}
                                      xl={3}
                                      className="px-0"
                                    >
                                      {" "}
                                      <Select
                                        styles={stylesgraybgTwo}
                                        onChange={(e) => {
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["CoinName"]: e.label },
                                          });
                                        }}
                                        value={{
                                          label: NFTFormValue.CoinName,
                                          value: NFTFormValue.CoinName,
                                        }}
                                        defaultValue={
                                          NFTFormValue.PutOnSaleType
                                            ? NFTFormValue.PutOnSaleType ==
                                              "FixedPrice"
                                              ? currency.filter(
                                                (item) => item.value !== currentNetwork?.COIN_NAME
                                              )[0]
                                              : ""
                                            : ""
                                        }
                                        options={
                                          // currency
                                          NFTFormValue.PutOnSaleType ==
                                            "FixedPrice"
                                            ? currency?.filter(
                                              (item) => item.deleted != true
                                            )
                                            : currency?.filter(
                                              (item) => item.label != currentNetwork?.COIN_NAME
                                            )
                                        }
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </Col>
                                  </Row>
                                  {/* <div class="form-group">

                                <input
                                  type="text"
                                  class="form-control custom_input"
                                  id="NFTPrice"
                                  onChange={inputonchange}
                                  value={NFTFormValue.NFTPrice}
                                  aria-describedby="emailHelp"
                                  placeholder="enter price for one item (BNB)"
                                />
                              </div> */}
                                  {/* <Select
                                styles={stylesgraybg}
                                onChange={(e) => {
                                  console.log("sfjgfjshfhsjf", e.label);
                                  setNFTFormValue({
                                    ...NFTFormValue,
                                    ...{ ["CoinName"]: e.label },
                                  });
                                }}
                                value={{
                                  label: NFTFormValue.CoinName,
                                  value: NFTFormValue.CoinName,
                                }}
                                defaultValue={
                                  NFTFormValue.PutOnSaleType
                                    ? NFTFormValue.PutOnSaleType == "FixedPrice"
                                      ? currency.filter(
                                        (item) => item.value !== "ETH"
                                      )[0]
                                      : ""
                                    : ""
                                }
                                options={
                                  NFTFormValue.PutOnSaleType == "FixedPrice"
                                    ? currency?.filter(
                                      (item) => item.deleted != true
                                    )
                                    : currency?.filter(
                                      (item) => item.label != "ETH"
                                    )
                                }
                                classNamePrefix="react-select"
                                isSearchable={false}
                              /> */}
                                  {ValidateError.NFTPrice && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTPrice}
                                    </span>
                                  )}
                                </div>
                                {/* unlock once purchased */}
                                {/* <Card className="tab_card mb-3 mt-4">
                                  <Card.Body className="p-4 ">
                                    <Row>
                                      <Col xs={8}>
                                        <div>
                                          <div className="d-flex align-items-start  lock_cont  gap-1">
                                            <img
                                              src={lock}
                                              alt="lock"
                                              className="img-fluid "
                                            />
                                            <h5 className="mb-0 heading tab_card_heads">
                                              Unlock once purchased
                                            </h5>
                                          </div>
                                          <p className="mb-0 mt-4 text_p">
                                            Unlock content after successful
                                            transaction.
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={4}
                                        className="d-flex justify-content-end"
                                      >
                                        Rounded switch
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={(e) => {
                                              setUnlock(!Unlock);
                                            }}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card> */}
                                {/* {Unlock && (
                                  <div className="inner_row mb-3">
                                    <p className="label">Unlcok Content</p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="UnlockContent"
                                        onChange={inputonchange}
                                        value={NFTFormValue.UnlockContent}
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.UnlockContent && (
                                      <span className="text-danger img-file">
                                        {ValidateError.UnlockContent}
                                      </span>
                                    )}
                                  </div>
                                )} */}

                                {!NFTFormValue.Project &&
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Type<span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                          value:
                                            NFTFormValue?.ContractType == 721
                                              ? "Single"
                                              : "Multiple",
                                        }}
                                        onChange={(e) => setCollection(e.value)}
                                        options={[
                                          { value: 1, label: "Single" },
                                          { value: 2, label: "Multiple" },
                                        ]}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        isDisabled={NFTFormValue.Project}

                                      // isDisabled={true}
                                      />
                                    </div>
                                  </div>}

                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose collection
                                    <span className="text-danger">*</span>
                                  </p>
                                  {/* <Row className="mx-auto">
                                    <Col xs={8} className="ps-0">
                                      <div>
                                        <h5 className="heading mb-0">
                                          {" "}
                                          Project
                                        </h5>
                                        <p>
                                          If you Want Choose Project for Staking
                                          Your NFT
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={4}
                                      className="pe-4 d-flex justify-content-end"
                                    >
                                      {" "}
                                      <label class="switch tab_toggle">
                                        <input
                                          type="checkbox"
                                          onChange={handleCheckboxActive}
                                          checked={NFTFormValue?.Project}
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                    </Col>
                                  </Row> */}

                                  <div class="form-group custom_choose_collec">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      onChange={(e) => {
                                        ChooseCollection(e);
                                      }}
                                      options={CreateCollectionState}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      value={
                                        NFTFormValue?.CollectionName ?
                                          {
                                            value: NFTFormValue?.CollectionName,
                                            label: NFTFormValue?.CollectionName,
                                          } :
                                          {
                                            label: "Select Collection",
                                            value: "Select Collection",
                                          }
                                      }
                                    />
                                  </div>
                                  {ValidateError.CollectionSymbol && (
                                    <span className="text-danger img-file">
                                      {ValidateError.CollectionSymbol}
                                    </span>
                                  )}
                                </div>
                                <Row>
                                  <Col>
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Starting Date
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div className=" position-relative date_picker ">
                                        <Select
                                          className="react_slt rem_drp_dwn_icn"
                                          styles={stylesgraybg}
                                          value={{
                                            value: NFTFormValue.ClockTime,
                                            label:
                                              NFTFormValue.ClockTime == ""
                                                ? NFTFormValue.ClockTime
                                                : moment(
                                                  NFTFormValue.ClockTime
                                                ).format(
                                                  "YYYY-MM-DD h:mm:ss a"
                                                ),
                                          }}
                                          onChange={(e) =>
                                            DateSelection(e, "start")
                                          }
                                          options={startingdate}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />

                                        {ValidateError.ClockTime && (
                                          <span className="text-danger img-file">
                                            {ValidateError.ClockTime}
                                          </span>
                                        )}
                                        {/* {currTheme == "light" ? (
                                          <img
                                            src={dateImg}
                                            alt="date-choosen"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        ) : (
                                          <img
                                            src={datewhite}
                                            alt="date"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        )} */}
                                        {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div>
                                      <p className="label">
                                        Ending Date
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div className=" position-relative date_picker ">
                                        <Select
                                          className="react_slt rem_drp_dwn_icn"
                                          styles={stylesgraybg}
                                          value={{
                                            value: NFTFormValue.EndClockTime,
                                            label:
                                              NFTFormValue.EndClockTime == ""
                                                ? NFTFormValue.EndClockTime
                                                : moment(
                                                  NFTFormValue.EndClockTime
                                                ).format(
                                                  "YYYY-MM-DD h:mm:ss a"
                                                ),
                                          }}
                                          onChange={(e) =>
                                            DateSelection(e, "end")
                                          }
                                          options={enddate}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />
                                        {ValidateError.EndClockTime && (
                                          <span className="text-danger img-file">
                                            {ValidateError.EndClockTime}
                                          </span>
                                        )}
                                        {/* {currTheme == "light" ? (
                                          <img
                                            src={dateImg}
                                            alt="date-choosen"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        ) : (
                                          <img
                                            src={datewhite}
                                            alt="date"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        )} */}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                {/* {!NFTFormValue?.Project&&   <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose Category
                                    <span className="text-danger">*</span>
                                  </p>
                                  <p className="text_p">
                                    This is the category where your item will
                                    appear.
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      isDisabled={
                                        NFTFormValue.CollectionSymbol
                                          ? true
                                          : false
                                      }
                                      value={{
                                        label: NFTFormValue?.Category,
                                        value: NFTFormValue?.Category,
                                      }}
                                      // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                                      onChange={(e) =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{ ["Category"]: e.label },
                                        })
                                      }
                                      options={Categorys}
                                      classNamePrefix="react-select"
                                      isSearchable={false}
                                      className="react_slt select_dropdown_modal"
                                      placeholder={"Select....."}
                                    />
                                  </div>
                                  {ValidateError.Category && (
                                    <span className="text-danger img-file">
                                      {ValidateError.Category}
                                    </span>
                                  )}
                                </div>} */}
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Title <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={inputonchange}
                                      id="NFTName"
                                      value={NFTFormValue.NFTName}
                                      aria-describedby="emailHelp"
                                      placeholder="e.g. `Cripto Funk"
                                    />
                                  </div>
                                  {ValidateError.NFTName && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTName}
                                    </span>
                                  )}
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">Description</p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={inputonchange}
                                      id="NFTDescription"
                                      value={NFTFormValue.NFTDescription}
                                      aria-describedby="emailHelp"
                                      placeholder="e.g. `This is very limited item`"
                                    />
                                  </div>
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Royalties
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="NFTRoyalty"
                                      value={NFTFormValue.NFTRoyalty}
                                      onChange={inputonchange}
                                      aria-describedby="emailHelp"
                                      placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                    />
                                    {ValidateError.NFTRoyalty && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTRoyalty}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                {NFTFormValue?.Project && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Pictures Royalties
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTPictureRoyalty"
                                        value={NFTFormValue.NFTPictureRoyalty}
                                        onChange={inputonchange}
                                        aria-describedby="emailHelp"
                                        placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                      />
                                    </div>

                                    {ValidateError.NFTPictureRoyalty && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPictureRoyalty}
                                      </span>
                                    )}
                                  </div>
                                )}
                                <p className="label">
                                  Block Chain
                                  <span className="text-danger">*</span>
                                </p>
                                <div class="form-group">
                                  <Select
                                    styles={stylesgraybg}
                                    className="react_slt select_dropdown_modal"
                                    value={currentNetwork}
                                    onChange={(e) => ChangeNetwork(e)}
                                    options={Object.values(network)}
                                    classNamePrefix="react-select-one"
                                    isSearchable={false}
                                  />
                                </div>{" "}
                                {!NFTFormValue?.Project && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Token Burn Status
                                      <span className="text-danger">*</span>
                                    </p>

                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                          value:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                        }}
                                        onChange={(e) => (
                                          SetValidateError({}),
                                          SetFormButton("start"),
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["Burnable"]: e.value },
                                          })
                                        )}
                                        options={[
                                          { value: true, label: "Burnable" },
                                          { value: false, label: "Unburnable" },
                                        ]}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                )}
                                {NFTFormValue?.ContractType !== 721 && (
                                  <div>
                                    <p className="label">
                                      No Of Copies
                                      <span className="text-danger">*</span>
                                    </p>
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={(e) => {
                                        if (
                                          e.target.value == "" ||
                                          config.NumOnly.test(e.target.value)
                                        ) {
                                          inputonchange(e);
                                        }
                                      }}
                                      id="NFTQuantity"
                                      value={NFTFormValue?.NFTQuantity}
                                      placeholder="No of Copy"
                                      autoComplete="off"
                                    />
                                  </div>
                                )}
                                {ValidateError.NFTQuantity && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTQuantity}
                                  </span>
                                )}{" "}
                                {NFTFormValue?.Project &&
                                  NFTFormValue?.CollectionDocuments && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Documents
                                        <span className="text-danger">*</span>
                                      </p>
                                      <p className="mb-3 text_p">
                                        If You want to know more about this
                                        project please Click here...!
                                      </p>
                                      <button className="green_btn hover_btn a_btn mt-4">
                                        <a
                                          href={`${config.IMG_URL}/collection/documents/${NFTFormValue?.CollectionSymbol}/${NFTFormValue?.CollectionDocuments}`}
                                          download
                                          target="_blank"
                                          className="text-decoration-none"
                                        >
                                          view pdf
                                        </a>
                                      </button>
                                    </div>
                                  )}
                                {NFTFormValue?.Project && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Staking pool
                                      <span className="text-danger">*</span>
                                    </p>
                                    {/* <p className="text_p">
  This is the category where your item will
  appear.
</p> */}
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        onChange={(e) => Sessonselect(e)}
                                        options={options}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        className="react_slt select_dropdown_modal"
                                        placeholder={"Select....."}
                                        value={season}
                                      />
                                    </div>
                                    {ValidateError?.season && (
                                      <span className="text-danger img-file">
                                        {ValidateError?.season}
                                      </span>
                                    )}
                                  </div>
                                )}
                                <div className="inner_row mb-3">
                                  {" "}
                                  {/* <div>
                                    <p className="label mb-0">
                                      Properties1
                                    </p>

                                    <div className="cs_prp_wrp d-flex align-items-center gap-3 py-3">
                                      {number?.map((value, index) => (
                                        <>
                                          {number?.length - 2 >= index && (
                                            <div className="d-flex align-items-center justify-content-between gap-3 cs_prp_crd p-2">
                                              <div className="d-flex align-items-center">
                                                <p className="mb-0 pe-1">
                                                  {key[index]}
                                                </p>{" "}
                                                :
                                                <p className="mb-0 ps-1">
                                                  {Value[index]}
                                                </p>
                                              </div>

                                              <i
                                                class="fa-solid fa-x cs_prp_cls"
                                                onClick={() =>
                                                  RemoveProperties(index)
                                                }
                                              ></i>
                                            </div>
                                          )}
                                        </>
                                      ))}
                                    </div>
                                  </div>{" "} */}
                                </div>{" "}
                                {/* <div className="inner_row mb-3">
                                  <Row>
                                    <Col sm={4}>
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Traits
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="key"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Traits"
                                            value={key[number.length - 1] || ""}
                                          />
                                        </div>

                                        {ValidateError.key && (
                                          <span className="text-danger img-file">
                                            {ValidateError.key}
                                          </span>
                                        )}
                                      </div>
                                    </Col>
                                    <Col sm={4} className="mt-3 mt-sm-0">
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Size
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="value"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Size"
                                            value={
                                              Value[number.length - 1] || ""
                                            }
                                          />
                                          {ValidateError.value && (
                                            <span className="text-danger img-file">
                                              {ValidateError.value}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={4}
                                      className="d-flex align-items-end mt-4 mt-sm-0"
                                    >
                                      {" "}
                                      <Button
                                        className="green_btn hover_btn card_btn btn_hgt"
                                        onClick={() => AddProperties()}
                                      >
                                        Add More
                                      </Button>
                                    </Col>
                               
                                  </Row>
                                </div>{" "} */}
                                <Button
                                  className="green_btn hover_btn card_btn  mt-4 "
                                  disabled={
                                    FormButton == "error" ||
                                      FormButton == "done" ||
                                      FormButton == "process"
                                      ? true
                                      : false
                                  }
                                  onClick={
                                    FormButton == "start" ? FormSubmit : null
                                  }
                                >
                                  {FormButton == "start" && "Publish"}
                                  {FormButton == "error" && "Fix-Error"}
                                  {FormButton == "process" && "processing"}
                                  {FormButton == "done" && "Done"}
                                </Button>
                                {/* <Button
                              onClick={() => {
                                handleShowAddCollection();
                              }}
                              className="green_btn hover_btn  mt-4 ms-3 green_btn_sm_mb"
                            >
                              Add Collection
                            </Button> */}
                              </div>
                            </Tab.Pane>

                            {/* ---------------Timed Auction------------------------- */}
                            <Tab.Pane eventKey="second">
                              {" "}
                              <div>
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Minimum Bid
                                    <span className="text-danger">*</span>
                                  </p>
                                  <Row className="cust_inpt_slt mx-auto">
                                    <Col
                                      xs={7}
                                      sm={8}
                                      md={9}
                                      lg={8}
                                      xl={9}
                                      className="ps-0 pe-2 d-flex align-items-center"
                                    >
                                      <div class="form-group w-100">
                                        <input
                                          type="text"
                                          class="form-control custom_input"
                                          id="NFTMinimumBid"
                                          value={NFTFormValue.NFTMinimumBid}
                                          onChange={inputonchange}
                                          aria-describedby="emailHelp"
                                          placeholder="Enter minimum bid"
                                        />
                                      </div>
                                    </Col>
                                    <Col
                                      xs={5}
                                      sm={4}
                                      md={3}
                                      lg={4}
                                      xl={3}
                                      className="px-0"
                                    >
                                      <Select
                                        styles={stylesgraybgTwo}
                                        onChange={(e) => {
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["CoinName"]: e.label },
                                          });
                                        }}
                                        value={{
                                          label: NFTFormValue.CoinName,
                                          value: NFTFormValue.CoinName,
                                        }}
                                        defaultValue={
                                          NFTFormValue.PutOnSaleType
                                            ? NFTFormValue.PutOnSaleType ==
                                              "FixedPrice"
                                              ? currency.filter(
                                                (item) => item.value !== currentNetwork?.COIN_NAME
                                              )[0]
                                              : ""
                                            : ""
                                        }
                                        options={
                                          NFTFormValue.PutOnSaleType ==
                                            "FixedPrice"
                                            ? currency?.filter(
                                              (item) => item.deleted != true
                                            )
                                            : currency?.filter(
                                              (item) =>
                                                !item.address.includes(
                                                  currentNetwork?.COIN_NAME
                                                )
                                            )
                                        }
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </Col>
                                  </Row>
                                </div>
                                {console.log(
                                  "ValidateErrorValidateError",
                                  ValidateError
                                )}{" "}
                                {ValidateError.NFTMinimumBid && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTMinimumBid}
                                  </span>
                                )}
                                {/* </div> */}
                                {/* <Card className="tab_card mb-3 mt-4">
                                  <Card.Body className="p-4 ">
                                    <Row>
                                      <Col xs={8}>
                                        <div>
                                          <div className="d-flex  lock_cont  gap-1">
                                            <img
                                              src={lock}
                                              alt="lock"
                                              className="img-fluid "
                                            />
                                            <h5 className="mb-0 heading tab_card_heads">
                                              Unlock once purchased
                                            </h5>
                                          </div>
                                          <p className="mb-0 mt-4 text_p">
                                            Unlock content after successful
                                            transaction.
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={4}
                                        className="d-flex justify-content-end"
                                      >
                                        Rounded switch
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={(e) => {
                                              setUnlock(!Unlock);
                                            }}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card> */}
                                {/* unlock once purchased */}
                                {/* {Unlock && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Unlcok Content
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="UnlockContent"
                                        onChange={inputonchange}
                                        value={NFTFormValue.UnlockContent}
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.UnlockContent && (
                                      <span className="text-danger img-file">
                                        {ValidateError.UnlockContent}
                                      </span>
                                    )}
                                  </div>
                                )} */}

                                {!NFTFormValue.Project && <div className="inner_row mb-3">
                                  <p className="label">
                                    Type<span className="text-danger">*</span>
                                  </p>

                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={{
                                        label:
                                          NFTFormValue?.ContractType == 721
                                            ? "Single"
                                            : "Multiple",
                                        value:
                                          NFTFormValue?.ContractType == 721
                                            ? "Single"
                                            : "Multiple",
                                      }}
                                      onChange={(e) => setCollection(e.value)}
                                      options={[
                                        { value: 1, label: "Single" },
                                        { value: 2, label: "Multiple" },
                                      ]}

                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      isDisabled={NFTFormValue.Project}
                                    />
                                  </div>
                                </div>}

                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose collection
                                    <span className="text-danger">*</span>
                                  </p>
                                  {/* <Row className="mx-auto">
                                <Col xs={9} className="ps-0">
                                  <div>
                                    <h5 className="heading mb-0"> Project</h5>
                                    <p>
                                      If you Want Choose Project for Staking
                                      Your NFT
                                    </p>
                                  </div>
                                </Col>
                                <Col xs={3} className="pe-0">
                                  {" "}
                                  <label class="switch tab_toggle">
                                    <input
                                      type="checkbox"
                                      onChange={handleCheckboxActive}
                                      checked={NFTFormValue?.Project}
                                    />
                                    <span class="slider round"></span>
                                  </label>
                                </Col>
                              </Row> */}

                                  <div class="form-group custom_choose_collec">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      onChange={(e) => {
                                        ChooseCollection(e);
                                      }}
                                      options={CreateCollectionState}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      value={
                                        NFTFormValue?.CollectionName ?
                                          {
                                            value: NFTFormValue?.CollectionName,
                                            label: NFTFormValue?.CollectionName,
                                          } :
                                          {
                                            label: "Select Collection",
                                            value: "Select Collection",
                                          }
                                      }
                                    />
                                  </div>
                                  {ValidateError.CollectionSymbol && (
                                    <span className="text-danger img-file">
                                      {ValidateError.CollectionSymbol}
                                    </span>
                                  )}
                                </div>
                                <Row>
                                  <Col>
                                    <div>
                                      <p className="label">
                                        Starting Date
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div className=" position-relative date_picker  ">
                                        <Select
                                          value={{
                                            value: NFTFormValue.ClockTime,
                                            label:
                                              NFTFormValue.ClockTime == ""
                                                ? NFTFormValue.ClockTime
                                                : moment(
                                                  NFTFormValue.ClockTime
                                                ).format(
                                                  "YYYY-MM-DD h:mm:ss a"
                                                ),
                                          }}
                                          onChange={(e) =>
                                            DateSelection(e, "start")
                                          }
                                          className="react_slt rem_drp_dwn_icn"
                                          styles={stylesgraybg}
                                          options={startingdate}
                                          classNamePrefix="react-select-one"
                                          isSearchable={false}
                                        />

                                        {ValidateError.ClockTime && (
                                          <span className="text-danger img-file">
                                            {ValidateError.ClockTime}
                                          </span>
                                        )}
                                        {/* {currTheme == "light" ? (
                                          <img
                                            src={dateImg}
                                            alt="date-choosen"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        ) : (
                                          <img
                                            src={datewhite}
                                            alt="date"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        )} */}
                                        {/* <img
                                      src={dateImg}
                                      alt="date"
                                      className="img-fluid position-absolute date_icon"
                                    /> */}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div>
                                      <p className="label">
                                        Ending Date
                                        <span className="text-danger">*</span>
                                      </p>
                                      <div className=" position-relative date_picker  ">
                                        <Select
                                          value={{
                                            value: NFTFormValue.EndClockTime,
                                            label:
                                              NFTFormValue.EndClockTime == ""
                                                ? NFTFormValue.EndClockTime
                                                : moment(
                                                  NFTFormValue.EndClockTime
                                                ).format(
                                                  "YYYY-MM-DD h:mm:ss a"
                                                ),
                                          }}
                                          onChange={(e) =>
                                            DateSelection(e, "end")
                                          }
                                          options={enddate}
                                          styles={stylesgraybg}
                                          classNamePrefix="react-select-one"
                                          className="react_slt rem_drp_dwn_icn"
                                          isSearchable={false}
                                        />
                                        {ValidateError.EndClockTime && (
                                          <span className="text-danger img-file">
                                            {ValidateError.EndClockTime}
                                          </span>
                                        )}
                                        {/* {currTheme == "light" ? (
                                          <img
                                            src={dateImg}
                                            alt="date-choosen"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        ) : (
                                          <img
                                            src={datewhite}
                                            alt="date"
                                            className="img-fluid position-absolute date_icon"
                                          />
                                        )} */}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                {/* {     !NFTFormValue?.Project&&
                           <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose Category
                                    <span className="text-danger">*</span>
                                  </p>
                                  <p className="text_p">
                                    This is the category where your item will
                                    appear.
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      isDisabled={
                                        NFTFormValue.CollectionSymbol
                                          ? true
                                          : false
                                      }
                                      value={{
                                        label: NFTFormValue?.Category,
                                        value: NFTFormValue?.Category,
                                      }}
                                      placeholder={"Select....."}
                                      // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                                      onChange={(e) =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{ ["Category"]: e.label },
                                        })
                                      }
                                      options={Categorys}
                                      classNamePrefix="react-select"
                                      isSearchable={false}
                                      className="react_slt select_dropdown_modal"
                                    />
                                  </div>
                                  {ValidateError.Category && (
                                    <span className="text-danger img-file">
                                      {ValidateError.Category}
                                    </span>
                                  )}
                                </div>} */}
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Title
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="NFTName"
                                      onChange={inputonchange}
                                      value={NFTFormValue.NFTName}
                                      placeholder="e.g. `Cripto Funk"
                                    />
                                  </div>
                                  {ValidateError.NFTName && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTName}
                                    </span>
                                  )}
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">Description</p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={inputonchange}
                                      id="NFTDescription"
                                      value={NFTFormValue.NFTDescription}
                                      placeholder="e.g. `This is very limited item`"
                                    />
                                  </div>
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Royalties
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="NFTRoyalty"
                                      value={NFTFormValue.NFTRoyalty}
                                      onChange={inputonchange}
                                      aria-describedby="emailHelp"
                                      placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                    />
                                  </div>

                                  {ValidateError.NFTRoyalty && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTRoyalty}
                                    </span>
                                  )}
                                </div>

                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Block Chain
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={currentNetwork}
                                      onChange={(e) => ChangeNetwork(e)}
                                      options={Object.values(network)}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                    />
                                  </div>{" "}
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Token Burn Status
                                    <span className="text-danger">*</span>
                                  </p>

                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={{
                                        label:
                                          NFTFormValue?.Burnable == true
                                            ? "Burnable"
                                            : "Unburnable",
                                        value:
                                          NFTFormValue?.Burnable == true
                                            ? "Burnable"
                                            : "Unburnable",
                                      }}
                                      onChange={(e) => (
                                        SetValidateError({}),
                                        SetFormButton("start"),
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{ ["Burnable"]: e.value },
                                        })
                                      )}
                                      options={[
                                        { value: true, label: "Burnable" },
                                        { value: false, label: "Unburnable" },
                                      ]}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                    />
                                  </div>
                                </div>
                                {NFTFormValue?.ContractType !== 721 && (
                                  <div>
                                    <p className="label">
                                      No of Copies
                                      <span className="text-danger">*</span>
                                    </p>

                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={(e) => {
                                        if (
                                          e.target.value == "" ||
                                          config.NumOnly.test(e.target.value)
                                        ) {
                                          inputonchange(e);
                                        }
                                      }}
                                      id="NFTQuantity"
                                      value={NFTFormValue?.NFTQuantity}
                                      placeholder="No of Copy"
                                      autoComplete="off"
                                    />
                                  </div>
                                )}
                                {ValidateError.NFTQuantity && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTQuantity}
                                  </span>
                                )}{" "}
                                <div className="inner_row mb-3">
                                  {" "}
                                  {/* <div>
                                    <p className="label mb-0">
                                      Properties2
                                      <span className="text-danger">*</span>
                                    </p>

                                    <div className="cs_prp_wrp d-flex align-items-center gap-3 py-3">
                                      {number.map((value, index) => (
                                        <>
                                          {number.length - 2 >= index && (
                                            <div className="d-flex align-items-center justify-content-between gap-3 cs_prp_crd p-2">
                                              <div className="d-flex align-items-center">
                                                <p className="mb-0 pe-1">
                                                  {key[index]}
                                                </p>{" "}
                                                :
                                                <p className="mb-0 ps-1">
                                                  {Value[index]}
                                                </p>
                                              </div>

                                              <i
                                                class="fa-solid fa-x cs_prp_cls"
                                                onClick={() =>
                                                  RemoveProperties(index)
                                                }
                                              ></i>
                                            </div>
                                          )}
                                        </>
                                        // )}
                                      ))}
                                    </div>
                                  </div>{" "} */}
                                </div>{" "}
                                {/* <div className="inner_row mb-3">
                                  <Row>
                                    <Col sm={4}>
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Traits
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="key"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Traits"
                                            value={key[number.length - 1] || ""}
                                          />

                                          {ValidateError.key && (
                                            <span className="text-danger img-file">
                                              {ValidateError.key}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={4} className="mt-3 mt-sm-0">
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Size
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="value"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Size"
                                            value={
                                              Value[number.length - 1] || ""
                                            }
                                          />
                                          {ValidateError.value && (
                                            <span className="text-danger img-file">
                                              {ValidateError.value}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={4}
                                      className="d-flex align-items-end mt-4 mt-sm-0"
                                    >
                                      {" "}
                                      <Button
                                        className="green_btn hover_btn card_btn btn_hgt"
                                        onClick={() => AddProperties()}
                                      >
                                        Add More
                                      </Button>
                                    </Col>
                          
                                  </Row>
                                </div>{" "} */}
                                <Button
                                  className="green_btn hover_btn card_btn  mt-4 "
                                  disabled={
                                    FormButton == "error" ||
                                      FormButton == "done" ||
                                      FormButton == "process"
                                      ? true
                                      : false
                                  }
                                  onClick={
                                    FormButton == "start" ? FormSubmit : null
                                  }
                                >
                                  {FormButton == "start" && "Publish"}
                                  {FormButton == "error" && "Fix-Error"}
                                  {FormButton == "process" && "processing"}
                                  {FormButton == "done" && "Done"}
                                </Button>
                                {/* <Button
                              onClick={() => {
                                handleShowAddCollection();
                              }}
                              className="green_btn hover_btn  mt-4 ms-3 green_btn_sm_mb"
                            >
                              Add Collection
                            </Button> */}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                              {" "}
                              <div>
                                {/* <div className="inner_row mb-3">
                              <p className="label">Price</p>
                              <div class="form-group">
                                <input
                                  type="text"
                                  class="form-control custom_input"
                                  id="NFTPrice"
                                  onChange={inputonchange}
                                  value={NFTFormValue.NFTPrice}
                                  aria-describedby="emailHelp"
                                  placeholder="enter price for one item (ETH)"
                                />
                                
                                  {ValidateError.NFTPrice && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPrice}
                                      </span>
                                    )}
                              </div>
                            </div> */}
                                {/* unlock once purchased */}
                                {/* <Card className="tab_card mb-3 mt-4">
                                  <Card.Body className="p-4 ">
                                    <Row>
                                      <Col xs={8} sm={10}>
                                        <div>
                                          <div className="d-flex  lock_cont  gap-1">
                                            <img
                                              src={lock}
                                              alt="lock"
                                              className="img-fluid "
                                            />
                                            <h5 className="mb-0 heading tab_card_heads">
                                              Unlock once purchased
                                            </h5>
                                          </div>
                                          <p className="mb-0 mt-4 text_p">
                                            Unlock content after successful
                                            transaction.
                                          </p>
                                        </div>
                                      </Col>
                                      <Col
                                        xs={4}
                                        sm={2}
                                        className="d-flex justify-content-end"
                                      >
                                        Rounded switch
                                        <label class="switch tab_toggle">
                                          <input
                                            type="checkbox"
                                            onChange={(e) => {
                                              setUnlock(!Unlock);
                                            }}
                                          />
                                          <span class="slider round"></span>
                                        </label>
                                      </Col>
                                    </Row>
                                  </Card.Body>
                                </Card> */}
                                {/* {Unlock && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Unlock Content
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="UnlockContent"
                                        onChange={inputonchange}
                                        value={NFTFormValue.UnlockContent}
                                        placeholder="e.g. `Cripto Funk"
                                      />
                                    </div>
                                    {ValidateError.UnlockContent && (
                                      <span className="text-danger img-file">
                                        {ValidateError.UnlockContent}
                                      </span>
                                    )}
                                  </div>
                                )} */}

                                {!NFTFormValue.Project && <div className="inner_row mb-3">
                                  <p className="label">
                                    Type <span className="text-danger">*</span>
                                  </p>

                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={{
                                        label:
                                          NFTFormValue?.ContractType == 721
                                            ? "Single"
                                            : "Multiple",
                                        value:
                                          NFTFormValue?.ContractType == 721
                                            ? "Single"
                                            : "Multiple",
                                      }}
                                      onChange={(e) => (
                                        setCollection(e.value),
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{
                                            ["CollectionName"]: "",
                                            ["CollectionSymbol"]: "",
                                          },
                                        })
                                      )}
                                      options={[
                                        { value: 1, label: "Single" },
                                        { value: 2, label: "Multiple" },
                                      ]}
                                      isDisabled={NFTFormValue.Project}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                    // isDisabled={true}
                                    />
                                  </div>
                                </div>}

                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose collection
                                    <span className="text-danger">*</span>
                                  </p>
                                  {/* <Row className="mx-auto">
                                    <Col xs={8} className="ps-0">
                                      <div>
                                        <h5 className="heading mb-0">
                                          {" "}
                                          Project
                                        </h5>
                                        <p>
                                          If you Want Choose Project for Staking
                                          Your NFT
                                        </p>
                                      </div>
                                    </Col>
                                    <Col
                                      xs={4}
                                      className="pe-4 d-flex justify-content-end"
                                    >
                                      {" "}
                                      <label class="switch tab_toggle">
                                        <input
                                          type="checkbox"
                                          onChange={handleCheckboxActive}
                                          checked={NFTFormValue?.Project}
                                        />
                                        <span class="slider round"></span>
                                      </label>
                                    </Col>
                                  </Row> */}

                                  {/* <p className="text_p">
                                This is the collection where your item will
                                appear.
                              </p> */}
                                  <div class="form-group custom_choose_collec">
                                    <Select
                                      // menuIsOpen={true}
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      onChange={(e) => {
                                        ChooseCollection(e);
                                      }}
                                      options={CreateCollectionState}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                      value={
                                        NFTFormValue?.CollectionName ?
                                          {
                                            value: NFTFormValue?.CollectionName,
                                            label: NFTFormValue?.CollectionName,
                                          } :
                                          {
                                            label: "Select Collection",
                                            value: "Select Collection",
                                          }
                                      }
                                    // defaultMenuIsOpen
                                    />
                                  </div>
                                  {ValidateError.CollectionSymbol && (
                                    <span className="text-danger img-file">
                                      {ValidateError.CollectionSymbol}
                                    </span>
                                  )}
                                </div>
                                {/* { !NFTFormValue?.Project
                         &&     
                           <div className="inner_row mb-3">
                                  <p className="label">
                                    Choose Category
                                    <span className="text-danger">*</span>
                                  </p>
                                  <p className="text_p">
                                    This is the category where your item will
                                    appear.
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      isDisabled={
                                        NFTFormValue.CollectionSymbol
                                          ? true
                                          : false
                                      }
                                      value={{
                                        label: NFTFormValue?.Category,
                                        value: NFTFormValue?.Category,
                                      }}
                                      // defaultValue={NFTFormValue.PutOnSaleType == "FixedPrice" ?  currency[0] : currency[1]}
                                      onChange={(e) =>
                                        setNFTFormValue({
                                          ...NFTFormValue,
                                          ...{ ["Category"]: e.label },
                                        })
                                      }
                                      placeholder={"Select....."}
                                      options={Categorys}
                                      classNamePrefix="react-select"
                                      isSearchable={false}
                                      className="react_slt select_dropdown_modal"
                                    />
                                  </div>
                                  {ValidateError.Category && (
                                    <span className="text-danger img-file">
                                      {ValidateError.Category}
                                    </span>
                                  )}
                                </div>} */}
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Title
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="NFTName"
                                      onChange={inputonchange}
                                      value={NFTFormValue.NFTName}
                                      placeholder="e.g. `Cripto Funk"
                                    />
                                  </div>
                                  {ValidateError.NFTName && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTName}
                                    </span>
                                  )}
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">Description</p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="e.g. `This is very limited item`"
                                    />
                                  </div>
                                </div>
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Royalties
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      id="NFTRoyalty"
                                      value={NFTFormValue.NFTRoyalty}
                                      onChange={inputonchange}
                                      aria-describedby="emailHelp"
                                      placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                    />
                                  </div>

                                  {ValidateError.NFTRoyalty && (
                                    <span className="text-danger img-file">
                                      {ValidateError.NFTRoyalty}
                                    </span>
                                  )}
                                </div>{" "}
                                {NFTFormValue?.Project && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Pictures Royalties
                                      <span className="text-danger">*</span>
                                    </p>
                                    <div class="form-group">
                                      <input
                                        type="text"
                                        class="form-control custom_input"
                                        id="NFTPictureRoyalty"
                                        value={NFTFormValue.NFTPictureRoyalty}
                                        onChange={inputonchange}
                                        aria-describedby="emailHelp"
                                        placeholder="suggested: 0, 10%, 20%. Maximum is 20%"
                                      />
                                    </div>

                                    {ValidateError.NFTPictureRoyalty && (
                                      <span className="text-danger img-file">
                                        {ValidateError.NFTPictureRoyalty}
                                      </span>
                                    )}
                                  </div>
                                )}
                                <div className="inner_row mb-3">
                                  <p className="label">
                                    Block Chain
                                    <span className="text-danger">*</span>
                                  </p>
                                  <div class="form-group">
                                    <Select
                                      styles={stylesgraybg}
                                      className="react_slt select_dropdown_modal"
                                      value={currentNetwork}
                                      onChange={(e) => ChangeNetwork(e)}
                                      options={Object.values(network)}
                                      classNamePrefix="react-select-one"
                                      isSearchable={false}
                                    />
                                  </div>{" "}
                                </div>
                                {!NFTFormValue?.Project && (
                                  <div className="inner_row mb-3 ">
                                    <p className="label">
                                      Token Burn Status
                                      <span className="text-danger">*</span>
                                    </p>

                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        className="react_slt select_dropdown_modal"
                                        value={{
                                          label:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                          value:
                                            NFTFormValue?.Burnable == true
                                              ? "Burnable"
                                              : "Unburnable",
                                        }}
                                        onChange={(e) => (
                                          SetValidateError({}),
                                          SetFormButton("start"),
                                          setNFTFormValue({
                                            ...NFTFormValue,
                                            ...{ ["Burnable"]: e.value },
                                          })
                                        )}
                                        options={[
                                          { value: true, label: "Burnable" },
                                          { value: false, label: "Unburnable" },
                                        ]}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                      />
                                    </div>
                                  </div>
                                )}
                                {NFTFormValue?.ContractType !== 721 && (
                                  <div className="inner_row mb-3 ">
                                    <p className="label">
                                      No Of Copies
                                      <span className="text-danger">*</span>
                                    </p>
                                    <input
                                      type="text"
                                      class="form-control custom_input"
                                      onChange={(e) => {
                                        if (
                                          e.target.value == "" ||
                                          config.NumOnly.test(e.target.value)
                                        ) {
                                          inputonchange(e);
                                        }
                                      }}
                                      id="NFTQuantity"
                                      value={NFTFormValue?.NFTQuantity}
                                      placeholder="No of Copy"
                                      autoComplete="off"
                                    />
                                  </div>
                                )}
                                {ValidateError.NFTQuantity && (
                                  <span className="text-danger img-file">
                                    {ValidateError.NFTQuantity}
                                  </span>
                                )}{" "}
                                {NFTFormValue?.Project &&
                                  NFTFormValue?.CollectionDocuments && (
                                    <div className="inner_row mb-3">
                                      <p className="label">
                                        Documents
                                        <span className="text-danger">*</span>
                                      </p>
                                      <p className="mb-3 text_p">
                                        If You want to know more about this
                                        project please Click here...!
                                      </p>
                                      <button className="green_btn hover_btn a_btn mt-4">
                                        <a
                                          href={`${config.IMG_URL}/collection/documents/${NFTFormValue?.CollectionSymbol}/${NFTFormValue?.CollectionDocuments}`}
                                          download
                                          target="_blank"
                                          className="text-decoration-none"
                                        >
                                          view pdf
                                        </a>
                                      </button>
                                    </div>
                                  )}
                                {NFTFormValue?.Project && (
                                  <div className="inner_row mb-3">
                                    <p className="label">
                                      Staking pool
                                      <span className="text-danger">*</span>
                                    </p>
                                    {/* <p className="text_p">
                                This is the category where your item will
                                appear.
                              </p> */}
                                    <div class="form-group">
                                      <Select
                                        styles={stylesgraybg}
                                        onChange={(e) => Sessonselect(e)}
                                        options={options}
                                        classNamePrefix="react-select-one"
                                        isSearchable={false}
                                        className="react_slt select_dropdown_modal"
                                        placeholder={"Select....."}
                                        value={season}
                                      />
                                    </div>
                                    {ValidateError?.season && (
                                      <span className="text-danger img-file">
                                        {ValidateError?.season}
                                      </span>
                                    )}
                                  </div>
                                )}
                                {/* <div className="inner_row mb-3">
                                  {" "}
                                  <div>
                                    <p className="label mb-0">
                                      Properties3
                                      <span className="text-danger">*</span>
                                    </p>

                                    <div className="cs_prp_wrp d-flex align-items-center gap-3 py-3">
                                      {number.map((value, index) => (
                                        <>
                                          {number.length - 2 >= index && (
                                            <div className="d-flex align-items-center justify-content-between gap-3 cs_prp_crd p-2">
                                              <div className="d-flex align-items-center">
                                                <p className="mb-0 pe-1">
                                                  {key[index]}
                                                </p>{" "}
                                                :
                                                <p className="mb-0 ps-1">
                                                  {Value[index]}
                                                </p>
                                              </div>

                                              <i
                                                class="fa-solid fa-x cs_prp_cls"
                                                onClick={() =>
                                                  RemoveProperties(index)
                                                }
                                              ></i>
                                            </div>
                                          )}
                                        </>
                                        // )}
                                      ))}
                                    </div>
                                  </div>{" "}
                                </div>{" "} */}
                                {/* <div className="inner_row mb-3">
                                  <Row>
                                    <Col sm={4}>
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Traits
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="key"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Traits"
                                            value={key[number.length - 1] || ""}
                                          />
                                          {ValidateError.key && (
                                            <span className="text-danger img-file">
                                              {ValidateError.key}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={4} className="mt-3 mt-sm-0">
                                      {" "}
                                      <div>
                                        <p className="label">
                                          Size
                                          <span className="text-danger">*</span>
                                        </p>
                                        <div class="form-group">
                                          <input
                                            type="text"
                                            class="form-control custom_input"
                                            id="value"
                                            onChange={(e) => Properties(e)}
                                            aria-describedby="emailHelp"
                                            placeholder="Size"
                                            value={
                                              Value[number.length - 1] || ""
                                            }
                                          />
                                          {ValidateError.value && (
                                            <span className="text-danger img-file">
                                              {ValidateError.value}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Col>
                                    <Col
                                      sm={4}
                                      className="d-flex align-items-end mt-4 mt-sm-0"
                                    >
                                      {" "}
                                      <Button
                                        className="green_btn hover_btn card_btn btn_hgt"
                                        onClick={() => AddProperties()}
                                      >
                                        Add More
                                      </Button>
                                    </Col>
                                    <Col xs={4} className="d-none d-sm-block">
                                      {ValidateError.key && (
                                        <span className="text-danger img-file">
                                          {ValidateError.key}
                                        </span>
                                      )}
                                    </Col>
                                    <Col xs={4} className="d-none d-sm-block">
                                      {ValidateError.value && (
                                        <span className="text-danger img-file">
                                          {ValidateError.value}
                                        </span>
                                      )}
                                    </Col>
                                  </Row>
                                </div>{" "} */}
                                <div className="d-flex flex-column flex-sm-row align-items-center">
                                  <Button
                                    className="green_btn hover_btn card_btn  mt-4 "
                                    disabled={
                                      FormButton == "error" ||
                                        FormButton == "done" ||
                                        FormButton == "process"
                                        ? true
                                        : false
                                    }
                                    onClick={
                                      FormButton == "start" ? FormSubmit : null
                                    }
                                  >
                                    {FormButton == "start" && "Publish"}
                                    {FormButton == "error" && "Fix-Error"}
                                    {FormButton == "process" && "processing"}
                                    {FormButton == "done" && "Done"}
                                  </Button>
                                  {/* <Button
                                onClick={() => {
                                  handleShowAddCollection();
                                }}
                                className="green_btn hover_btn  mt-4 ms-sm-4 green_btn_sm_mb"
                              >
                                Add Collection
                              </Button> */}
                                </div>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
                <div className=" col-lg-4 col-xl-4 rightside mt-4 mt-lg-0 d-none d-lg-block ">
                  <p className="label">Preview item</p>

                  <div className="">
                    <Nftcard
                      from={"Preview"}
                      product={NFTFormValue}
                      preview={NFTFormValue}
                      type="Preview"
                      index={1}
                      value={NFTFormValue}
                    />{" "}
                  </div>
                  {/* ))} */}

                  {/* {typeof NFTFormValue?.NFTOrginalImage == "string" ? (
                  isEmpty(NFTFormValue?.NFTOrginalImage) ? (
                    <img src={saleImg} alt="images" />
                  ) : (
                    <ImgAudVideo
                      file={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`}
                      type={
                        NFTFormValue?.CompressedFile?.includes(".webp")
                          ? "image"
                          : NFTFormValue?.CompressedFile?.includes(".webm") ||
                            NFTFormValue?.CompressedFile?.includes(".mp4")
                          ? "video"
                          : "audio"
                      }
                      origFile={`${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`}
                      thumb={
                        NFTFormValue?.NFTThumpImage
                          ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`
                          : saleImg
                      }
                    />
                  //   <Nftcard 
                  //   from    = {'Preview'}
                  //   product={NFTFormValue}
                  //   type="Previed"
                  //   index={1}
                  //   value={NFTFormValue}
                  //   // LikeList={LikeList}
                  //   // LikedTokenList={LikedTokenList}
                  //   // setLikedTokenList={setLikedTokenList}
                  //   // LikeForwardRef={LikeForwardRef}
                  // />
                    // <Nftcard key={value.id} value={value} />
                  )
                ) 
                : 
                
                (     <Nftcard 
                  from    = {'Preview'}
                  product={NFTFormValue}
                  preview={NFTFormValue}
                  type="Previed"
                  index={1}
                  value={NFTFormValue}
                  // LikeList={LikeList}
                  // LikedTokenList={LikedTokenList}
                  // setLikedTokenList={setLikedTokenList}
                  // LikeForwardRef={LikeForwardRef}
                />)

                // NFTFormValue?.NFTOrginalImage?.type?.includes("image") ? (
                //   <>
                //     <img
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     />
                //   </>
                // ) : NFTFormValue?.NFTOrginalImage?.type?.includes("video") ? (
                //   <>
                //     <video
                //       loop={true}
                //       muted
                //       autoPlay={true}
                //       controls
                //       onContextMenu="return false;"
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     ></video>
                //   </>
                // ) : NFTFormValue?.NFTOrginalImage?.type?.includes("audio") ? (
                //   <>
                //     <audio
                //       controls
                //       onContextMenu="return false;"
                //       src={URL.createObjectURL(NFTFormValue?.NFTOrginalImage)}
                //       alt="images"
                //     ></audio>
                //   </>
                // )
                //  : (
                //   <img
                //     src={
                //       NFTFormValue?.NFTOrginalImage != ""
                //         ? `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT/${NFTFormValue?.NFTOrginalImage}`
                //         : saleImg
                //     }
                //     alt="images"
                //   />
                // )
                } */}
                  {/* </div> */}

                  <div style={{ opacity: 0, position: "relative" }}>
                    <Certificate
                      refdata={certificateRef}
                      nftdata={{
                        Profile: payload?.Profile
                          ? `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${payload?.Profile}`
                          : undefined,
                        NFTCreator: NFTFormValue?.NFTCreator,
                        NFTOwner: NFTFormValue?.NFTOwner,
                        NFTQuantity: NFTFormValue?.NFTQuantity,
                        Receptionist: NFTFormValue?.NFTOwner,
                        Certificateid: NFTFormValue?.Certificateid,
                        NFTName: NFTFormValue?.NFTName,
                        NFTDescription: NFTFormValue?.NFTDescription,
                        createdAt: new Date(),
                        ContractAddress: NFTFormValue?.ContractAddress,
                        NFTId: NFTFormValue?.NFTId,
                        ContractType: NFTFormValue?.ContractType,
                        HashValue: NFTFormValue?.HashValue,
                        BlockChain: currentNetwork?.BlockChain,
                      }}
                    />
                  </div>

                  {!isEmpty(NFTFormValue?.NFTOrginalImage?.type) &&
                    !NFTFormValue?.NFTOrginalImage?.type.includes("image") && (
                      <>
                        <p className="label mt-4">Preview Thumb Image</p>
                        <Row className="">
                          {/* {thumbCardData.map((value) => ( */}
                          {(!NFTFormValue?.NFTOrginalImage?.type?.includes(
                            "image"
                          ) ||
                            (typeof NFTFormValue?.NFTOrginalImage == "string" &&
                              (NFTFormValue?.NFTOrginalImage?.includes(
                                ".mp3"
                              ) ||
                                NFTFormValue?.NFTOrginalImage?.includes(
                                  ".webm"
                                )))) && (
                              <Col className="">
                                <Thumbcard
                                  key={1}
                                  value={
                                    typeof NFTFormValue?.NFTThumpImage == "string"
                                      ? isEmpty(NFTFormValue?.NFTThumpImage)
                                        ? saleImg
                                        : `${config.IMG_URL}/nft/${payload?.WalletAddress}/Original/NFT_THUMB/${NFTFormValue?.NFTThumpImage}`
                                      : URL.createObjectURL(
                                        NFTFormValue?.NFTThumpImage
                                      )
                                  }
                                />
                              </Col>
                            )}
                          {/* ))} */}
                        </Row>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>{" "}
          {showCreateItem && isEmpty(ValidateError) && (
            <FollowSteps
              show={showCreateItem}
              functions={{
                Nftimage: NFTFormValue.NFTOrginalImage,
                CompressedFile: NFTFormValue?.CompressedFile,
                approvecallshow: ApproveButton,
                ipfscallshow: UploadButton,
                mintcallshow: MintButton,
                approvefunction: TokenApproveCall,
                ipfscall: UploadIPFScall,
                mintcallfunction: MintCall,
                nfttype: location,
              }}
              NFTFormValue={NFTFormValue}
              setNFTFormValue={setNFTFormValue}
              handleClose={handleCloseCreateItem}
              Validation={Validation}
              SetValidateError={SetValidateError}
            />
          )}
          {showAddCollection && (
            <AddCollection
              show={showAddCollection}
              handleShow={handleShowAddCollection}
              handleClose={handleCloseAddCollection}
              project={NFTFormValue?.Project}
              Getcollection={() =>
                CollectionByCreate({
                  Type: collection == 1 ? 721 : 1155,
                  Creator: accountAddress,
                  Project: NFTFormValue?.Project,
                  ChainId: String(currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId),
                })
              }
            />
          )}
          {showCalender && (
            <Calender
              show={showCalender}
              handleClose={handleClosecalender}
              modal={OpenPopup}
              validDate={OpenPopup == "ClockTime" ? validStart : validEnd}
              setClockValue={setClockValue}
              closeModal={() => setCalenderShow(false)}
            />
          )}
        </section>
      )}
    </>
  );
}
