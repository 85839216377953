import React from "react";

import greenTick from "../../assests/svg/greentick.svg";
import profile from "../../assests/svg/profile.svg";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { Name_showing, address_showing } from "../actions/common";
import config from "../config/config";

const TopSeller = ({ props, index }) => {
  const { allcurrency } = useSelector((state) => state.LoginReducer);

  const Convertusd = (price, coinname) => {
    console.log("finalusdpricefinalusdpricefinal", price, coinname);

    var usdcueency = allcurrency.filter((item) => item.value == coinname)[0]?.usd;
    console.log("usdcueency", usdcueency * price);

    var finalusdprice = (usdcueency ? usdcueency : 0 * price).toFixed(2);
    console.log("finalusdpricefinalusdprice", finalusdprice);

    return finalusdprice;
  };

  return (
    // <Row>
    //   <Col xs={1}>{index}.</Col>
    //   <Col xs={12}>
    <div className="d-flex align-items-center gap-3 gap-xl-4 top_seller_card">
      {" "}
      <div className="table_collection_img position-relative">
        <NavLink
          to={`/author/${props?.userData?.CustomUrl
            ? props?.userData?.CustomUrl
            : props?.userData?.WalletAddress
            }`}
        >
          {/* <img
                src={greenTick}
                alt="verify"
                className="table_verify position-absolute"
              /> */}
          <img
            src={
              props?.userData?.Profile
                ? `${config.IMG_URL}/user/${props?.userData?.WalletAddress}/profile/${props?.userData?.Profile}`
                : profile
            }
            alt=""
            className="ts_left_image "
          />
        </NavLink>
      </div>
      <div className="ts_right_section">
        <div>
          {/* <h5 className="heading">hari</h5> */}
          <NavLink
            to={`/author/${props?.userData?.CustomUrl
              ? props?.userData?.CustomUrl
              : props?.userData?.WalletAddress
              }`}
            className="text-decoration-none"
          >
            <h5 className="heading">
              {props?.userData?.DisplayName
                ? Name_showing(props?.userData?.DisplayName)
                :
                address_showing(props?.userData?.WalletAddress)

              }
            </h5>{" "}
          </NavLink>
          <p className="ts_price">
            <span >Price : </span>

            {(Number(Convertusd(props?.TokenPrice ?? 0, "CAKE")) +
              Number(Convertusd(props?.Coinprice ?? 0, "BNB"))).toFixed(2)
            }
            {/* ${props.value} */}
            {/* {coinType} */}
            $
          </p>
        </div>
        <p

          className="ts_percent increase"
        // className="ts_percent decrease"
        >+67.80%</p>
      </div>
    </div>
    //   </Col>
    // </Row>
  );
};

export default TopSeller;
