import { useState, useRef, useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import profileImg from "../../assests/svg/profileImage.svg";
import bannerImgSvg from "../../assests/img/background.jpg";

import saleImg from "../../assests/img/nft.png";
import fixedicon from "../../assests/img/fixed.svg";
import fixedIconDark from "../../assests/svg/fixedicondark.svg";
import timedicon from "../../assests/img/timed.svg";
import timedIconDark from "../../assests/svg/timedauctiondark.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import config from "../config/config";

import Select from "react-select";
import { NumberChange, isEmpty } from "../actions/common";
import { useNavigate } from "react-router-dom";
import {
  CollectionApprove,
  CreateCollectionFunc,
} from "../actions/axios/nft.axios";
import useContractProviderHook from "../actions/contractProviderHook";
import Web3 from "web3";
import { useMemo } from "react";
import { GetAdmin } from "../actions/axios/user.axios";

function AddCollection({
  from,
  show,
  handleClose,
  data,
  project,
  Getcollection,
  GetData,
}) {
  // redux
  const currTheme = useSelector((state) => state.themeReducer.theme);

  // var [Adminaddress,SetAdminAddress] = useState(process.env.REACT_APP_ADMINADDRESS.toLowerCase());
  var [Adminaddress, SetAdminAddress] = useState('');

  const ContractCall = useContractProviderHook();

  // console.log("asdcfsdvdf", project);
  // useref

  const inputRef = useRef(null);
  const bannerInputRef = useRef(null);
  var push = useNavigate();

  const [profileImage, setProfileImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [BtnData, SetBtnData] = useState("start");
  const [ApproveBtn, SetApproveBtnData] = useState("start");
  const [Project, setProject] = useState(false);
  const [docsUpload, setDocsUpload] = useState(false);

  const [ValidateError, SetValidateError] = useState({});
  const { Categorys, currency } = useSelector((state) => state.LoginReducer);
  const { web3p, accountAddress, currentNetwork } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { payload } = useSelector((state) => state.LoginReducer.User);
  var accountInfo = localStorage.getItem("accountInfo");

  var inindata = {
    CollectionProfileImage: "",
    CollectionCoverImage: "",
    CollectionDocuments: "",
    CollectionName: "",
    CollectionDiscribtion: "",
    Collectionurl: "",
    CollectionCreator: accountAddress ? accountInfo : accountInfo,
    CollectionContractAddress: "",
    CollectionType: project ? 1155 : "",
    Category: Categorys[0]?.label,
    From: from == "editcollection" ? "editcollection" : "addcollection",
    Approved: accountAddress == Adminaddress ? true : false,
    Deployed: accountAddress == Adminaddress ? true : false,
    EmailId: payload?.EmailId,
    Project: project ? project : false,
    ChainId: (currentNetwork?.CHAIN_ID ? currentNetwork?.CHAIN_ID : config?.DefaultChainId)
  };
  const [FormValue, SetFormValue] = useState(inindata);

  // const decimal =
  // currency
  //   ?.filter(
  //     (item) =>
  //       item.label ===
  //       (owner.CoinName == "PancakeSwap Token" ? "CAKE" : owner.CoinName)
  //   )
  //   ?.pop()?.decimal ?? 18;

  useEffect(() => {
    if (data) {
      console.log('datttaaaaaa', data)
      SetFormValue({ ...FormValue, ...data });
    }
  }, [data]);

  useEffect(() => {
    if (project) {
      SetFormValue({ ...FormValue, ...{ CollectionType: 1155 } });
    }
  }, [project]);

  useEffect(() => {
    if (currentNetwork?.ChainId && isEmpty(data)) {
      SetFormValue({ ...FormValue, ChainId: currentNetwork?.ChainId })
    }
  }, [currentNetwork])

  useEffect(() => {
    if (isEmpty(data) && Adminaddress) {
      SetFormValue({
        ...FormValue,
        Approved: accountAddress == Adminaddress ? true : false,
        Deployed: accountAddress == Adminaddress ? true : false,
      })
    }
  }, [Adminaddress])

  console.log('FormValueFormValue', FormValue)

  useEffect(() => {
    GetAdminAddress()
  }, [])

  const GetAdminAddress = async () => {
    let resp = await GetAdmin();
    console.log('GetAdminresppp', resp);
    if (!isEmpty(resp?.data?.AdminAddress)) {
      SetAdminAddress(String(resp?.data?.AdminAddress)?.toLowerCase())
    }
  }

  const onChange = (e) => {
    SetBtnData("start");
    SetValidateError({});

    const { files, value, id } = e.target;
    if (files) {
      console.log("sdsdasfef", id);

      var file = files[0];
      if (id == "CollectionProfileImage" || id == "CollectionCoverImage") {
        var validExtensions = ["png", "gif", "webp", "jpg", "jpeg"];
      }
      if (id == "CollectionDocuments") {
        var validExtensions = ["pdf"];
      }
      var fileName = file?.name;
      var fileNameExt = fileName?.substr(fileName.lastIndexOf(".") + 1);

      if (!validExtensions.some((val) => fileNameExt === val)) {
        toast.error(
          "Only these file types are accepted : " + validExtensions.join(", ")
        );
        // return false;
      } else {
        if (id == "CollectionProfileImage")
          SetFormValue({ ...FormValue, ...{ [id]: files?.[0] } });
        else if (id == "CollectionCoverImage")
          SetFormValue({ ...FormValue, ...{ [id]: files?.[0] } });
        else if (id == "CollectionDocuments") {
          SetFormValue({ ...FormValue, ...{ [id]: files?.[0] } });
        }
      }
    } else if (id == "CollectionSymbol")
      SetFormValue({
        ...FormValue,
        ...{ [id]: value.toString().replace(/\s/g, "").toLowerCase() },
      });
    else if (id == "CollectionType")
      SetFormValue({ ...FormValue, ...{ [id]: value } });
    else SetFormValue({ ...FormValue, ...{ [id]: value } });
  };

  const Validation = async (data) => {
    let ValidateError = {};
    const {
      CollectionProfileImage,
      CollectionName,
      CollectionCoverImage,
      Collectionurl,
      CollectionDocuments,
      Project,
      CollectionType,
    } = data;
    if (!CollectionName)
      ValidateError.CollectionName = "Collection Name Required";
    if (!CollectionProfileImage)
      ValidateError.CollectionProfileImage =
        "Collection Profile Image Required";

    if (!Collectionurl) {
      ValidateError.Collectionurl = "Collectionurl  Required";
    }
    if (Collectionurl) {
      //  console.log("asdasdsfef",config.notSpecil.test(Collectionurl));
      if (!config.notSpecil.test(Collectionurl)) {
        ValidateError.Collectionurl = "Do not Use Special characters";
      }
    }

    if (!CollectionCoverImage)
      ValidateError.CollectionCoverImage = "Collection Cover Image Required";

    if (Project && !CollectionDocuments) {
      ValidateError.CollectionDocuments = "CollectionDocuments Required";
    } else if (!CollectionType) {
      ValidateError.CollectionType = "CollectionType Required";
    }

    return ValidateError;
  };
  const modelclose = () => {
    handleClose();
    SetFormValue({});
  };

  const handleCheckboxActive = (e) => {
    SetFormValue({ ...FormValue, ...{ Project: !FormValue?.Project } });
  };

  console.log('FormValueFormValue', FormValue)

  const FormSubmit = async () => {
    let toastid = toast.loading("Collection Adding")
    try {
      if (accountAddress) {
        SetBtnData("start");
        var Error = await Validation(FormValue);
        SetBtnData("process");
        if (isEmpty(Error)) {
          if (FormValue?.Project) {
            if (accountAddress !== Adminaddress) {
              let Respc = await CreateCollectionFunc(FormValue);
              if (Respc?.data?.success == "success") {
                SetBtnData("done");
                toast.update(toastid, { render: "After Getting Confirmation Your Collection Will be Create", type: 'success', isLoading: false, autoClose: 1000 })
                modelclose();
                push("/create");
              } else {
                SetBtnData("error");
                toast.update(toastid, { render: Respc?.data?.msg?.CollectionName, type: 'error', isLoading: false, autoClose: 1000 })
                // toast.error(Respc?.data?.msg?.CollectionName);
                SetValidateError(Respc.data.msg);
              }
            } else {
              let chainid = NumberChange(FormValue?.ChainId)
              let checkchain = await ContractCall.SwitchNetwork(chainid);
              console.log('checkchaincheckchain', checkchain)
              if (checkchain) {
                // if(checkchain==true){

                // }
              }
              else {
                SetBtnData("error");
                return false;
              }
              let cont = await ContractCall.createcollection_1155(
                FormValue?.CollectionCreator,
                FormValue?.CollectionName,
                FormValue?.Collectionurl
              );
              if (cont) {
                var updatdata = FormValue;
                updatdata.CollectionContractAddress = cont?.contractaddress;
                updatdata.Deployed = true;

                let Respc = await CreateCollectionFunc(FormValue);
                if (Respc?.data?.success == "success") {
                  SetApproveBtnData("done");
                  toast.update(toastid, { render: "Collection Created Successfully", type: "success", isLoading: false, autoClose: 1000 })
                  // toast.success("Collection Created Successfully");
                  modelclose();
                  Getcollection();
                  // push("/collections");
                } else {
                  SetBtnData("error");
                  SetApproveBtnData("error");
                  toast.update(toastid, { render: Respc?.data?.msg?.CollectionName, type: "error", isLoading: false, autoClose: 1000 })
                  // toast.error(Respc?.data?.msg?.CollectionName);
                  SetValidateError(Respc.data.msg);
                }
              }
              else {
                SetBtnData("error");
                toast.update(toastid, { render: "Try-Again", type: "error", isLoading: false, autoClose: 1000 })
                // toast.error("Try-Again");
              }
            }
          } else {
            console.log('frommmmmm', data?.fromm)
            let Respc = await CreateCollectionFunc({ ...FormValue, ...{ From: (from == 'edit' ? "editcollection" : FormValue.From) } });
            console.log('RespcRespc', Respc?.data?.success)
            if (Respc?.data?.success == "success") {
              SetBtnData("done");
              console.log('toasssss', toastid, Respc?.data?.msg)
              toast.update(toastid, { render: ((Respc?.data?.msg && typeof (Respc?.data?.msg) == 'string') ? Respc?.data?.msg : "Collection Created Successfully"), type: "success", isLoading: false, autoClose: 1000 })
              // toast.success("Collection Created Successfully");
              modelclose();
              if (Getcollection) {
                Getcollection();
              }
              // push("/create");
            } else {
              SetBtnData("error");
              toast.update(toastid, { render: Respc?.data?.msg?.CollectionName, type: "error", isLoading: false, autoClose: 1000 })
              // toast.error(Respc?.data?.msg?.CollectionName);
              SetValidateError(Respc.data.msg);
            }
          }
        } else {
          SetBtnData("error");
          SetValidateError(Error);
          toast.update(toastid, { render: "Check Fields", type: "error", isLoading: false, autoClose: 1000 })
          // toast.error("Check Fields");
        }
      } else {
        toast.update(toastid, { render: "Connect Wallet To create NFT", type: "error", isLoading: false, autoClose: 1000 })
        // toast.error("Connect Wallet To create NFT");
      }
    }
    catch (err) {
      console.log('FormSubmit_error', err)
      SetBtnData("error");
      toast.update(toastid, { render: "Try-Again", type: "error", isLoading: false, autoClose: 1000 })
      // toast.error("Try-Again");
    }
  };
  // console.log("GetcollectionGetcollectionGetcollection", Getcollection);
  const Deploy = async () => {
    try {
      if (accountAddress) {
        SetBtnData("start");
        var Error = await Validation(FormValue);
        SetBtnData("process");

        if (isEmpty(Error)) {
          let chainid = NumberChange(FormValue?.ChainId)
          let checkchain = await ContractCall.SwitchNetwork(chainid);
          console.log('checkchaincheckchain', checkchain)
          if (checkchain) {
            // if(checkchain==true){

            // }
          }
          else {
            SetBtnData("error");
            return false;
          }
          let cont = await ContractCall.createcollection_1155(
            FormValue?.CollectionCreator,
            FormValue?.CollectionName,
            FormValue?.Collectionurl
          );
          console.log('contcont', cont)
          if (cont) {
            var updatdata = FormValue;
            updatdata.CollectionContractAddress = cont?.contractaddress;
            updatdata.Deployed = true;

            let Respc = await CreateCollectionFunc(FormValue);
            if (Respc?.data?.success == "success") {
              SetBtnData("done");
              toast.success("Collection Created Successfully");
              modelclose();
              push(`/author/${payload?.CustomUrl}`);
              //  Getcollection();
              // SetOpenPopup("")
            } else {
              SetBtnData("error");
              SetApproveBtnData("error");
              toast.error(Respc?.data?.msg?.CollectionName);
              SetValidateError(Respc.data.msg);
            }
          }
          else {
            SetBtnData("error");
            toast.error("Try-Again");
          }
        } else {
          SetBtnData("error");
          SetApproveBtnData("error");
          SetValidateError(Error);
          toast.error("Check Fields");
        }
      } else {
        toast.error("Connect Wallet To create NFT");
      }
    }
    catch (err) {
      console.log('Deploy_error', err);
      SetBtnData("error");
      SetApproveBtnData("error");
      toast.error("Try-Again");
    }

  };

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 10px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "16px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcnter"
      centered
      className="custom_modal"
      onHide={modelclose}
      backdrop="static"
    >
      <Modal.Header closeButton className="px-sm-5 mx-auto">
        <Modal.Title id="contained-modal-title-vcenter" className=" heading">
          {(from == "editcollection" || from == "edit") ? "Edit Collection" : "Add Collection"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-sm-5 pb-4 add_colec_mdl_bdy">
        <div className="d-flex flex-column">
          <div className="put_on_sale_img banner_image_upload add_colc_baner_img_wrp">
            {typeof FormValue?.CollectionCoverImage == "string" ? (
              <>
                {FormValue?.CollectionCoverImage.includes(".webp") ? (
                  <img
                    src={`${config.IMG_URL}/collection/cover/${FormValue?.Collectionurl}/${FormValue?.CollectionCoverImage}`}
                    alt="profile"
                    className="img-fluid"
                  />
                ) : (
                  <img src={bannerImgSvg} alt="profile" className="img-fluid" />
                )}
              </>
            ) : (
              <>
                {typeof FormValue?.CollectionCoverImage == "object" ? (
                  <img
                    src={URL.createObjectURL(FormValue?.CollectionCoverImage)}
                    alt="profile"
                    className="img-fluid"
                  />
                ) : (
                  <img src={bannerImgSvg} alt="profile" className="img-fluid" />
                )}
              </>
            )}

            {/* <p className="text-danger img-file">
                {ValidateError?.CollectionCoverImage}
              </p> */}
            <button className="green_btn hover_btn bnt_upload_banner">
              <i className="fa fa-pencil" />

              <input
                type="file"
                ref={inputRef}
                id="CollectionCoverImage"
                onChange={(e) => onChange(e)}
              ></input>
            </button>
          </div>
          <div className="put_on_sale_img prof_coll_image banner_image_upload ">
            {typeof FormValue?.CollectionProfileImage == "string" ? (
              <>
                {FormValue?.CollectionProfileImage?.includes(".webp") ? (
                  <img
                    src={`${config.IMG_URL}/collection/profile/${FormValue?.Collectionurl}/${FormValue?.CollectionProfileImage}`}
                    alt="profile"
                    className="img-fluid"
                  />
                ) : (
                  <img src={profileImg} alt="profile" className="img-fluid" />
                )}
              </>
            ) : (
              <>
                {typeof FormValue?.CollectionProfileImage == "object" ? (
                  <img
                    src={URL.createObjectURL(FormValue?.CollectionProfileImage)}
                    alt="profile"
                    className="img-fluid"
                  />
                ) : (
                  <img src={profileImg} alt="profile" className="img-fluid" />
                )}
              </>
            )}

            {/* <img src={saleImg} alt="nft" className="img-fluid" /> */}
            <button className="green_btn hover_btn bnt_upload_banner">
              <i className="fa fa-pencil" />

              <input
                type="file"
                ref={inputRef}
                id="CollectionProfileImage"
                onChange={(e) => onChange(e)}
              ></input>
            </button>
          </div>
          {/* {ValidateError?.CollectionProfileImage && (
            <p className="text-danger img-file">
              {ValidateError?.CollectionProfileImage}
            </p>
          )} */}
          {/* <Row className="w-100 mt-3">
              <Col xs={9} className="ps-0">
                <div>
                  <h5 className="heading mb-0"> Project</h5>
                  <p>If you Want Create Project for Staking Your NFT</p>

                 
                </div>
              </Col>
              <Col xs={3} className="pe-0">
                {" "}
                <label class="switch tab_toggle">
                  <input type="checkbox" onChange={handleCheckboxActive} checked={FormValue?.Project} disabled={from=='editcollection'?true:false}/>
                  <span class="slider round"></span>
                </label>
              </Col>
            </Row> */}
          <p className="text-danger img-file mt-5">
            {ValidateError?.CollectionCoverImage}
          </p>
          {ValidateError?.CollectionProfileImage && (
            <p className="text-danger img-file mb-0">
              {ValidateError?.CollectionProfileImage}
            </p>
          )}
          <p className="mt-3 text-left label">Collection Name</p>
          <div className="w-100">
            <input
              id="CollectionName"
              type="text"
              placeholder="Enter Collection Name"
              className="custom_input modal_input w-100"
              value={FormValue?.CollectionName}
              disabled={from == "editcollection" ? true : false}
              onChange={onChange}
            />
          </div>
          {ValidateError?.CollectionName && (
            <span className="text-danger img-file">
              {ValidateError?.CollectionName}
            </span>
          )}
          {!FormValue?.Project
            && <>
              <p className="mt-4 text-left label">Type</p>
              <Select
                isSearchable={false}
                styles={stylesgraybg}
                className="react_slt select_dropdown_modal"
                id="CollectionType"
                value={{
                  label:
                    FormValue?.CollectionType == 721
                      ? "Single"
                      : FormValue?.CollectionType == 1155
                        ? "Multiple"
                        : "Select...",
                  value:
                    FormValue?.CollectionType == 721
                      ? "Single"
                      : FormValue?.CollectionType == 1155
                        ? "Multiple"
                        : "Select...",
                }}
                onChange={(e) => (
                  SetValidateError({}),
                  SetFormValue({
                    ...FormValue,
                    ...{ ["CollectionType"]: e.value },
                  })
                )}
                options={[
                  { value: 721, label: "Single" },
                  { value: 1155, label: "Multiple" },
                ]}
                classNamePrefix="react-select-one"
                isDisabled={FormValue.Project || data?.Collectionurl}
              />
            </>}

          {ValidateError?.CollectionType && (
            <span className="text-danger img-file">
              {ValidateError?.CollectionType}
            </span>
          )}

          <p className="mt-4 text-left label">Collection Symbol</p>
          <div className="w-100">
            <input
              type="text"
              placeholder="Enter Custom URL"
              className="custom_input modal_input w-100"
              id="Collectionurl"
              value={FormValue?.Collectionurl}
              onChange={onChange}
              disabled={(FormValue.From == "editcollection" || data?.Collectionurl) ? true : false}
            />
          </div>
          {ValidateError?.Collectionurl && (
            <span className="text-danger img-file">
              {ValidateError?.Collectionurl}
            </span>
          )}
          {/* <p className="mt-4 text-left label">Category</p>
          <div className="w-100 theme_based_sl">
            <Select
              isSearchable={false}
              styles={stylesgraybg}
              // menuIsOpen={true}
              value={{
                label: FormValue?.Category,
                value: FormValue?.Category,
              }}
              isDisabled={
                FormValue.CollectionSymbol
                  ? true
                  : false || FormValue.From == "editcollection"
                  ? true
                  : false
              }
              onChange={(e) =>
                SetFormValue({
                  ...FormValue,
                  ...{ ["Category"]: e.label },
                })
              }
              options={Categorys}
              className="react_slt select_dropdown_modal"
              placeholder="Art"
            />
          </div>
          {ValidateError?.Category && (
            <span className="text-danger img-file">
              {ValidateError?.Category}
            </span>
          )} */}
          <p className="mt-4 text-left label">Description</p>
          <div className="w-100">
            <input
              type="text"
              placeholder="Enter Description"
              className="custom_input modal_input w-100"
              id="CollectionDiscribtion"
              value={FormValue?.CollectionDiscribtion}
              onChange={onChange}
            />
          </div>
          {FormValue?.Project && (
            <>
              {typeof FormValue?.CollectionDocuments == "string" &&
                !isEmpty(FormValue?.CollectionDocuments) ? (
                <>
                  <a
                    href={`${config.IMG_URL}/collection/documents/${FormValue?.Collectionurl}/${FormValue?.CollectionDocuments}`}
                    download
                    target="_blank"
                    className="text-decoration-none"
                  >
                    <button className="green_btn hover_btn mt-4 w-100">
                      view pdf{" "}
                    </button>
                  </a>
                </>
              ) : (
                <>
                  <p className="mt-4 text-left label">Documents Upload</p>
                  {console.log("FormValue", FormValue)}{" "}
                  <p className=" text-left">
                    {!isEmpty(FormValue?.CollectionDocuments?.name)
                      ? FormValue?.CollectionDocuments?.name
                      : "No file choose"}
                    {/* {   !isEmpty(FormValue?.CollectionDocuments?.name) &&
                        FormValue?.CollectionDocuments?.name} */}
                  </p>
                  <div>
                    <button className="green_btn hover_btn">
                      Browse
                      <input
                        className=" cs_upld_inpt"
                        type="file"
                        id="CollectionDocuments"
                        autoComplete="off"
                        onChange={(e) => onChange(e)}
                      />
                    </button>
                  </div>
                  {ValidateError?.CollectionDocuments && (
                    <span className="text-danger img-file">
                      {ValidateError?.CollectionDocuments}
                    </span>
                  )}
                </>
              )}
            </>
          )}
          {/* { ApproveBtnshow  &&  <Button
            className="green_btn hover_btn mt-4"
            onClick={BtnData == "start" ? CollectionapproveFun : ""}
          >
            {ApproveBtn == "start" && "Approve"}
            {ApproveBtn == "error" && "Error"}
            {ApproveBtn == "process" && "processing"}
            {ApproveBtn == "done" && "Done"}
          </Button>
          } */}
          {from == "editcollection" ? (
            <Button
              className="green_btn hover_btn mt-4"
              onClick={(BtnData == "start" || BtnData == "error") ? Deploy : null}
            >
              {BtnData == "start" && "Deploy"}
              {BtnData == "error" && "Fix-Error"}
              {BtnData == "process" && "processing"}
              {BtnData == "done" && "Done"}
            </Button>
          ) : (
            <Button
              className="green_btn hover_btn mt-4"
              onClick={(BtnData == "start" || BtnData == "error") ? FormSubmit : null}
            >
              {BtnData == "start" && "Start"}
              {BtnData == "error" && "Fix-Error"}
              {BtnData == "process" && "processing"}
              {BtnData == "done" && "Done"}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddCollection;
