import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import collectionCardOne from "../../assests/img/background.jpg";
import { Link, NavLink } from "react-router-dom";
import config from "../config/config";
import { Name_showing } from "../actions/common";
import { ValidChainCheck } from "../hooks/useWallet";

function CollectionCards({ props }) {
  const { CollectionProfileImage, DisplayName, Collectionurl, CollectionName, field, Tokensdata, CollectionType, totalnft } = props;
  console.log('jkhdjhkdadad', DisplayName)
  return (
    <div className="mb-3">
      {
        (props?.Approved || props?.Project == false) ?
          <NavLink
            // to={{ pathname: `/collectiondetail` }}
            // state={{ collectionnft: props }}
            to={`/collectiondetail/${Collectionurl}`}
            className="no_dec_cool"
          >
            <Card className="collection_card">
              <Card.Img
                variant="top"
                src={
                  CollectionProfileImage
                    ? `${config.IMG_URL}/collection/profile/${Collectionurl}/${CollectionProfileImage}`
                    : collectionCardOne
                }
                className="collection_card_img collection_card_img_ht"
              />
              <Card.Body className="d-flex  flex-column align-items-center pb-0">
                <h5 className="heading">
                  {Name_showing(CollectionName ? CollectionName : DisplayName)}
                </h5>
                <p className="content my-2">

                  {ValidChainCheck(props?.ChainId)?.COIN_NAME ?? config?.COIN_NAME}-{String(CollectionType) == "1155" ? "1155" : "721"}
                </p>
                {
                  <p className="total_nft_val">
                    Total Assets : {Tokensdata ?? 0}
                  </p>
                }
              </Card.Body>
            </Card>
          </NavLink>
          :
          <div className="no_dec_cool">
            <Card className="collection_card">
              <Card.Img
                variant="top"
                src={
                  CollectionProfileImage
                    ? `${config.IMG_URL}/collection/profile/${Collectionurl}/${CollectionProfileImage}`
                    : collectionCardOne
                }
                className="collection_card_img collection_card_img_ht"
              />
              <Card.Body className="d-flex  flex-column align-items-center pb-0">
                <h5 className="heading">
                  {Name_showing(CollectionName ? CollectionName : DisplayName)}
                </h5>
                <p className="content">

                  {ValidChainCheck(props?.ChainId)?.COIN_NAME ?? config?.COIN_NAME}-{String(CollectionType) == "1155" ? "1155" : "721"}
                </p>
                {
                  <p className="total_nft_val">
                    Total Assets : {Tokensdata ?? 0}
                  </p>
                }
              </Card.Body>
            </Card>
          </div>
      }

    </div>
  );
}

export default CollectionCards;
