import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Tab, Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Innertitle from "../common-components/innertitle";
import BlogCards from "../common-components/BlogCards";
import blogCardImg from "../../assests/svg/blogcardimg.svg";
import { Link } from "react-router-dom";
import InputField from "../common-components/InputField";
import ProfileInfo from "./ProfileInfo";
import CollectionCards from "../common-components/CollectionCards";

import collectionCardOne from "../../assests/img/background.jpg";
import collectionCardTwo from "../../assests/img/bgTwo.jpg";
import collectionCardThree from "../../assests/img/bgThree.jpg";
import collectionCardFour from "../../assests/svg/hotcollectionfour.svg";
import { GetArticle } from "../actions/axios/cms.axios";
import NoData from "../common-components/nodata";
import {
  Admindata,
  GetAdmin,
  Subscription,
  userRegister,
} from "../actions/axios/user.axios";
import { Name_showing, isEmpty } from "../actions/common";
import config from "../config/config";
import { toast } from "react-toastify";
import Loader from "../common-components/Loader";



const Blog = () => {

  const [blogcard, setblogcard] = useState([]);
  const [loadlimit, setloadlimit] = useState(6);
  const [admindata, setadmindata] = useState({});
  const [admincollections, setadmincollections] = useState([]);
  const [loader, setLoader] = useState(true);

  var [email, setEmail] = useState("");
  var [Error, SetError] = useState({});

  // var Adminaddress = process.env.REACT_APP_ADMINADDRESS.toLowerCase();
  var [Adminaddress, SetAdminAddress] = useState('');


  useEffect(() => {
    GetAdminAddress()
  }, [])

  const GetAdminAddress = async () => {
    let resp = await GetAdmin();
    console.log('GetAdminresppp', resp);
    if (!isEmpty(resp?.data?.AdminAddress)) {
      SetAdminAddress(String(resp?.data?.AdminAddress)?.toLowerCase())
    }
  }

  useEffect(() => {
    Getarticles();
    getAdminDetails();
  }, [Adminaddress]);

  const Getarticles = async () => {
    var resp = await GetArticle();
    console.log("articleaaa", resp);
    if (resp?.success) {
      setblogcard(resp.data);
    } else {
      // setStatus(resp.status)
    }
  };

  const getAdminDetails = async () => {
    var SendDATA = {
      Type: "getProfile",
      WalletAddress: Adminaddress,
    };
    var profileInfo = await Admindata(SendDATA);
    // console.log("dcsdvsfv",profileInfo);
    if (profileInfo?.success == "success" && profileInfo?.msg?.WalletAddress) {
      setLoader(false)
      setadmindata(profileInfo?.msg);
      setadmincollections(profileInfo?.collections?.data);
    }
  };

  const NewsLetter = async () => {
    const id = toast.loading("Subscribing...");

    let err = {};
    if (email == "") err.email = "Email Id Required";
    if (email && !config.EMAIL.test(email))
      err.email = "Invalid Email ID Format";
    SetError(err);
    // console.log('fhbdfhbdf',email)
    if (isEmpty(err)) {
      let resp = await Subscription({ email: email });
      if (resp.success === "success") {
        toast.update(id, {
          render: "Successfully Subscribed",
          type: "success",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        setEmail("");
      } else {
        toast.update(id, {
          render: "Already User",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetError({ email: resp.msg });
      }
    } else {
      toast.update(id, {
        render: err.email,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
    }
  };

  // console.log("dcsdvsdfv",admindata);
  return (
    loader ? (
      <Loader show={loader} handleClose={() => setLoader(false)} />
    ) : (
      <section>
        <Innertitle title="Blog" subtitle="" />
        <Container className="custom_container ">
          {/* Profile */}
          <div className="profile_section d-flex justify-content-start gap-4 mb-5">
            <ProfileInfo userProfile={admindata} />
          </div>

          <div className="tab_section ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="author_section">
                <Col sm={12}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first" className="tab_nav_act">
                        Entries
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second" className="tab_nav_act">
                        Collection
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content className="mt-4">
                    <Tab.Pane eventKey="first">
                      <div className="first_section row">
                        <div>
                          {/* Subscribe newsletter */}
                          <Row className="subscribe mb-4 py-3 px-2 px-sm-3 justify-content-between mx-auto">
                            <Col md={6} >
                              {" "}
                              <h5 className="heading">
                                Subscribe to{" "}
                                {Name_showing(
                                  admindata?.DisplayName ??
                                  admindata?.WalletAddress
                                )}
                              </h5>
                              {/* <p className="content mb-0">
                                Receive new entries directly to your inbox.
                              </p> */}
                            </Col>
                            <Col
                              md={6}

                              className="d-flex align-items-center justify-content-start justify-content-md-end mt-3 mt-md-0"
                            >
                              <div>

                                <p className="content mb-0 text-start text-md-end">
                                  Receive new entries directly to your inbox.
                                </p>
                              </div>

                            </Col>
                            <Col className="my-4 " >
                              <InputGroup className="input_max_ht">
                                <Form.Control
                                  placeholder="enter your email"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  className="input_fld"
                                  value={email}
                                  onChange={(event) => (
                                    setEmail(event.target.value), SetError({})
                                  )}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                  className="input_btn"
                                  onClick={() => NewsLetter()}
                                >
                                  Subscribe
                                </Button>
                              </InputGroup></Col>
                          </Row>

                          <Row>
                            {blogcard?.slice(loadlimit - 20, loadlimit)
                              ?.length !== 0 ? (
                              blogcard
                                ?.slice(loadlimit - 20, loadlimit)
                                .map((data) => (
                                  <Col sm={6} lg={4} className="mb-4">
                                    <BlogCards data={data} />
                                    {/* <div className="d-flex align-items-center pagination_wrapper mx-auto mt-3">
                                    <Link
                                      className="pagination_btn active px-3 d-flex justify-content-center py-2"
                                      onClick={() => setloadlimit(20)}
                                    >
                                      1-20
                                    </Link>
                                    <Link
                                      className="pagination_btn px-3 d-flex justify-content-center py-2"
                                      onClick={() => setloadlimit(40)}
                                    >
                                      21-40
                                    </Link>
                                    <Link
                                      className="pagination_btn px-3 d-flex justify-content-center py-2"
                                      onClick={() => setloadlimit(60)}
                                    >
                                      41-60
                                    </Link>
                                  </div> */}
                                  </Col>
                                ))
                            ) : (
                              <NoData />
                            )}
                          </Row>

                          {/* Pagination */}
                          <div className="d-flex align-items-center pagination_wrapper mx-auto mt-3">
                            {
                              blogcard?.length > 0
                                ?
                                <Link
                                  className="pagination_btn active px-3 d-flex justify-content-center py-2"
                                  onClick={() => setloadlimit(20)}
                                >
                                  1-20
                                </Link>
                                :
                                <></>
                            }

                            {
                              blogcard?.length > 20
                                ?
                                <Link
                                  className="pagination_btn px-3 d-flex justify-content-center py-2"
                                  onClick={() => setloadlimit(40)}
                                >
                                  21-40
                                </Link>
                                :
                                <></>
                            }

                            {
                              blogcard?.length > 40
                                ?
                                <Link
                                  className="pagination_btn px-3 d-flex justify-content-center py-2"
                                  onClick={() => setloadlimit(60)}
                                >
                                  41-60
                                </Link>
                                :
                                <></>
                            }
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {" "}
                      <div className="first_section row">
                        <div>
                          {/* Subscribe newsletter */}
                          {/* <Row className="subscribe mb-4 py-3 px-2 px-sm-3 justify-content-between">
                            <Col md={6} lg={6}>
                              {" "}
                              <h5 className="heading">
                                Subscribe to{" "}
                                {Name_showing(
                                  admindata?.DisplayName ??
                                  admindata?.WalletAddress
                                )}
                              </h5>
                              <p className="content mb-0">
                                Receive new entries directly to your inbox.
                              </p>
                            </Col>
                            <Col
                              md={6}
                              lg={5}
                              className="d-flex align-items-center mt-3 mt-md-0"
                            >
                              <InputGroup className="input_max_ht">
                                <Form.Control
                                  placeholder="enter your email"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  className="input_fld"
                                  value={email}
                                  onChange={(event) => (
                                    setEmail(event.target.value), SetError({})
                                  )}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                  className="input_btn"
                                  onClick={() => NewsLetter()}
                                >
                                  Subscribe
                                </Button>
                              </InputGroup>
                            </Col>
                          </Row> */}
                          <Row className="subscribe mb-4 py-3 px-2 px-sm-3 justify-content-between mx-auto">
                            <Col md={6} >
                              {" "}
                              <h5 className="heading">
                                Subscribe to{" "}
                                {Name_showing(
                                  admindata?.DisplayName ??
                                  admindata?.WalletAddress
                                )}
                              </h5>
                              {/* <p className="content mb-0">
                                Receive new entries directly to your inbox.
                              </p> */}
                            </Col>
                            <Col
                              md={6}

                              className="d-flex align-items-center justify-content-start justify-content-md-end mt-3 mt-md-0"
                            >
                              <div>

                                <p className="content mb-0 text-start text-md-end">
                                  Receive new entries directly to your inbox.
                                </p>
                              </div>

                            </Col>
                            <Col className="my-4 " >
                              <InputGroup className="input_max_ht">
                                <Form.Control
                                  placeholder="enter your email"
                                  aria-label="Recipient's username"
                                  aria-describedby="basic-addon2"
                                  className="input_fld"
                                  value={email}
                                  onChange={(event) => (
                                    setEmail(event.target.value), SetError({})
                                  )}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                  className="input_btn"
                                  onClick={() => NewsLetter()}
                                >
                                  Subscribe
                                </Button>
                              </InputGroup>
                            </Col>
                          </Row>
                          <Row>
                            {admincollections?.map((val) => (
                              <Col md={6} lg={4} xxl={3} className="mt-4">
                                <CollectionCards props={val} />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </div>
        </Container>
      </section>
    )
  );
};

export default Blog;
