import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Button, Tab, Nav, Card } from "react-bootstrap/";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";

import Innertitle from "../common-components/innertitle";

import profileIcon from "../../assests/svg/profileIcon.svg";
import profiledark from "../../assests/svg/profiledark.svg";
import notification from "../../assests/svg/notification.svg";
import appearance from "../../assests/svg/appearance.svg";
import notificationLight from "../../assests/svg/notificationlight.svg";
import notificationDark from "../../assests/svg/notificationdark.svg";
import appearancelight from "../../assests/svg/appearancelight.svg";
import appearancedark from "../../assests/svg/appearancedark.svg";
import important from "../../assests/svg/important.svg";
import profileImg from "../../assests/svg/profileImage.svg";
import bannerImgSvg from "../../assests/img/background.jpg";
import link from "../../assests/svg/link.svg";
import linkdark from "../../assests/svg/linkdark.svg";
import instagram from "../../assests/svg/instagram.svg";
import instagramdark from "../../assests/svg/instagramdark2.svg";
import twitter from "../../assests/svg/twitter.svg";
import twitterdark from "../../assests/svg/twitterdark2.svg";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "../actions/common";
import config from "../config/config";
import { userRegister } from "../actions/axios/user.axios";
import { useNavigate } from "react-router-dom";

const notificationData = [
  {
    id: 1,
    title: "Item Sold",
    subtitle: "When someone purhased your item.",
    key: "Itemsold",
  },
  {
    id: 2,
    title: "Mint A NFT",
    subtitle: "When an auction you created ends.",
    key: "Mint",
  },
  {
    id: 3,
    title: "Bid Activity",
    subtitle: "When someone purhased your item.",
    key: "Bidactivity",
  },
  {
    id: 4,
    title: "Put On Sale",
    subtitle: "When someone purhased your item.",
    key: "Placeoreder",
  },
  {
    id: 5,
    title: "Cancel Order",
    subtitle: "When someone purhased your item.",
    key: "Cancelorder",
  },
  // {
  //   id: 6,
  //   title: "Successful Purchase",
  //   subtitle: "When someone purhased your item.",
  // },
];

const profilenames = {
  CustomUrl: "Custom Url",
  EmailId: "Email-Id",
  Profile: "Profile Image",
  Cover: "Cover Image",
};

const EditProfile = () => {
  // useref

  const inputRef = useRef(null);
  const bannerInputRef = useRef(null);
  const { payload, token } = useSelector((state) => state.LoginReducer.User);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // usestate

  const [profileImage, setProfileImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [status, Setstatus] = useState();

  // theme from redux

  const currTheme = useSelector((state) => state.themeReducer.theme);

  // image upload function

  const handleImageUpload = () => {
    inputRef.current.click();
  };

  const handleBannerUpload = () => {
    bannerInputRef.current.click();
  };

  // image change function

  const handleImageChange = (e) => {
    const newImage = e.target.files[0];
    setProfileImage(newImage);
  };

  const handleBannerChange = (e) => {
    const newBanner = e.target.files[0];
    setBannerImage(newBanner);
  };
  const [themeImg, setThemeImg] = useState(true);
  const selectedTheme = localStorage.getItem("selectedTheme");

  const setDarkTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
  };
  const setLightTheme = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
    localStorage.setItem("selectedTheme", "light");
  };

  if (selectedTheme === "dark") {
    setDarkTheme();
  }

  const toggleTheme = (e) => {
    console.log("ssdsder", e);

    if (e == "dark") {
      setThemeImg(true);
      setDarkTheme();
    } else {
      setLightTheme();
      setThemeImg(false);
    }
    dispatch({
      type: "themechange",
      value: { theme: e },
    });
  };

  useEffect(() => {
    let currentTheme = localStorage.getItem("selectedTheme");
    if (currentTheme === "dark") {
      currentTheme = "dark";
    } else {
      currentTheme = "light";
    }
    dispatch({
      type: "themechange",
      value: { theme: currentTheme },
    });
  }, []);
  // theme from redux

  let valueContainerBG = currTheme === "light" ? "#fafafa" : "#28253f";
  let valueContainerText = currTheme === "light" ? "#18152D" : "#ffffff";
  let valueContainerBorder =
    currTheme === "light" ? "1px solid #A8A7B2" : "1px solid #5A5869";

  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#00ef9b"
          : isSelected
            ? "#00ef9b"
            : isFocused
              ? "#00ef9b"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#18152d"
          : isSelected
            ? "#18152d"
            : isFocused
              ? "#18152d"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      padding: "0 20px",
      // width: "180px",
      backgroundColor: valueContainerBG,
      color: "red",
      border: valueContainerBorder,
      borderRadius: 5,
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "40px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "40px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "red",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: valueContainerText,
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      // border: "1px solid #5A5869",
      borderRadius: 5,
      background: valueContainerBG,
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: valueContainerText,
      };
    },
  };

  const optionsOne = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "tamil", label: "Tamil" },
  ];
  const optionsTwo = [
    { value: "dark", label: "Dark Scheme" },
    { value: "light", label: "Light Scheme" },
  ];

  const [profiledetail, SetProfileDetail] = useState({});
  const [Errors, SetErrors] = useState({});
  const [DisableStatus, SetDisableStatus] = useState({});

  useEffect(() => {
    if (payload) {
      SetProfileDetail({
        ...profiledetail,
        ...{
          DisplayName: payload?.DisplayName ?? "",
          CustomUrl: payload?.CustomUrl ?? "",
          EmailId: payload?.EmailId ?? "",
          SiteUrl: payload?.SiteUrl ?? "",
          Twitter: payload?.Twitter ?? "",
          Instagram: payload?.Instagram ?? "",
          Profile: payload?.Profile,
          Cover: payload?.Cover,
          Itemsold: payload?.Itemsold,
          Mint: payload?.Mint,
          Bidactivity: payload?.Bidactivity,
          Placeoreder: payload?.Placeoreder,
          Cancelorder: payload?.Cancelorder,
        },
      });
    }
  }, [payload]);
  console.log("dddddddddddddgsdfg", payload);
  const OnChange = (e) => {
    if (e?.target) {
      SetErrors({});
      const { id, name, value, files } = e.target;
      if (files) {
        if (!files[0]?.type?.includes("image")) {
          return toast.error("Images Only allowed");
        }
        return SetProfileDetail({
          ...profiledetail,
          ...{
            [id]: files[0],
          },
        });
      }
      return SetProfileDetail({
        ...profiledetail,
        ...{
          [id]: value,
        },
      });
    }
  };

  const Validate = () => {
    let error = {};
    Object.keys(profiledetail).map((val) => {
      if (
        typeof profiledetail[val] == "string" &&
        isEmpty(profiledetail[val]) &&
        profilenames[val]
      ) {
        error[val] = profilenames[val] + " is required";
      } else if (val == "EmailId") {
        if (!config.EMAIL.test(profiledetail?.EmailId)) {
          error[val] = "Enter Valid Email-Id";
        }
      }
    });
    return error;
  };

  const updateprofile = async () => {
    SetDisableStatus({ update: true });
    const toastid = toast.loading("Validating...");
    let error = Validate();
    if (isEmpty(error)) {
      let senddata = profiledetail;
      senddata.Type = "profile";
      senddata.WalletAddress = payload.WalletAddress;
      let Resp = await userRegister(senddata);
      dispatch({
        type: "Register_Section",
        Register_Section: {
          User: {
            payload: Resp.data,
            token: Resp.token ? Resp?.token : token,
          },
        },
      });
      SetErrors({});
      toast.update(toastid, {
        type: "success",
        render: "Updated Successfuly",
        isLoading: false,
        autoClose: 1000,
      });
      SetDisableStatus({ update: false });
      navigate("/author");
      return true;
    } else {
      SetDisableStatus({ update: false });
      SetErrors(error);
      toast.update(toastid, {
        type: "error",
        render: "Fix all Validations",
        isLoading: false,
        autoClose: 1000,
      });
      return false;
    }
  };
  const updatenotification = async (key, data) => {
    let senddata = profiledetail;
    senddata.Type = "notification";
    senddata[key] = data;
    senddata.WalletAddress = payload?.WalletAddress;
    // console.log("senddatasenddata",senddata);
    let Resp = await userRegister(senddata);
    dispatch({
      type: "Register_Section",
      Register_Section: {
        User: {
          payload: Resp.data,
          token: Resp.token ? Resp?.token : token,
        },
      },
    });
    SetErrors({});

    SetDisableStatus({ update: false });
    return true;
  };

  return (
    <section className="innerpages">
      <Innertitle title="Edit" subtitle="Profile" />
      <Container className="custom_container mt-5  pt-xl-5">
        <div className="tab_section   ">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row className="author_section">
              <Col sm={12}>
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="first"
                      className="d-flex align-items-center"
                    >
                      {/* <img
                        src={profileIcon}
                        style={{ width: "20px" }}
                        alt="profile"
                        className="img-fluid me-2"
                      /> */}

                      <div className="d-flex align-items-center edit_tb ">

                        <img
                          src={profileIcon}
                          style={{ width: "20px" }}
                          alt="notification"
                          className="img-fluid me-2"
                        />

                        {/* <img
                          src={notificationLight}
                          style={{ width: "20px" }}
                          alt="notification"
                          className="img-fluid me-2"
                        /> */}
                      </div>
                      <p className="method_type mb-0 "> Profile</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      className="d-flex align-items-center"
                    >
                      <div className="d-flex align-items-center edit_tb ">

                        <img
                          src={notificationLight}
                          style={{ width: "20px" }}
                          alt="notification"
                          className="img-fluid me-2"
                        />

                      </div>
                      <p className="method_type mb-0 "> Notification</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      className="d-flex align-items-center "
                    >
                      <div className="d-flex align-items-center edit_tb ">
                        <img
                          src={appearancelight}
                          style={{ width: "20px" }}
                          alt="notification"
                          className="img-fluid me-2"
                        />
                      </div>
                      <p className="method_type mb-0">Appearance</p>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content className="mt-5">
                  <Tab.Pane eventKey="first">
                    <div>
                      <Row>
                        <Col xs={12} sm={6} md={5} lg={4} xl={3}>
                          <div>
                            <div className="d-flex align-items-center">
                              <h5 className="heading mb-0">Profile Image </h5>
                              <img
                                src={important}
                                alt="important"
                                className="img-fluid ms-2"
                              />
                            </div>
                            <div
                              className="pfl_img_wrap mt-4"
                              onClick={handleImageUpload}
                            >
                              <img
                                src={
                                  typeof profiledetail?.Profile == "string"
                                    ? isEmpty(profiledetail?.Profile)
                                      ? profileImg
                                      : `${config.IMG_URL}/user/${payload?.WalletAddress}/profile/${profiledetail?.Profile}`
                                    : profiledetail?.Profile?.type
                                      ? URL.createObjectURL(
                                        profiledetail?.Profile
                                      )
                                      : profileImg
                                }
                                alt="profile"
                                className="img-fluid"
                              />
                              {/* {profileImage ? (
                                <img
                                  src={URL.createObjectURL(profileImage)}
                                  alt="profile"
                                  className="img-fluid"
                                />
                              ) : (
                                <img
                                  src={profileImg}
                                  alt="profile"
                                  className="img-fluid"
                                />
                              )} */}

                              <input
                                type="file"
                                ref={inputRef}
                                style={{ display: "none" }}
                                id="Profile"
                                onChange={(e) => OnChange(e)}
                              ></input>
                            </div>
                            {Errors?.Profile && (
                              <span className="text-danger img-file">
                                {Errors?.Profile}
                              </span>
                            )}
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          sm={6}
                          md={5}
                          lg={4}
                          xl={3}
                          className="mt-4 mt-sm-0"
                        >
                          <div>
                            <div className="d-flex align-items-center">
                              <h5 className="heading mb-0">Profile Banner </h5>
                              <img
                                src={important}
                                alt="important"
                                className="img-fluid ms-2"
                              />
                            </div>
                            <div
                              className="banner_img_wrp mt-4"
                              onClick={handleBannerUpload}
                            >
                              {/* {bannerImage ? (
                                <img
                                  src={URL.createObjectURL(bannerImage)}
                                  alt="profile"
                                  className="img-fluid"
                                />
                              ) : (
                                <img
                                  src={bannerImgSvg}
                                  alt="profile"
                                  className="img-fluid"
                                />
                              )} */}

                              <img
                                src={
                                  typeof profiledetail?.Cover == "string"
                                    ? isEmpty(profiledetail?.Cover)
                                      ? bannerImgSvg
                                      : `${config.IMG_URL}/user/${payload?.WalletAddress}/cover/${profiledetail?.Cover}`
                                    : profiledetail?.Cover?.type
                                      ? URL.createObjectURL(profiledetail?.Cover)
                                      : bannerImgSvg
                                }
                                alt="profile"
                                className="img-fluid"
                              />

                              <input
                                type="file"
                                ref={bannerInputRef}
                                id="Cover"
                                onChange={(e) => OnChange(e)}
                                style={{ display: "none" }}
                              ></input>
                            </div>
                            {Errors?.Cover && (
                              <span className="text-danger img-file">
                                {Errors?.Cover}
                              </span>
                            )}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <p className="label">DisplayName</p>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                aria-describedby="emailHelp"
                                placeholder="Enter your name"
                                id="DisplayName"
                                onChange={(e) => OnChange(e)}
                                value={profiledetail?.DisplayName || ""}
                              />
                              {Errors?.CustomUrl && (
                                <span className="text-danger img-file">
                                  {Errors?.CustomUrl}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <p className="label">Custom URL</p>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                aria-describedby="emailHelp"
                                placeholder="Enter your custom URL"
                                id="CustomUrl"
                                onChange={(e) => OnChange(e)}
                                value={profiledetail?.CustomUrl || ""}
                              />
                              {Errors?.CustomUrl && (
                                <span className="text-danger img-file">
                                  {Errors?.CustomUrl}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <p className="label">Email Address*</p>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                id="EmailId"
                                onChange={(e) => OnChange(e)}
                                aria-describedby="emailHelp"
                                value={profiledetail?.EmailId || ""}
                                placeholder="Tell the world who you are!"
                              />
                              {Errors?.EmailId && (
                                <span className="text-danger img-file">
                                  {Errors?.EmailId}
                                </span>
                              )}
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <div className="d-flex align-items-center mb-3">
                              {/* <img
                                src={link}
                                alt="link"
                                className="img-fluid me-2"
                              /> */}

                              {currTheme == "light" ? (
                                <img
                                  src={link}
                                  alt="link"
                                  className="img-fluid me-2"
                                />
                              ) : (
                                <img
                                  src={linkdark}
                                  alt="link"
                                  className="img-fluid me-2"
                                />
                              )}
                              {/* <img
                                src={link}
                                alt="link"
                                className="img-fluid me-2"
                              /> */}
                              <p className="label mb-0">Your site</p>
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                id="SiteUrl"
                                onChange={(e) => OnChange(e)}
                                aria-describedby="emailHelp"
                                placeholder="Enter website URL"
                                value={profiledetail?.SiteUrl || ""}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <div className="d-flex align-items-center mb-3">
                              {/* <img
                                src={twitter}
                                alt="twitter"
                                className="img-fluid me-2"
                              /> */}
                              {currTheme == "light" ? (
                                <img
                                  src={twitter}
                                  alt="twitter"
                                  className="img-fluid me-2"
                                />
                              ) : (
                                <img
                                  src={twitterdark}
                                  alt="twitter"
                                  className="img-fluid me-2"
                                />
                              )}
                              <p className="label mb-0">Twitter Link</p>
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                id="Twitter"
                                onChange={(e) => OnChange(e)}
                                aria-describedby="emailHelp"
                                placeholder="Enter Twitter link"
                                value={profiledetail?.Twitter || ""}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md={6} className="mb-4">
                          <div className="inner_row ">
                            <div className="d-flex align-items-center mb-3">
                              {/* <img
                                src={instagram}
                                alt="instagram"
                                className="img-fluid me-2"
                              /> */}
                              {currTheme == "light" ? (
                                <img
                                  src={instagram}
                                  alt="instagram"
                                  className="img-fluid me-2"
                                />
                              ) : (
                                <img
                                  src={instagramdark}
                                  alt="instagram"
                                  className="img-fluid me-2"
                                />
                              )}
                              <p className="label mb-0">Instagram Link</p>
                            </div>
                            <div class="form-group">
                              <input
                                type="text"
                                class="form-control custom_input"
                                id="Instagram"
                                onChange={(e) => OnChange(e)}
                                aria-describedby="emailHelp"
                                placeholder="Enter Instagram link"
                                value={profiledetail?.Instagram || ""}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Button
                        className="green_btn hover_btn card_btn mt-4"
                        onClick={() => updateprofile()}
                        disabled={DisableStatus?.update}
                      >
                        Update Profile
                      </Button>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Row>
                      <Col xl={10}>
                        {" "}
                        <Row>
                          {/* {notificationData.map((value) => ( */}
                          <Col xs={12} md={6} lg={6}>
                            <Card className="tab_card noti_tab_cards mb-3 mt-4">
                              <Card.Body className="p-4 ">
                                <Row>
                                  <Col xs={8}>
                                    <div>
                                      <div className="d-flex  lock_cont  gap-1">
                                        <h5 className="mb-0 heading  ">
                                          {/* {value.title} */}
                                          Item Sold
                                        </h5>
                                      </div>
                                      <p className="mb-0 mt-4 text_p">
                                        {/* {value.subtitle} */}
                                        When someone purhased your item{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                  >
                                    {/* Rounded switch */}
                                    <label class="switch tab_toggle">
                                      <input
                                        type="checkbox"
                                        checked={profiledetail.Itemsold}
                                        onClick={() =>
                                          updatenotification(
                                            "Itemsold",
                                            !profiledetail?.Itemsold
                                          )
                                        }
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                            <Card className="tab_card noti_tab_cards mb-3 mt-4">
                              <Card.Body className="p-4 ">
                                <Row>
                                  <Col xs={8}>
                                    <div>
                                      <div className="d-flex  lock_cont  gap-1">
                                        <h5 className="mb-0 heading  ">
                                          {/* {value.title} */}
                                          Mint A NFT
                                        </h5>
                                      </div>
                                      <p className="mb-0 mt-4 text_p">
                                        {/* {value.subtitle} */}
                                        When you mint a NFT.{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                  >
                                    {/* {console.log("profiledetailprofiledetail",profiledetail)} */}
                                    {/* Rounded switch */}
                                    <label class="switch tab_toggle">
                                      <input
                                        type="checkbox"
                                        checked={profiledetail?.Mint}
                                        onClick={() =>
                                          updatenotification(
                                            "Mint",
                                            !profiledetail?.Mint
                                          )
                                        }
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xs={12} md={6} lg={6}>
                            <Card className="tab_card noti_tab_cards mb-3 mt-4">
                              <Card.Body className="p-4 ">
                                <Row>
                                  <Col xs={8}>
                                    <div>
                                      <div className="d-flex  lock_cont  gap-1">
                                        <h5 className="mb-0 heading  ">
                                          {/* {value.title} */}
                                          Bid Activity
                                        </h5>
                                      </div>
                                      <p className="mb-0 mt-4 text_p">
                                        {/* {value.subtitle} */}
                                        When someone Bid your NFT{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                  >
                                    {/* Rounded switch */}
                                    <label class="switch tab_toggle">
                                      <input
                                        type="checkbox"
                                        checked={profiledetail?.Bidactivity}
                                        onClick={() =>
                                          updatenotification(
                                            "Bidactivity",
                                            !profiledetail?.Bidactivity
                                          )
                                        }
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xs={12} md={6} lg={6}>
                            <Card className="tab_card noti_tab_cards mb-3 mt-4">
                              <Card.Body className="p-4 ">
                                <Row>
                                  <Col xs={8}>
                                    <div>
                                      <div className="d-flex  lock_cont  gap-1">
                                        <h5 className="mb-0 heading  ">
                                          {/* {value.title} */}
                                          Put On Sale
                                        </h5>
                                      </div>
                                      <p className="mb-0 mt-4 text_p">
                                        {/* {value.subtitle} */}
                                        When you placed a Order{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                  >
                                    {/* Rounded switch */}
                                    <label class="switch tab_toggle">
                                      <input
                                        type="checkbox"
                                        checked={profiledetail.Placeoreder}
                                        onClick={() =>
                                          updatenotification(
                                            "Placeoreder",
                                            !profiledetail?.Placeoreder
                                          )
                                        }
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          <Col xs={12} md={6} lg={6}>
                            <Card className="tab_card noti_tab_cards mb-3 mt-4">
                              <Card.Body className="p-4 ">
                                <Row>
                                  <Col xs={8}>
                                    <div>
                                      <div className="d-flex  lock_cont  gap-1">
                                        <h5 className="mb-0 heading  ">
                                          {/* {value.title} */}
                                          Cancel Order
                                        </h5>
                                      </div>
                                      <p className="mb-0 mt-4 text_p">
                                        {/* {value.subtitle} */}
                                        When you cancel your Order{" "}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={4}
                                    className="d-flex justify-content-end"
                                  >
                                    {/* Rounded switch */}
                                    <label class="switch tab_toggle">
                                      <input
                                        type="checkbox"
                                        checked={profiledetail.Cancelorder}
                                        onClick={() =>
                                          updatenotification(
                                            "Cancelorder",
                                            !profiledetail?.Cancelorder
                                          )
                                        }
                                      />
                                      <span class="slider round"></span>
                                    </label>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                          {/* ))} */}
                        </Row>
                      </Col>
                    </Row>
                    {/* <Button className="green_btn hover_btn mt-4">
                      Update Profile
                    </Button> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <Row>
                      <Col xl={10} xxl={9}>
                        <Row>
                          <Col md={6}>
                            <div className="inner_row ">
                              <p className="label mb-2">Language</p>
                              <p className="content">
                                Select your prefered language.
                              </p>
                              <Select
                                classNamePrefix="react-select-one"
                                styles={stylesgraybg}
                                // menuIsOpen={true}
                                options={optionsOne}
                                className="react_slt edit_pfl_inpt"
                                placeholder="English"
                                isSearchable={false}
                              />
                            </div>
                          </Col>
                          <Col md={6} className="mt-4 mt-md-0">
                            <div className="inner_row ">
                              <p className="label mb-2">Theme</p>
                              <p className="content">
                                Customize how Gigaland looks for you.
                              </p>
                              <Select
                                classNamePrefix="react-select-one"
                                styles={stylesgraybg}
                                // menuIsOpen={true}
                                onChange={(e) => toggleTheme(e.value)}
                                options={optionsTwo}
                                className="react_slt edit_pfl_inpt"
                                placeholder="Dark scheme"
                                isSearchable={false}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    {/* <Button className="green_btn hover_btn mt-4">
                      Update Profile
                    </Button> */}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </section>
  );
};

export default EditProfile;
